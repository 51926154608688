import CheckboxField from "modules/form/fields/CheckboxField.component";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";
import { dpLessMuted } from "modules/theme/theme";
import React, { FunctionComponent } from "react";
import { View, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import MedicationListRowEdit from "./MedicationListRowEdit";
import MedicationListRowRead from "./MedicationListRowRead";
import { AnonymousMedication, Medication } from "./store/refill.action-types";
import { selectEditMedicationRowState, selectMedicationsSaved, selectNewMedications } from "./store/refill.selectors";

const MedicationListRow: FunctionComponent<MedicationListRowProps> = (props) => {
	const medication = props.medication;
	// guest refill will have less fields for medication, but is guaranteed to have a temporary medication_list_medication_id assigned
	const medicationId = medication.medication_list_medication_id!;
	const rowState = useSelector(selectEditMedicationRowState(medicationId));
	const medicationsSaved = useSelector(selectMedicationsSaved());
	const isDefaultSelected =
		medicationsSaved.findIndex((x) => {
			return x.medication_list_medication_id === medication.medication_list_medication_id;
		}) < 0;

	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	return (
		<>
			<View style={[styles.row, styles.medicationListRowItem]}>
				<View style={[styles.column, { width: isSmallScreen ? 40 : 80, justifyContent: "flex-start" }]}>
					<CheckboxField name={medicationId} defaultValue={isDefaultSelected} />
				</View>

				{(rowState === "edit" || rowState === "saving") && <MedicationListRowEdit medication={medication} state={rowState} />}
				{rowState === "read" && <MedicationListRowRead medication={medication} />}
			</View>
			{!props.isLast && (
				<View
					style={{
						borderBottomColor: dpLessMuted,
						borderBottomWidth: 2,
					}}
				/>
			)}
		</>
	);
};

interface MedicationListRowProps {
	medication: Medication | AnonymousMedication;
	isLast: boolean;
}

const styles = StyleSheet.create({
	row: {
		flexDirection: "row",
	},
	column: {
		alignItems: "center",
		flexDirection: "row",
	},
	medicationListRowItem: {
		paddingTop: 24,
		paddingBottom: 24,
		alignItems: "center",
	},
});

export default MedicationListRow;
