import TextField from "modules/form/fields/TextField.component";
import React, { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { StyleSheet, View, Text } from "react-native";
import { Card } from "react-native-paper";
import { dpError, dpGreen, dpLessMutedStill } from "../theme/theme";
import Button from "modules/form/button/Button.component";
import { useDispatch, useSelector } from "react-redux";
import { submitRefillRequest } from "./store/refill.actions";
import { AnonymousMedication, Medication } from "./store/refill.action-types";
import { selectMedications, selectAddMedicationState, selectSubmitRefillRequestState, selectMedicationsSaved, selectRefill } from "./store/refill.selectors";
import { Screens } from "modules/common/screens/screens.constants";
import { RequestState } from "modules/store/types";
import MedicationList from "./MedicationList.component";
import { useNavigation, useRoute } from "@react-navigation/native";
import { selectIsAuthenticated, selectProfile } from "modules/account/store/account.selectors";
import PrivacyPolicyField from "modules/pro-forma/privacy-policy/PrivacyPolicyField.component";
import TermsOfServiceField from "modules/pro-forma/terms-of-service/TermsOfServiceField.component";
import NewsletterSignUpField from "modules/pro-forma/newsletter/newsletter-signup.component";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";
import AddMedicationDialog from "./AddMedicationDialog.component";
import { selectPharmacy } from "modules/pharmacy/store/pharmacy.selectors";

const AddMedication: FunctionComponent<AddMedicationProps> = (props) => {
	const addMedicationMethods = useForm<AddMedicationForm>({ defaultValues: { rx_num: "", rx_name: "" } });
	const medicationListMethods = useForm<AddMedicationForm>({ defaultValues: {} });
	const commentMethods = useForm<CommentForm>({ defaultValues: { comment: "" } });
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const route = useRoute();
	const medications = useSelector(selectMedications());
	const medicationsSaved = useSelector(selectMedicationsSaved());
	const addingMedicationState = useSelector(selectAddMedicationState());
	const submitRefillRequestState = useSelector(selectSubmitRefillRequestState());
	const profile = useSelector(selectProfile());
	const isAuthenticated = useSelector(selectIsAuthenticated());
	const pharmacy = useSelector(selectPharmacy());
	const refill = useSelector(selectRefill());
	const locationId = profile?.patient.default_location_id ?? refill.locationId;
	const location = pharmacy.location.find((x) => x.id === locationId);
	const [medicationSelectedCount, setMedicationSelectionCount] = useState(0);

	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	const [addMedicationVisible, setAddMedicationVisible] = useState(false);

	useEffect(
		function checkForValidForm() {
			medicationListMethods.watch((data: { [medicationId: string]: boolean | undefined }) => {
				setMedicationSelectionCount(getMedicationIds(data).length);
			});
		},
		[medicationListMethods]
	);

	useEffect(
		// react stack navigation does not unmount on route change, force form reset
		function resetFormOnUnload() {
			if (submitRefillRequestState === RequestState.idle) {
				commentMethods.reset();
				addMedicationMethods.reset();
			}
		},
		[submitRefillRequestState]
	);

	const handleSubmitRefill = (data: { [medicationId: string]: boolean | undefined; newsletter: boolean }) => {
		const medicationIds = getMedicationIds(data);
		const submittedMedications = medicationIds.map<Medication | AnonymousMedication>((x) => {
			const medicationSaved = medicationsSaved.find((y) => y.medication_list_medication_id === x.id);
			const medicationAnonymous = medications.find((y) => y.medication_list_medication_id === x.id);
			return (medicationSaved ?? medicationAnonymous)!;
		});
		const isAddToNewsletter = data.newsletter;
		const comment = commentMethods.getValues("comment");
		dispatch(submitRefillRequest(submittedMedications, isAddToNewsletter, comment, navigation, route));
	};

	const handleOpenAddMedicationDialog = () => {
		setAddMedicationVisible(true);
	};

	const handleAddMedicationClose = () => {
		setAddMedicationVisible(false);
	};

	return (
		<>
			<AddMedicationDialog visible={addMedicationVisible} onClose={handleAddMedicationClose} />
			<Card elevation={4} style={styles.card}>
				<FormProvider {...medicationListMethods}>
					<MedicationList />
				</FormProvider>
				{medications.length === 0 && medicationsSaved.length === 0 && (
					<View style={styles.row}>
						<View style={[styles.column, { paddingTop: 32, paddingBottom: 32 }]}>
							<Button
								tracking={{
									id: "add-first-medication",
									category: TRACKING_CATEGORY.refill,
								}}
								onPress={handleOpenAddMedicationDialog}
							>
								Add Medication
							</Button>
						</View>
					</View>
				)}
				{(medications.length > 0 || medicationsSaved.length > 0) && (
					<>
						{location?.service.Refills.allowsComments === true && (
							<View style={styles.row}>
								<View style={styles.column}>
									<FormProvider {...commentMethods}>
										<TextField name="comment" label="Questions / Comments for your Pharmacist" multiline={true} numberOfLines={1} />
									</FormProvider>
								</View>
							</View>
						)}
						<View style={[styles.row, styles.lastRow]}>
							<View style={styles.column}>
								<Button
									tracking={{
										id: "open-add-medication-dialog",
										category: TRACKING_CATEGORY.refill,
									}}
									onPress={handleOpenAddMedicationDialog}
									mode="text"
									textButtonStyle={{
										color: dpGreen,
										fontWeight: "bold",
									}}
								>
									+ Add Medication
								</Button>
							</View>
						</View>
					</>
				)}
			</Card>

			{!isAuthenticated && (
				<View style={[isSmallScreen ? styles.proFormaSmall : styles.proFormaLarge]}>
					<FormProvider {...medicationListMethods}>
						<TermsOfServiceField rightAlign={true} />
						<PrivacyPolicyField rightAlign={true} />
						<NewsletterSignUpField rightAlign={true} />
					</FormProvider>
				</View>
			)}

			<View style={styles.actions}>
				{!profile && (
					<View style={{ alignItems: "center", justifyContent: "center" }}>
						<Button
							tracking={{
								id: "add-medications-back",
								category: TRACKING_CATEGORY.refill,
							}}
							mode="text"
							navigateTo={Screens.PharmacySelection}
							disabled={addingMedicationState === RequestState.loading}
							textButtonStyle={{ color: dpLessMutedStill }}
						>
							&laquo; back
						</Button>
					</View>
				)}
				<View style={{ marginLeft: 32, alignItems: "flex-end" }}>
					<Button
						tracking={{
							id: "add-medications-submit",
							category: TRACKING_CATEGORY.refill,
						}}
						onPress={medicationListMethods.handleSubmit(handleSubmitRefill)}
						disabled={
							addingMedicationState === RequestState.loading || submitRefillRequestState === RequestState.loading || medicationSelectedCount === 0
						}
						loading={submitRefillRequestState === RequestState.loading}
					>
						Submit Refill
					</Button>
				</View>
			</View>
			<View style={styles.error}>{medicationSelectedCount === 0 && <Text style={{ color: dpError }}>Select medications from list above</Text>}</View>
		</>
	);
};

const getMedicationIds = (data: { [medicationId: string]: boolean | undefined }): { id: string; name: string }[] => {
	// a cheat to avoid `useFieldArray` or similar, so instead of having a well defined list of keys react-hook-form is aware of
	// and the extra code to maintain it, just have the checkboxes with the name set to medicationId and see which keys are checked
	const medicationIds = Object.keys(data).reduce((accumulator, key) => {
		if (data[key] === true && key !== "privacyPolicy" && key !== "termsOfService" && key !== "newsletter") {
			accumulator.push({ id: key, name: "" });
		}
		return accumulator;
	}, [] as { id: string; name: string }[]);
	return medicationIds;
};

const styles = StyleSheet.create({
	card: {
		// force header image to not hide the border radius of the card
		overflow: "hidden",
	},
	actions: {
		flexDirection: "row",
		marginTop: 32,
		justifyContent: "flex-end",
	},
	error: {
		flexDirection: "row",
		justifyContent: "flex-end",
	},
	row: {
		flexDirection: "row",
	},
	lastRow: {
		marginBottom: 32,
	},
	column: {
		flex: 1,
		paddingLeft: 32,
		paddingRight: 32,
	},
	proFormaLarge: {
		alignItems: "flex-end",
		paddingTop: 16,
	},
	proFormaSmall: {
		alignItems: "flex-start",
		paddingTop: 16,
	},
});

interface AddMedicationProps {}

interface AddMedicationForm {
	rx_num: string;
	rx_name: string;
}

interface CommentForm {
	comment: string;
}

export default AddMedication;
