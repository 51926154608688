import { Dispatch } from "redux";
import { SET_SNACKBAR_ITEMS, SnackbarOptions, SnackbarActionTypes, SnackbarItem } from "./snackbar.action-types";
import { GetState } from "modules/store/types";

export const addSnackbarItem = (options: SnackbarOptions) => (dispatch: Dispatch<SnackbarActionTypes>, getState: GetState) => {
	const snackbarItem = createSnackbarItem(options);

	setTimeout(() => {
		dispatch(removeSnackbarItem(snackbarItem) as any);
	}, snackbarItem.timeout);

	const snackbarItems = [...getState().snackbar, snackbarItem];
	dispatch({
		payload: snackbarItems,
		type: SET_SNACKBAR_ITEMS,
	});
};

export const removeSnackbarItem = (snackbarItem: SnackbarItem) => (dispatch: Dispatch<SnackbarActionTypes>, getState: GetState) => {
	const snackbarItems = getState().snackbar;
	const index = snackbarItems.indexOf(snackbarItem);
	if (index < 0) {
		return;
	}

	snackbarItems.splice(index, 1);
	const updatedSnackbarItems = [...snackbarItems];
	dispatch({
		payload: updatedSnackbarItems,
		type: SET_SNACKBAR_ITEMS,
	});
};

const defaultOptions = {
	timeout: 5000,
	severity: "success",
};

export const createSnackbarItem = (options: SnackbarOptions): SnackbarItem => {
	return {
		...defaultOptions,
		...options,
	} as SnackbarItem;
};
