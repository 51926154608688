import { SnackbarReducer, SnackbarReducerState } from "modules/layout/snackbar/store/snackbar.reducer";
import { RefillReducer, RefillReducerState } from "modules/refill/store/refill.reducer";
import { combineReducers } from "redux";
import { AccountReducer, AccountReducerState } from "../account/store/account.reducer";
import { PharmacyReducer, PharmacyReducerState } from "../pharmacy/store/pharmacy.reducer";

const createRootReducer = (history?: any) =>
	combineReducers({
		account: AccountReducer,
		pharmacy: PharmacyReducer,
		refill: RefillReducer,
		snackbar: SnackbarReducer,
	});

export interface RootReducerState {
	account: AccountReducerState;
	pharmacy: PharmacyReducerState;
	refill: RefillReducerState;
	snackbar: SnackbarReducerState;
}

export default createRootReducer;
