import { AccountDetails, AccountLoginProfile, AccountLoginResponse, AccountVerificationResponse, ResendAccountVerificationResponse } from "./account.types";

export const SET_ACCOUNT = "SET_ACCOUNT";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_STATE = "RESET_PASSWORD_STATE";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const TRY_REFRESH_SESSION = "TRY_REFRESH_SESSION";
export const TRY_REFRESH_SESSION_SUCCESS = "TRY_REFRESH_SESSION_SUCCESS";
export const TRY_REFRESH_SESSION_ERROR = "TRY_REFRESH_SESSION_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const ACCOUNT_VERIFICATION = "ACCOUNT_VERIFICATION";
export const ACCOUNT_VERIFICATION_SUCCESS = "ACCOUNT_VERIFICATION_SUCCESS";
export const ACCOUNT_VERIFICATION_ERROR = "ACCOUNT_VERIFICATION_ERROR";
export const RESEND_ACCOUNT_VERIFICATION = "RESEND_ACCOUNT_VERIFICATION";
export const RESEND_ACCOUNT_VERIFICATION_SUCCESS = "RESEND_ACCOUNT_VERIFICATION_SUCCESS";
export const RESEND_ACCOUNT_VERIFICATION_ERROR = "RESEND_ACCOUNT_VERIFICATION_ERROR";
export const ADD_PROFILE = "ADD_PROFILE";
export const ADD_PROFILE_SUCCESS = "ADD_PROFILE_SUCCESS";
export const ADD_PROFILE_ERROR = "ADD_PROFILE_ERROR";
export const SET_PROFILE = "SET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";

export interface SetAuthAction {
	type: typeof SET_ACCOUNT;
	payload: boolean;
}

export interface CreateAccountAction {
	type: typeof CREATE_ACCOUNT;
}

export interface CreateAccountSuccessAction {
	type: typeof CREATE_ACCOUNT_SUCCESS;
	payload: AccountLoginResponse;
}

export interface CreateAccountErrorAction {
	type: typeof CREATE_ACCOUNT_ERROR;
	payload: {
		message: string;
	};
}

export interface LoginAction {
	type: typeof LOGIN;
}

export interface LoginSuccessAction {
	type: typeof LOGIN_SUCCESS;
	payload: AccountLoginResponse;
}

export interface LoginErrorAction {
	type: typeof LOGIN_ERROR;
}

export interface ResetPasswordAction {
	type: typeof RESET_PASSWORD;
}

export interface ResetPasswordSuccessAction {
	type: typeof RESET_PASSWORD_SUCCESS;
}

export interface ResetPasswordErrorAction {
	type: typeof RESET_PASSWORD_ERROR;
}

export interface ResetPasswordStateAction {
	type: typeof RESET_PASSWORD_STATE;
}

export interface LogoutAction {
	type: typeof LOGOUT;
}

export interface LogoutSuccessAction {
	type: typeof LOGOUT_SUCCESS;
}

export interface TryRefreshSessionAction {
	type: typeof TRY_REFRESH_SESSION;
}

export interface TryRefreshSessionSuccessAction {
	type: typeof TRY_REFRESH_SESSION_SUCCESS;
	payload: AccountLoginResponse;
}

export interface TryRefreshSessionErrorAction {
	type: typeof TRY_REFRESH_SESSION_ERROR;
}

export interface ChangePasswordAction {
	type: typeof CHANGE_PASSWORD;
}

export interface ChangePasswordSuccessAction {
	type: typeof CHANGE_PASSWORD_SUCCESS;
	payload: AccountLoginResponse;
}

export interface ChangePasswordErrorAction {
	type: typeof CHANGE_PASSWORD_ERROR;
}

export interface AccountVerificationAction {
	type: typeof ACCOUNT_VERIFICATION;
}

export interface AccountVerificationSuccessAction {
	type: typeof ACCOUNT_VERIFICATION_SUCCESS;
	payload: AccountLoginResponse;
}

export interface AccountVerificationErrorAction {
	type: typeof ACCOUNT_VERIFICATION_ERROR;
}

export interface ResendAccountVerificationAction {
	type: typeof RESEND_ACCOUNT_VERIFICATION;
}

export interface ResendAccountVerificationSuccessAction {
	type: typeof RESEND_ACCOUNT_VERIFICATION_SUCCESS;
	payload: ResendAccountVerificationResponse;
}

export interface ResendAccountVerificationErrorAction {
	type: typeof RESEND_ACCOUNT_VERIFICATION_ERROR;
}

export interface UpdateAccountAction {
	type: typeof UPDATE_ACCOUNT;
	payload: {
		account: AccountDetails;
		profile: AccountLoginProfile;
	};
}

export interface AddProfileAction {
	type: typeof ADD_PROFILE;
}

export interface AddProfileErrorAction {
	type: typeof ADD_PROFILE_ERROR;
}

export interface AddProfileSuccessAction {
	type: typeof ADD_PROFILE_SUCCESS;
	payload: {
		profile: AccountLoginProfile;
		account: AccountLoginResponse;
	};
}

export interface UpdateProfileAction {
	type: typeof UPDATE_PROFILE;
}

export interface UpdateProfileErrorAction {
	type: typeof UPDATE_PROFILE_ERROR;
}

export interface UpdateProfileSuccessAction {
	type: typeof UPDATE_PROFILE_SUCCESS;
	payload: {
		account: AccountLoginResponse;
		profile: AccountLoginProfile;
	};
}

export interface SetProfileAction {
	type: typeof SET_PROFILE;
	payload: AccountLoginProfile;
}

export type AccountActionTypes =
	| SetAuthAction
	| CreateAccountAction
	| CreateAccountSuccessAction
	| CreateAccountErrorAction
	| LoginAction
	| LoginSuccessAction
	| LoginErrorAction
	| ResetPasswordAction
	| ResetPasswordSuccessAction
	| ResetPasswordErrorAction
	| LogoutAction
	| LogoutSuccessAction
	| TryRefreshSessionAction
	| TryRefreshSessionSuccessAction
	| TryRefreshSessionErrorAction
	| ChangePasswordAction
	| ChangePasswordSuccessAction
	| ChangePasswordErrorAction
	| AccountVerificationAction
	| AccountVerificationSuccessAction
	| AccountVerificationErrorAction
	| ResendAccountVerificationAction
	| ResendAccountVerificationSuccessAction
	| ResendAccountVerificationErrorAction
	| AddProfileAction
	| AddProfileSuccessAction
	| AddProfileErrorAction
	| SetProfileAction
	| UpdateProfileAction
	| UpdateProfileSuccessAction
	| UpdateProfileErrorAction
	| UpdateAccountAction
	| ResetPasswordStateAction;
