import Layout from "modules/layout/Layout.component";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAbortRefillRequestState, selectRefillIncomplete, selectSubmitRefillRequestState } from "./store/refill.selectors";
import { StyleSheet, Text, View } from "react-native";
import { dpError, dpGreen, dpLessMutedStill, dpWarn } from "modules/theme/theme";
import { Card } from "react-native-paper";
import Button from "modules/form/button/Button.component";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import { Screens } from "modules/common/screens/screens.constants";
import { RefillItemStatusCodes } from "./store/refill.types";
import { selectIsAuthenticated } from "modules/account/store/account.selectors";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import TextField from "modules/form/fields/TextField.component";
import { abortRefill, resetRefillSubmitState, submitRefillRequest } from "./store/refill.actions";
import { useNavigation, useRoute } from "@react-navigation/native";
import { RequestState } from "modules/store/types";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";
import { AnonymousMedication } from "./store/refill.action-types";

const RefillIncomplete: FunctionComponent<RefillIncompleteProps> = (props) => {
	const refillIncomplete = useSelector(selectRefillIncomplete());
	const isAuthenticated = useSelector(selectIsAuthenticated());
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const route = useRoute();
	const refillState = useSelector(selectSubmitRefillRequestState());
	const abortRefillRequestState = useSelector(selectAbortRefillRequestState());

	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	const methods = useForm({
		defaultValues: {
			medications: refillIncomplete?.retryItems.map((x) => {
				return { rx_num: x.medication.rx_num, rx_name: x.medication.rx_name };
			}),
		},
	});

	const { fields } = useFieldArray({
		control: methods.control,
		name: "medications",
		keyName: "rx_num",
	});

	useEffect(
		function initialize() {
			dispatch(resetRefillSubmitState());

			const unsubscribe = navigation.addListener("blur", () => {
				dispatch(resetRefillSubmitState());
			});
			return unsubscribe;
		},
		[dispatch, navigation]
	);

	const handleResubmit = (data: { medications: AnonymousMedication[] }) => {
		dispatch(submitRefillRequest(data.medications, false, refillIncomplete?.comment, navigation, route));
	};

	const handleAbort = () => {
		dispatch(
			abortRefill(
				[...(refillIncomplete?.successfulItems ?? []), ...(refillIncomplete?.retryItems ?? []), ...(refillIncomplete?.failedItems ?? [])],
				navigation,
				route,
				isAuthenticated ? Screens.AccountProfile : Screens.ProfileInfo
			)
		);
	};

	return (
		<Layout>
			<View style={styles.root}>
				<Card elevation={4} style={[styles.card, isSmallScreen ? styles.contentContainerSmall : styles.contentContainerLarge]}>
					{(refillIncomplete?.successfulItems ?? []).length > 0 && (
						<>
							<View style={[styles.headerContainer, { backgroundColor: dpGreen }]}>
								<Text style={{ color: "#fff" }}>Refilled the following medications</Text>
							</View>
							<View style={[styles.errorRow, { borderBottomColor: "#efefef", borderBottomWidth: 2 }, { padding: 16 }]}>
								<View style={styles.errorColumn}>
									<Text style={[styles.listText, { fontWeight: "bold" }]}>Rx Number</Text>
								</View>
								<View style={styles.errorColumn}>
									<Text style={[styles.listText, { fontWeight: "bold" }]}>Rx Name</Text>
								</View>
							</View>
							{refillIncomplete?.successfulItems?.map((x, index) => (
								<View key={index} style={[{ backgroundColor: index % 2 === 1 ? "#f7f7f7" : "inherit" }, { padding: 16 }]}>
									<View style={styles.errorRow}>
										<View style={styles.errorColumn}>
											<Text style={styles.listText}>{x.medication.rx_num}</Text>
										</View>
										<View style={styles.errorColumn}>
											<Text style={styles.listText}>{x.medication.rx_name}</Text>
										</View>
									</View>
								</View>
							))}
						</>
					)}

					{(refillIncomplete?.failedItems ?? []).length > 0 && (
						<>
							<View style={[styles.headerContainer, { backgroundColor: dpError }]}>
								<Text style={{ color: "#fff" }}>Error refilling the following medications</Text>
							</View>
							<View style={[styles.errorRow, { borderBottomColor: "#efefef", borderBottomWidth: 2 }, { padding: 16 }]}>
								<View style={styles.errorColumn}>
									<Text style={[styles.listText, { fontWeight: "bold" }]}>Rx Number</Text>
								</View>
								<View style={styles.errorColumn}>
									<Text style={[styles.listText, { fontWeight: "bold" }]}>Rx Name</Text>
								</View>
							</View>
							{refillIncomplete?.failedItems?.map((x, index) => (
								<View key={index} style={[{ backgroundColor: index % 2 === 1 ? "#f7f7f7" : "inherit" }, { padding: 16 }]}>
									<View style={styles.errorRow}>
										<View style={styles.errorColumn}>
											<Text style={styles.listText}>{x.medication.rx_num}</Text>
										</View>
										<View style={styles.errorColumn}>
											<Text style={styles.listText}>{x.medication.rx_name}</Text>
										</View>
									</View>
									<View>
										<Text style={styles.errorMessage}>{mapCodeToString(x.messageCode)}</Text>
									</View>
								</View>
							))}
						</>
					)}

					{(refillIncomplete?.retryItems ?? []).length > 0 && (
						<>
							<View style={[styles.headerContainer, { backgroundColor: dpWarn }]}>
								<Text style={{ color: "#fff" }}>Error refilling the following medications</Text>
							</View>
							<View style={[styles.errorRow, { borderBottomColor: "#efefef", borderBottomWidth: 2 }, { padding: 16 }]}>
								<View style={styles.errorColumn}>
									<Text style={[styles.listText, { fontWeight: "bold" }]}>Rx Number</Text>
								</View>
								<View style={styles.errorColumn}>
									<Text style={[styles.listText, { fontWeight: "bold" }]}>Rx Name</Text>
								</View>
							</View>
							<FormProvider {...methods}>
								{fields.map((field, index) => (
									<View key={index} style={[{ backgroundColor: index % 2 === 1 ? "#f7f7f7" : "inherit" }, { padding: 16 }]}>
										<View style={styles.errorRow}>
											<View style={[styles.errorColumn, { paddingRight: 16 }]}>
												<TextField key={field.rx_num} name={`medications.${index}.rx_num`} />
											</View>
											<View style={styles.errorColumn}>
												<TextField key={field.rx_num} name={`medications.${index}.rx_name`} />
											</View>
										</View>
										<View>
											<Text style={styles.errorMessage}>{mapCodeToString((refillIncomplete?.retryItems ?? [])[index]?.messageCode)}</Text>
										</View>
									</View>
								))}
							</FormProvider>
						</>
					)}

					{(refillIncomplete?.retryItems ?? []).length > 0 && (
						<View style={styles.actionContainer}>
							<Button
								tracking={{
									id: "resubmit-refill",
									category: TRACKING_CATEGORY.refill,
								}}
								onPress={methods.handleSubmit(handleResubmit)}
								disabled={refillState === RequestState.loading}
								loading={refillState === RequestState.loading}
							>
								Resubmit Refill
							</Button>
							<View style={[{ alignItems: "center" }, { paddingTop: 16 }]}>
								<Button
									tracking={{
										id: "continue-without-refilling",
										category: TRACKING_CATEGORY.refill,
									}}
									mode="text"
									onPress={handleAbort}
									disabled={abortRefillRequestState === RequestState.loading}
									textButtonStyle={{ color: dpLessMutedStill }}
								>
									Or continue without re-submitting
								</Button>
							</View>
						</View>
					)}

					{refillIncomplete?.retryItems.length === 0 && (
						<View style={styles.actionContainer}>
							<Button
								tracking={{
									id: "request-another-refill",
									category: TRACKING_CATEGORY.refill,
								}}
								onPress={handleAbort}
								disabled={refillState === RequestState.loading || abortRefillRequestState === RequestState.loading}
							>
								Request Another Refill
							</Button>
						</View>
					)}
				</Card>
			</View>
		</Layout>
	);
};

const mapCodeToString = (code: keyof typeof RefillItemStatusCodes) => {
	return RefillItemStatusCodes[code] ?? "An unknown error has occurred. Please try again later.";
};

const styles = StyleSheet.create({
	root: {
		width: "100%",
	},
	card: {
		overflow: "hidden",
	},
	container: {
		alignItems: "center",
	},
	headerContainer: {
		justifyContent: "center",
		paddingTop: 16,
		paddingBottom: 16,
		flexDirection: "row",
	},
	errorHeader: {
		color: "#fff",
	},
	errorRow: { flexDirection: "row" },
	errorColumn: { flex: 1 },
	errorMessage: {
		color: dpError,
		fontStyle: "italic",
	},
	listText: {
		fontSize: 16,
	},
	actionContainer: {
		justifyContent: "center",
		flexDirection: "column",
		padding: 32,
	},
	contentContainerLarge: {
		width: 768,
	},
	contentContainerSmall: {
		width: "100%",
	},
});

interface RefillIncompleteProps {}

export default RefillIncomplete;
