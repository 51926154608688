import { DeliveryOptions } from "modules/common/constants";
import { ParsedRefillResponse, RefillRequestResult } from "./refill.types";
import { RequestState } from "../../store/types";
import { LogoutSuccessAction } from "modules/account/store/account.action-types";
export const PERSIST_REFILL_PARTIAL = "PERSIST_REFILL_PARTIAL";
export const PERSIST_PROFILE_INFO = "PERSIST_PROFILE_INFO";
export const SUBMIT_REFILL_REQUEST = "SUBMIT_REFILL_REQUEST";
export const SUBMIT_REFILL_REQUEST_SUCCESS = "SUBMIT_REFILL_REQUEST_SUCCESS";
export const SUBMIT_REFILL_REQUEST_ERROR = "SUBMIT_REFILL_REQUEST_ERROR";
export const DISMISS_SUBMIT_REFILL_REQUEST_ERROR = "DISMISS_SUBMIT_REFILL_REQUEST_ERROR";
export const ADD_ANONYMOUS_MEDICATION = "ADD_ANONYMOUS_MEDICATION";
export const UPDATE_ANONYMOUS_MEDICATION = "UPDATE_ANONYMOUS_MEDICATION";
export const REMOVE_ANONYMOUS_MEDICATION = "REMOVE_ANONYMOUS_MEDICATION";
export const SET_ADD_MEDICATION_STATE = "SET_ADD_MEDICATION_STATE";
export const EDIT_MEDICATION = "EDIT_MEDICATION";
export const EDIT_MEDICATION_SUCCESS = "EDIT_MEDICATION_SUCCESS";
export const EDIT_MEDICATION_ERROR = "EDIT_MEDICATION_ERROR";
export const SET_EDIT_MEDICATION_STATE = "SET_EDIT_MEDICATION_STATE";
export const SET_REMOVE_MEDICATION_STATE = "SET_REMOVE_MEDICATION_STATE";
export const RESET_SUBMIT_REFILL_STATE = "RESET_SUBMIT_REFILL_STATE";
export const SET_ABORT_REFILL_STATE = "SET_ABORT_REFILL_STATE";

export interface Refill {
	firstName: string;
	lastName: string;
	phone: string;
	dateOfBirth: string;
	emailAddress: string;
	locationId: string;
	deliveryMethodId: DeliveryOptions;
	address1: string;
	address2: string;
	postalCode: string;
	city: string;
	state: string;
	medications: Medication[];
}

export interface PersistRefillPartialAction {
	type: typeof PERSIST_REFILL_PARTIAL;
	payload: Partial<Refill>;
}

export interface Medication {
	active: boolean;
	created_date: string;
	last_refilled_date?: string;
	medication_list_id: string;
	medication_list_medication_id: string;
	patient_profile_id: string;
	remaining_refills?: string;
	rx_dosage?: string;
	rx_name: string;
	rx_norm?: string;
	rx_num: string;
	rx_type?: string;
	updated_date: string;
}

export interface AnonymousMedication {
	medication_list_medication_id: string;
	rx_num: string;
	rx_name?: string;
}

export interface SubmitRefillRequestAction {
	type: typeof SUBMIT_REFILL_REQUEST;
}

export interface SubmitRefillRequestSuccessAction {
	type: typeof SUBMIT_REFILL_REQUEST_SUCCESS;
	payload: RefillRequestResult;
}

export interface SubmitRefillRequestErrorAction {
	type: typeof SUBMIT_REFILL_REQUEST_ERROR;
	payload: {
		parsedResponse: ParsedRefillResponse;
	};
}

export interface DismissSubmitRefillRequestErrorAction {
	type: typeof DISMISS_SUBMIT_REFILL_REQUEST_ERROR;
}

export interface SetEditMedicationStateAction {
	type: typeof SET_EDIT_MEDICATION_STATE;
	payload: {
		medication: Medication | AnonymousMedication;
		state: "read" | "edit" | "saving";
	};
}

export interface SetAddMedicationStateAction {
	type: typeof SET_ADD_MEDICATION_STATE;
	payload: RequestState;
}

export interface SetRemoveMedicationStateAction {
	type: typeof SET_REMOVE_MEDICATION_STATE;
	payload: {
		medication: Medication;
		state: RequestState;
	};
}

export interface AddAnonymousMedicationAction {
	type: typeof ADD_ANONYMOUS_MEDICATION;
	payload: AnonymousMedication;
}

export interface UpdateAnonymousMedicationAction {
	type: typeof UPDATE_ANONYMOUS_MEDICATION;
	payload: AnonymousMedication;
}

export interface RemoveAnonymousMedicationAction {
	type: typeof REMOVE_ANONYMOUS_MEDICATION;
	payload: AnonymousMedication;
}

export interface ResetSubmitRefillState {
	type: typeof RESET_SUBMIT_REFILL_STATE;
}

export interface SetAbortRefillStateAction {
	type: typeof SET_ABORT_REFILL_STATE;
	payload: RequestState;
}

export type RefillActionTypes =
	| PersistRefillPartialAction
	| SubmitRefillRequestAction
	| SubmitRefillRequestSuccessAction
	| SubmitRefillRequestErrorAction
	| DismissSubmitRefillRequestErrorAction
	| SetEditMedicationStateAction
	| SetRemoveMedicationStateAction
	| ResetSubmitRefillState
	| SetAddMedicationStateAction
	| AddAnonymousMedicationAction
	| UpdateAnonymousMedicationAction
	| RemoveAnonymousMedicationAction
	| LogoutSuccessAction
	| SetAbortRefillStateAction;
