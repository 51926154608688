export const SET_SNACKBAR_ITEMS = "SET_SNACKBAR_ITEMS";

export interface SetSnackbarItems {
	type: typeof SET_SNACKBAR_ITEMS;
	payload: SnackbarItem[];
}

export type SnackbarActionTypes = SetSnackbarItems;

export type SnackbarItem = {
	text: string;
	timeout: number;
	severity: "error" | "warning" | "info" | "success";
};

export type SnackbarOptions = Partial<SnackbarItem> & { text: string };
