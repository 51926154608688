import { dpBlue, dpGreen, dpMuted } from "modules/theme/theme";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Pressable } from "react-native";
import { ImageRequireSource, View } from "react-native";
import { StyleSheet, Text } from "react-native";
import Icon from "../icon/Icon.component";
import Outline from "../menu/Outline.component";

/**
 * Create a group of buttons
 */
const ButtonGroupField: FunctionComponent<ButtonGroupFieldProps> = (props) => {
	const formContext = useFormContext();
	if (!formContext) {
		/**
		 * ## Usage
		 * ```js
		 * import { useForm, FormProvider } from 'react-hook-form';
		 * ...
		 * const methods = useForm();
		 * ...
		 * <FormProvider {...methods} >
		 *     <ButtonGroupField />
		 * </FormProvider>
		 * ```
		 */
		throw new Error("ButtonGroup field must have a parent form context");
	}
	const { setValue } = formContext;

	const [fieldValue, setFieldValue] = useState(props.defaultValue);

	const handleSelection = (value: string | number) => {
		setFieldValue(value as any);
		setValue(props.name, value);
		if (props.onChange) {
			props.onChange(value);
		}
	};

	useEffect(() => {
		// handle new set of items where the previous value isn't in the new collection
		handleSelection(props.defaultValue);
	}, [props.defaultValue]);

	return (
		<View style={styles.root}>
			{props.label && <Text style={styles.label}>{props.label}</Text>}
			<View style={styles.buttonContainer}>
				{props.items.map((item, index) => (
					<React.Fragment key={item.value}>
						<Pressable onPress={() => handleSelection(item.value)}>
							<View style={styles.button}>
								<Outline
									hasActiveOutline={item.value === fieldValue}
									activeColor={dpGreen}
									backgroundColor={"#fff"}
									outlineColor={dpMuted}
									theme={
										{ roundness: 8, colors: { primary: dpGreen, background: "#fff", placeholder: dpMuted, text: dpGreen } as any } as any
									}
								/>
								<View style={{ alignItems: "center", cursor: "pointer", justifyContent: "center" } as any}>
									{item.icon && (
										<View style={styles.icon}>
											<Icon icon={item.icon} color={item.value === fieldValue ? dpGreen : dpBlue} />
										</View>
									)}
									<Text style={item.value === fieldValue ? [styles.textInput, styles.textInputSelected] : styles.textInput}>
										{item.title}
									</Text>
								</View>
							</View>
						</Pressable>
						{index !== props.items.length - 1 && <View style={{ width: 12 }}></View>}
					</React.Fragment>
				))}
			</View>
		</View>
	);
};

interface ButtonGroupFieldProps {
	items: {
		title: string;
		value: string | number;
		icon?: ImageRequireSource;
	}[];
	label?: string;
	name: string;
	onChange?: (value: string | number) => void;
	defaultValue: string | number;
}

const styles = StyleSheet.create({
	textInput: {
		color: dpBlue,
		fontWeight: "bold",
		// font family likely can come from theme?
		fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
		fontSize: 16,
	},
	textInputSelected: {
		color: dpGreen,
	},
	label: {
		fontSize: 18,
		color: dpBlue,
		fontWeight: "bold",
		paddingBottom: 4,
		fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
	},
	root: {
		paddingBottom: 32,
	},
	button: {
		width: 100,
		height: 100,
		justifyContent: "center",
	},
	icon: {
		paddingBottom: 8,
	},
	buttonContainer: {
		flexDirection: "row",
		flexFlow: "row wrap",
	},
});

export default ButtonGroupField;
