import React, { FunctionComponent, useState } from "react";
import { TextInput, Text } from "react-native-paper";
import { dpBlue, dpError, dpGreen, dpLessMuted, dpMuted } from "../../theme/theme";
import { NativeSyntheticEvent, StyleSheet, TextInputKeyPressEventData, View } from "react-native";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import EyeIcon from "../../../assets/icons/eye.svg";
import EyeCrossIcon from "../../../assets/icons/eye-cross.svg";

const TextField: FunctionComponent<TextFieldProps> = (props) => {
	const formContext = useFormContext();
	if (!formContext) {
		/**
		 * ## Usage
		 * ```js
		 * import { useForm, FormProvider } from 'react-hook-form';
		 * ...
		 * const methods = useForm();
		 * ...
		 * <FormProvider {...methods} >
		 *     <TextField />
		 * </FormProvider>
		 * ```
		 */
		throw new Error("Text field must have a parent form context");
	}

	const { control, formState } = formContext;
	const error = formState.errors[props.name];
	const [secureEntryVisible, setSecureEntryVisible] = useState(true);

	const handleKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
		if (event.nativeEvent.key === "Enter" && props.onSubmit) {
			props.onSubmit();
		}
	};

	return (
		<View style={styles.root}>
			{props.label && <Text style={styles.label}>{props.label}</Text>}
			<Controller
				control={control}
				render={({ field: { onChange, onBlur, value } }) => (
					<TextInput
						theme={{ roundness: 8, colors: { primary: dpGreen, background: "#fff", placeholder: dpMuted, text: dpGreen } as any }}
						onBlur={onBlur}
						onChange={onChange}
						multiline={props.multiline}
						mode="outlined"
						secureTextEntry={props.type === "password" && secureEntryVisible}
						value={value}
						style={styles.textInput}
						onKeyPress={handleKeyPress}
						numberOfLines={props.multiline ? props.numberOfLines : undefined}
						right={
							props.type === "password" && (
								<TextInput.Icon
									name={secureEntryVisible ? EyeCrossIcon : EyeIcon}
									color={dpGreen}
									onPress={() => setSecureEntryVisible(!secureEntryVisible)}
									forceTextInputFocus={false}
								/>
							)
						}
						disabled={props.disabled}
					/>
				)}
				name={props.name}
				rules={props.rules}
			/>
			{!!error && <Text style={styles.errorMessage}>{error.message}</Text>}
			{!!props.helpMessage && <Text style={styles.helpMessage}>{props.helpMessage}</Text>}
		</View>
	);
};

const styles = StyleSheet.create({
	textInput: {
		color: dpGreen,
		fontWeight: "bold",
	},
	label: {
		fontSize: 18,
		color: dpBlue,
		fontWeight: "bold",
		paddingBottom: 4,
	},
	root: {
		paddingBottom: 32,
	},
	helpMessage: {
		color: dpLessMuted,
		fontSize: 12,
		marginTop: 12,
	},
	errorMessage: {
		color: dpError,
		fontSize: 12,
		marginTop: 12,
	},
});

interface TextFieldProps {
	label?: string;
	type?: "text" | "password";
	name: string;
	rules?: RegisterOptions;
	helpMessage?: string;
	rightIcon?: string;
	disabled?: boolean;
	multiline?: boolean;
	numberOfLines?: number;
	onSubmit?: Function;
}

export default TextField;
