import React, { FunctionComponent } from "react";
import { View, StyleSheet, Text } from "react-native";
import { Surface } from "react-native-paper";

const Dialog: FunctionComponent<DialogProps> = (props) => {
	// TODO: animations

	if (!props.visible) {
		return <></>;
	}

	return (
		<View style={styles.modal}>
			<Surface style={styles.modalContent}>{props.children}</Surface>
		</View>
	);
};

const styles = StyleSheet.create({
	modal: {
		position: "fixed",
		zIndex: 1,
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		overflow: "auto",
		backgroundColor: "rgba(0,0,0,0.4)",
		alignItems: "center",
		justifyContent: "center",
	} as any,
	modalContent: {
		padding: 16,
		maxWidth: 420,
		elevation: 4,
		borderRadius: 4,
	},
});

interface DialogProps {
	visible: boolean;
	style: {};
	onDismiss: Function;
}

export default Dialog;
