import Layout from "modules/layout/Layout.component";
import React, { FunctionComponent } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { StyleSheet, View } from "react-native";
import { Card, Title } from "react-native-paper";
import { dpBlue, dpLessMutedStill } from "../theme/theme";
import ClipboardForwardIcon from "../../assets/icons/clipboard-forward.svg";
import Button from "modules/form/button/Button.component";
import Icon from "modules/material/icon/Icon.component";
import { useDispatch, useSelector } from "react-redux";
import { persistRefillPartial } from "./store/refill.actions";
import { Screens } from "modules/common/screens/screens.constants";
import PharmacySelectionForm from "./PharmacySelectForm.component";
import { selectAccount, selectProfile, selectUpdateProfileState } from "modules/account/store/account.selectors";
import { updateProfilePharmacy } from "modules/account/store/account.actions";
import { useNavigation, useRoute } from "@react-navigation/native";
import { RequestState } from "modules/store/types";
import { navigate } from "modules/common/helpers";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import WizardProgress from "modules/material/progress/WizardProgress.component";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";

const PharmacySelectionScreen: FunctionComponent<PharmacySelectionScreenProps> = (props) => {
	const profile = useSelector(selectProfile());
	const account = useSelector(selectAccount());
	const updateProfileState = useSelector(selectUpdateProfileState());
	const navigation = useNavigation();
	const route = useRoute();
	const address = account?.account_details.addresses?.find((x) => x.address_id === profile?.patient.default_address_id);
	const methods = useForm<PharmacySelectionFormData>({
		defaultValues: {
			locationId: profile?.patient.default_location_id ?? "",
			deliveryMethodId: profile?.patient.default_delivery_method ?? "",
			address1: address?.street_one ?? "",
			address2: address?.street_two ?? "",
			postalCode: address?.zip ?? "",
			city: address?.city ?? "",
			state: address?.state ?? "",
		},
	});
	const dispatch = useDispatch();

	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	const handleSubmit = (data: PharmacySelectionForm) => {
		if (profile) {
			dispatch(updateProfilePharmacy(data, navigation, route));
		} else {
			dispatch(persistRefillPartial(data));
			navigate(navigation, route, Screens.AddMedication);
		}
	};

	return (
		<Layout>
			<View style={styles.root}>
				{!profile && (
					<View style={styles.header}>
						<Title style={styles.title}>Refill</Title>
						<WizardProgress numberOfSteps={3} currentStep={2} />
					</View>
				)}
				<Card elevation={4} style={styles.card}>
					<FormProvider {...methods}>
						<View style={styles.cardHeader}>
							<Icon icon={ClipboardForwardIcon} color={"#fff"} />
							<Title style={styles.cardHeaderText}>Select Your Pharmacy</Title>
						</View>
						<Card.Content style={[styles.contentContainer, isSmallScreen ? styles.contentContainerSmall : styles.contentContainerLarge]}>
							<PharmacySelectionForm />
						</Card.Content>
					</FormProvider>
				</Card>
				<View style={styles.actions}>
					<View style={{ alignItems: "center", justifyContent: "center" }}>
						<Button
							tracking={{
								id: "back-to-profile-info",
								category: TRACKING_CATEGORY.profile,
							}}
							mode="text"
							navigateTo={profile ? Screens.AccountProfile : Screens.ProfileInfo}
							textButtonStyle={{ color: dpLessMutedStill }}
						>
							{profile ? "Cancel" : <>&laquo; back</>}
						</Button>
					</View>
					<View style={{ marginLeft: 32 }}>
						<Button
							tracking={{
								id: "goto-refill-add-medications",
								category: TRACKING_CATEGORY.profile,
							}}
							onPress={methods.handleSubmit(handleSubmit)}
							disabled={updateProfileState === RequestState.loading}
							loading={updateProfileState === RequestState.loading}
						>
							{profile ? "Save" : "Next"}
						</Button>
					</View>
				</View>
			</View>
		</Layout>
	);
};

const styles = StyleSheet.create({
	root: {
		width: "100%",
	},
	contentContainer: {
		justifyContent: "center",
		flexDirection: "row",
		paddingTop: 32,
	},
	contentContainerLarge: {
		width: 768,
	},
	contentContainerSmall: {
		width: "100%",
	},
	content: {
		// width: 460,
		flex: 1,
	},
	card: {
		// force header image to not hide the border radius of the card
		overflow: "hidden",
	},
	cardHeader: {
		width: "100%",
		height: 70,
		backgroundColor: dpBlue,
		paddingLeft: 16,
		flexDirection: "row",
		alignItems: "center",
	},
	cardHeaderText: {
		color: "#fff",
		fontWeight: "bold",
		fontSize: 20,
		zIndex: 1,
		paddingLeft: 8,
	},
	actions: {
		flexDirection: "row",
		marginTop: 32,
		justifyContent: "flex-end",
	},
	row: {
		flexDirection: "row",
	},
	column: {
		flex: 1,
		paddingLeft: 16,
		paddingRight: 16,
	},
	header: {
		flexDirection: "row",
		paddingBottom: 16,
	},
	title: {
		color: dpBlue,
		fontWeight: "bold",
		flex: 1,
	},
});

interface PharmacySelectionScreenProps {}

export interface PharmacySelectionFormData {
	locationId: string;
	deliveryMethodId: string;
	address1: string;
	address2: string;
	postalCode: string;
	city: string;
	state: string;
}

export default PharmacySelectionScreen;
