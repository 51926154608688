import React, { FunctionComponent, useEffect, useState } from "react";
import { ImageRequireSource, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { selectPharmacy, selectPharmacyLocation } from "modules/pharmacy/store/pharmacy.selectors";
import ButtonGroupField from "modules/material/button-group/ButtonGroup.component";
import { Pharmacy, PharmacyLocation, } from "modules/pharmacy/store/pharmacy";
import BriefcaseIcon from "../../assets/icons/briefcase.svg";
import AmbulanceIcon from "../../assets/icons/ambulance.svg";
import PlusBoxIcon from "../../assets/icons/plus-box.svg";
import MailIcon from "../../assets/icons/mail.svg";
import CarIcon from "../../assets/icons/car.svg";
import DeliveryAddress from "./DeliveryAddress.component";
import { DeliveryOptions } from "modules/common/constants";
import { useFormContext } from "react-hook-form";
import SelectField from "modules/material/dropdown/SelectField.component";
import { DropdownItem } from "modules/material/dropdown/dropdown.helpers";

const PharmacySelectionForm: FunctionComponent<PharmacySelectionFormProps> = (props) => {
	const pharmacy = useSelector(selectPharmacy());
	const formContext = useFormContext();
	const defaultLocationId = formContext.getValues("locationId") !== "" ? formContext.getValues("locationId") : pharmacy.location[0].id;
	const [deliveryOptions, setDeliveryOptions] = useState(getDeliveryOptions(pharmacy, defaultLocationId));
	let defaultDeliveryOption = formContext.getValues("deliveryMethodId") !== "" ? formContext.getValues("deliveryMethodId") : deliveryOptions[0]?.value;
	if (deliveryOptions.findIndex((x) => x.value === defaultDeliveryOption) < 0) {
		// handle user selecting a location that doesn't have the option previously selected
		defaultDeliveryOption = deliveryOptions[0].value;
	}
	const [deliveryOption, setDeliveryOption] = useState(defaultDeliveryOption);

	const locations = pharmacy.location.map((location) => {
		return {
			title: location.displayName,
			value: location.id,
		};
	});

	const selectedLocation: PharmacyLocation | undefined = useSelector(selectPharmacyLocation(defaultLocationId));
	const allowsDeliveryDetails = selectedLocation!.service.Refills.allowsDeliveryDetails;

	useEffect(
		function initialize() {
			formContext.setValue("locationId", defaultLocationId);
		},
		[defaultLocationId]
	);

	const handleLocationChange = (item: DropdownItem) => {
		const deliveryOptions = getDeliveryOptions(pharmacy, item.value as string);
		setDeliveryOptions(deliveryOptions);
	};

	const handleDeliveryOptionChange = (value: string | number) => {
		setDeliveryOption(value as DeliveryOptions);
	};

	return (
		<View style={styles.content}>
			<View style={styles.row}>
				<View style={styles.column}>
					<SelectField
						name="locationId"
						items={locations}
						defaultValue={defaultLocationId}
						onSelection={handleLocationChange}
						label="Pharmacy Location"
					/>
				</View>
			</View>
			<View style={[styles.row, { paddingTop: 16 }]}>
				<View style={styles.column}>
					<ButtonGroupField
						items={deliveryOptions}
						label="Pickup Method"
						name="deliveryMethodId"
						defaultValue={defaultDeliveryOption}
						onChange={handleDeliveryOptionChange}
					/>
				</View>
			</View>
			{(deliveryOption === DeliveryOptions.mail || deliveryOption === DeliveryOptions.delivery)
				&& allowsDeliveryDetails
				&& <DeliveryAddress />}
		</View>
	);
};

const getDeliveryOptions = (pharmacy: Pharmacy, locationId: string): { title: string; value: DeliveryOptions; icon: ImageRequireSource }[] => {
	const location = pharmacy.location.find((x) => x.id === locationId);

	const deliveryOptions = [];
	if (location?.service.Refills.allowsPickupOption) {
		deliveryOptions.push({ title: "Pickup", value: DeliveryOptions.pickup, icon: BriefcaseIcon });
	}
	if (location?.service.Refills.allowsDeliveryOption) {
		deliveryOptions.push({ title: "Delivery", value: DeliveryOptions.delivery, icon: AmbulanceIcon });
	}
	if (location?.service.Refills.allowsMailOption) {
		deliveryOptions.push({ title: "Mail", value: DeliveryOptions.mail, icon: MailIcon });
	}
	if (location?.service.Refills.allowsKioskOption) {
		deliveryOptions.push({ title: "Kiosk", value: DeliveryOptions.kiosk, icon: PlusBoxIcon });
	}
	if (location?.service.Refills.allowsCurbsideOption) {
		deliveryOptions.push({ title: "Curbside", value: DeliveryOptions.curbside, icon: CarIcon });
	}
	if (deliveryOptions.length === 0) {
		deliveryOptions.push({ title: "Pickup", value: DeliveryOptions.pickup, icon: BriefcaseIcon });
	}

	return deliveryOptions;
};

const styles = StyleSheet.create({
	content: {
		flex: 1,
	},
	row: {
		flexDirection: "row",
	},
	column: {
		flex: 1,
		paddingLeft: 16,
		paddingRight: 16,
	},
});

interface PharmacySelectionFormProps { }

interface PharmacySelectionForm {
	locationId: string;
	deliveryMethodId: DeliveryOptions;
	address1: string;
	address2: string;
	postalCode: string;
	city: string;
	state: string;
}

export default PharmacySelectionForm;
