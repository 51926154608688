/* react-native-gesture-handler must be the first import https://reactnavigation.org/docs/getting-started/ */
import "react-native-gesture-handler";

import React from "react";
import { StyleSheet, View } from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
import { Provider as StoreProvider } from "react-redux";
import { initialize as initializeTracker } from "./modules/common/tracking/tracking";
import { initialize as heapIOInitialize } from "./modules/common/tracking/heap-io-tracking";
import { initialize as pendoInitialize } from "./modules/common/tracking/pendo-tracking";
import PharmacyHandler from "./modules/pharmacy/PharmacyHandler.component";
import configureStore from "./modules/store/store";
import theme from "./modules/theme/theme";
import { NavigationContainer } from "@react-navigation/native";
import { linking } from "modules/common/screens/screens.constants";
import Routes from "modules/layout/Routes.component";
import { ResponsiveProvider } from "modules/layout/store/Responsive.context";
import { initSentry } from "modules/common/logging/logger";

// Redux
const store = configureStore();

// Tracking (snowplow)
initializeTracker();

// error monitoring
initSentry();

// heap io tracking
heapIOInitialize();

// pendo tracking
pendoInitialize();

export default function App() {
	// https://reactnavigation.org/docs/navigation-container/#ref
	const navigationRef = React.useRef(null);

	return (
		<NavigationContainer
			linking={linking}
			fallback={<View></View>}
			ref={navigationRef}
			documentTitle={{
				formatter: (opts) => {
					// display the name specified from the route, or generic until the specific pharmacy details are loaded
					return opts?.title ?? "Pharmacy";
				},
			}}
		>
			<StoreProvider store={store}>
				<PaperProvider theme={theme}>
					<ResponsiveProvider>
						<View style={styles.container}>
							<PharmacyHandler>
								<Routes navigationRef={navigationRef} />
							</PharmacyHandler>
						</View>
					</ResponsiveProvider>
				</PaperProvider>
			</StoreProvider>
		</NavigationContainer>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#F2F5F8",
	},
});
