import Layout from "modules/layout/Layout.component";
import React, { FunctionComponent } from "react";
import { StyleSheet, View, Text } from "react-native";
import { Card, Title } from "react-native-paper";
import { dpBlue, dpGreen, dpLessMuted, dpLessMutedStill } from "../theme/theme";
import ClipboardForwardIcon from "../../assets/icons/clipboard-forward.svg";
import Icon from "modules/material/icon/Icon.component";
import { useDispatch, useSelector } from "react-redux";
import { Screens } from "modules/common/screens/screens.constants";
import { selectAccount, selectProfile } from "modules/account/store/account.selectors";
import Button from "modules/form/button/Button.component";
import AccountProfilePharmacy from "./AccountProfilePharmacy.component";
import { formatDate, formatPhone } from "modules/common/helpers";
import AddMedication from "modules/refill/AddMedication.component";
import { setProfile } from "modules/account/store/account.actions";
import { DropdownItem } from "modules/material/dropdown/dropdown.helpers";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import { FormProvider, useForm } from "react-hook-form";
import SelectField from "modules/material/dropdown/SelectField.component";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";

const AccountProfile: FunctionComponent<AccountProfileProps> = (props) => {
	const dispatch = useDispatch();

	const account = useSelector(selectAccount());
	const profile = useSelector(selectProfile())!;

	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	// wrap select field in form so that we can use SelectField which gives us blur and focus states
	const methods = useForm();
	const profiles =
		account?.account_details.profiles?.map((x) => {
			return { title: `${x.patient.first_name} ${x.patient.last_name}`, value: x.patient.patient_profile_id };
		}) ?? [];

	const handleSelectProfile = (menuItem: DropdownItem) => {
		const newProfile = account?.account_details.profiles?.find((x) => x.patient_profile_id === menuItem.value);
		if (!!newProfile && newProfile.patient_profile_id !== profile.patient_profile_id) {
			dispatch(setProfile(newProfile));
		}
	};

	if (!profile) {
		return (
			<Layout>
				<View style={styles.root}>
					<Card elevation={4} style={styles.card}>
						<Card.Content
							style={[
								styles.contentContainer,
								isSmallScreen ? styles.contentContainerSmall : styles.contentContainerLarge,
								{ flexDirection: "column", padding: 64, alignItems: "center" },
							]}
						>
							<Text style={{ paddingBottom: 8 }}>Your account doesn’t have any profiles.</Text>
							<Text style={{ paddingBottom: 32 }}>Add a profile to save your information and refill in one click</Text>
							<Button tracking={{ id: "add-first-profile", category: TRACKING_CATEGORY.profile }} navigateTo={Screens.AddProfile}>
								Add Profile
							</Button>
						</Card.Content>
					</Card>
				</View>
			</Layout>
		);
	}

	if (!account) {
		return <></>;
	}

	return (
		<Layout>
			<View style={styles.root}>
				<FormProvider {...methods}>
					<View style={styles.profileSelect}>
						<SelectField
							items={profiles}
							onSelection={handleSelectProfile}
							key={profile.patient_profile_id}
							defaultValue={profile.patient_profile_id}
							name="profile"
						/>
						<View style={{ width: 16 }}></View>
						<Button
							tracking={{
								id: "goto-add-profile",
								category: TRACKING_CATEGORY.profile,
							}}
							mode="text"
							textButtonStyle={{
								color: dpGreen,
								fontWeight: "bold",
							}}
							style={{ paddingBottom: 32 }}
							navigateTo={Screens.AddProfile}
						>
							+ Add Profile
						</Button>
					</View>
				</FormProvider>
				<Card elevation={4} style={styles.card}>
					<View style={styles.cardHeader}>
						<View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
							<Icon icon={ClipboardForwardIcon} color={"#fff"} />
							<Title style={styles.cardHeaderText}>Profile Info</Title>
						</View>
						<Button
							tracking={{
								id: "goto-edit-profile",
								category: TRACKING_CATEGORY.profile,
							}}
							mode="text"
							textButtonStyle={{ color: dpGreen }}
							navigateTo={Screens.ProfileInfo}
						>
							edit
						</Button>
					</View>
					<Card.Content style={[styles.contentContainer, isSmallScreen ? styles.contentContainerSmall : styles.contentContainerLarge]}>
						<View style={styles.content}>
							<View style={styles.row}>
								<View style={[styles.column, styles.divider]}>
									<Text style={styles.columnHeader}>Name</Text>
									<Text style={styles.columnText}>
										{profile.patient.first_name} {profile.patient.last_name}
									</Text>
								</View>
								<View style={[styles.column, styles.divider]}>
									<Text style={styles.columnHeader}>Phone</Text>
									<Text style={styles.columnText}>{profile.patient.phone ? formatPhone(profile.patient.phone) : "n/a"}</Text>
								</View>
								<View style={[styles.column]}>
									<Text style={styles.columnHeader}>Date of Birth</Text>
									<Text style={styles.columnText}>{formatDate(profile.patient.date_of_birth)}</Text>
								</View>
							</View>
						</View>
					</Card.Content>
				</Card>
				<AccountProfilePharmacy account={account} profileId={profile.patient_profile_id} />
				<AddMedication key={profile?.patient_profile_id /* key here ensures AddMedication is unmounted and remounted when profile changes */} />
			</View>
		</Layout>
	);
};

const styles = StyleSheet.create({
	root: {
		width: "100%",
	},
	contentContainer: {
		justifyContent: "center",
		flexDirection: "row",
		paddingBottom: 0,
	},
	contentContainerLarge: {
		width: 768,
	},
	contentContainerSmall: {
		width: "100%",
	},
	content: {
		flex: 1,
	},
	card: {
		overflow: "hidden",
		marginBottom: 32,
	},
	cardHeader: {
		width: "100%",
		height: 70,
		backgroundColor: dpBlue,
		paddingLeft: 16,
		paddingRight: 16,
		flexDirection: "row",
		alignItems: "center",
	},
	cardHeaderText: {
		color: "#fff",
		fontWeight: "bold",
		fontSize: 20,
		zIndex: 1,
		paddingLeft: 8,
	},
	row: {
		flexDirection: "row",
	},
	column: {
		flex: 1,
		paddingLeft: 16,
		paddingRight: 16,
	},
	columnHeader: {
		fontSize: 14,
		color: dpLessMutedStill,
		paddingBottom: 8,
		paddingTop: 16,
	},
	columnText: {
		fontSize: 18,
		fontWeight: "bold",
		color: dpBlue,
		paddingBottom: 16,
	},
	divider: {
		borderRightColor: dpLessMuted,
		borderRightWidth: 1,
	},
	profileSelect: {
		flexDirection: "row",
		alignItems: "center",
	},
});

interface AccountProfileProps {}

export default AccountProfile;
