import { RootReducerState } from "../../store/rootReducer";
import { RequestState } from "../../store/types";
import { Pharmacy, PharmacyLocation } from "./pharmacy";

export const selectPharmacyState =
	() =>
	(state: RootReducerState): RequestState =>
		state.pharmacy.state;

export const selectPharmacy =
	() =>
	(state: RootReducerState): Pharmacy =>
		state.pharmacy.pharmacy!;

export const selectPharmacyLogo =
	() =>
	(state: RootReducerState): string | undefined =>
		state.pharmacy.pharmacy?.file.Logo?.publicURL;

export const selectPharmacyLocation =
	(locationId: string) =>
	(state: RootReducerState): PharmacyLocation | undefined =>
		state.pharmacy.pharmacy?.location.find((l) => l.id === locationId);
