import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import Button from "modules/form/button/Button.component";
import React, { FunctionComponent } from "react";
import { Platform, Text } from "react-native";
import CheckboxField from "../../form/fields/CheckboxField.component";
import { dpBlue, dpLessMutedStill } from "../../theme/theme";

const PrivacyPolicyField: FunctionComponent<PrivacyPolicyFieldProps> = (props) => {
	const handlePrivacyPolicyPress = () => {
		if (Platform.OS == "web") {
			window.open(`https://www.digitalpharmacist.com//client/patient-privacy-policy/`, "_blank");
		} else {
			// TODO: Linking.openURL(); or more likely use a modal and move into this app
			throw new Error("Not Implemented");
		}
	};

	return (
		<CheckboxField name="privacyPolicy" rules={{ required: true }} rightAlign={props.rightAlign}>
			<Text style={{ fontSize: 16, color: dpLessMutedStill }}>
				I have read and agree to the&nbsp;
				<Button
					mode="text"
					tracking={{
						id: "view-privacy-policy",
						category: TRACKING_CATEGORY.profile,
					}}
					onPress={handlePrivacyPolicyPress}
					textButtonStyle={{ color: dpBlue }}
				>
					Privacy Policy
				</Button>
			</Text>
		</CheckboxField>
	);
};

interface PrivacyPolicyFieldProps {
	rightAlign?: boolean;
}

export default PrivacyPolicyField;
