import React, { FunctionComponent } from "react";
import { StyleSheet, View, Text } from "react-native";
import { Card, Title } from "react-native-paper";
import { dpBlue, dpGreen, dpLessMuted, dpLessMutedStill } from "../theme/theme";
import Icon from "modules/material/icon/Icon.component";
import { useSelector } from "react-redux";
import Button from "modules/form/button/Button.component";
import { selectPharmacy } from "modules/pharmacy/store/pharmacy.selectors";
import { AccountLoginResponse } from "modules/account/store/account.types";
import BriefcaseIcon from "../../assets/icons/briefcase.svg";
import AmbulanceIcon from "../../assets/icons/ambulance.svg";
import PlusBoxIcon from "../../assets/icons/plus-box.svg";
import MailIcon from "../../assets/icons/mail.svg";
import CarIcon from "../../assets/icons/car.svg";
import { selectAccount, selectProfile } from "modules/account/store/account.selectors";
import { stateNameToAbbreviation } from "modules/common/constants";
import { formatPhone } from "modules/common/helpers";
import { Screens } from "modules/common/screens/screens.constants";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";

const AccountProfilePharmacy: FunctionComponent<AccountProfilePharmacyProps> = (props) => {
	const pharmacy = useSelector(selectPharmacy());
	const profile = useSelector(selectProfile());
	const account = useSelector(selectAccount());
	const locationId = profile?.patient.default_location_id;
	const location = pharmacy.location.find((x) => x.id === locationId);
	const locationAddress = location?.address.Main;
	const state = stateNameToAbbreviation(locationAddress?.state);
	const patientAddress = account?.account_details.addresses.find((x) => x.address_id === profile?.patient.default_address_id);

	if (!location || !profile) {
		return <Text>Error loading pharmacy details</Text>;
	}

	return (
		<Card elevation={4} style={styles.card}>
			<View style={styles.cardHeader}>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Icon icon={BriefcaseIcon} color={"#fff"} />
					<Title style={styles.cardHeaderText}>Your Pharmacy</Title>
				</View>
				<Button
					tracking={{
						id: "goto-edit-pharmacy-location",
						category: TRACKING_CATEGORY.profile,
					}}
					mode="text"
					textButtonStyle={{ color: dpGreen }}
					navigateTo={Screens.PharmacySelection}
				>
					edit
				</Button>
			</View>
			<Card.Content style={styles.contentContainer}>
				<View style={styles.content}>
					<View style={styles.row}>
						<View style={[styles.column, styles.divider]}>
							<Text style={styles.columnHeader}>Name</Text>
							<Text style={styles.columnText}>{location.displayName}</Text>
							<Text style={styles.address}>{locationAddress?.street1}</Text>
							{!!locationAddress?.street2 && <Text style={styles.address}>{locationAddress?.street2}</Text>}
							<Text style={[styles.address, { paddingBottom: 16 }]}>
								{locationAddress?.city}, {state} {locationAddress?.zip}
							</Text>
							<Button
								mode="text"
								tracking={{
									id: "profile-phone",
									category: TRACKING_CATEGORY.profile,
								}}
								textButtonStyle={{
									color: dpGreen,
									fontWeight: "bold",
									fontSize: 16,
								}}
								openUrl={`tel:${location.phone}`}
							>
								{formatPhone(location.phone)}
							</Button>
						</View>
						<View style={styles.column}>
							<Text style={styles.columnHeader}>Pickup Method</Text>
							<View style={styles.deliveryMethod}>
								{profile.patient.default_delivery_method === "pickup" && (
									<>
										<Icon icon={BriefcaseIcon} color={dpBlue} />
										<Text style={styles.deliveryMethodText}>Pickup</Text>
									</>
								)}
								{profile.patient.default_delivery_method === "mail" && (
									<>
										<Icon icon={MailIcon} color={dpBlue} />
										<Text style={styles.deliveryMethodText}>Mail</Text>
									</>
								)}
								{profile.patient.default_delivery_method === "delivery" && (
									<>
										<Icon icon={AmbulanceIcon} color={dpBlue} />
										<Text style={styles.deliveryMethodText}>Delivery</Text>
									</>
								)}
								{profile.patient.default_delivery_method === "kiosk" && (
									<>
										<Icon icon={PlusBoxIcon} color={dpBlue} />
										<Text style={styles.deliveryMethodText}>Kiosk</Text>
									</>
								)}
								{profile.patient.default_delivery_method === "curbside" && (
									<>
										<Icon icon={CarIcon} color={dpBlue} />
										<Text style={styles.deliveryMethodText}>Curbside</Text>
									</>
								)}
							</View>

							{(profile.patient.default_delivery_method === "mail" || profile.patient.default_delivery_method === "delivery") && patientAddress && (
								<>
									<Text style={styles.address}>{patientAddress.street_one}</Text>
									{patientAddress?.street_two && <Text style={styles.address}>{patientAddress.street_two}</Text>}
									<Text style={styles.address}>
										{patientAddress.city}, {patientAddress.state} {patientAddress.zip}
									</Text>
								</>
							)}
						</View>
					</View>
				</View>
			</Card.Content>
		</Card>
	);
};

const styles = StyleSheet.create({
	root: {
		width: "100%",
	},
	deliveryMethod: {
		flexDirection: "row",
		alignItems: "center",
		paddingBottom: 16,
	},
	contentContainer: {
		justifyContent: "center",
		flexDirection: "row",
		paddingBottom: 0,
		// paddingTop: 32,
	},
	content: {
		// width: 460,
		flex: 1,
	},
	card: {
		// force header image to not hide the border radius of the card
		overflow: "hidden",
		marginBottom: 32,
	},
	cardHeader: {
		width: "100%",
		height: 70,
		backgroundColor: dpBlue,
		paddingLeft: 16,
		paddingRight: 16,
		flexDirection: "row",
		alignItems: "center",
	},
	cardHeaderText: {
		color: "#fff",
		fontWeight: "bold",
		fontSize: 20,
		zIndex: 1,
		paddingLeft: 8,
	},
	row: {
		flexDirection: "row",
	},
	column: {
		flex: 1,
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 32,
	},
	columnHeader: {
		fontSize: 14,
		color: dpLessMutedStill,
		paddingBottom: 8,
		paddingTop: 16,
	},
	columnText: {
		fontSize: 18,
		fontWeight: "bold",
		color: dpBlue,
		paddingBottom: 16,
	},
	divider: {
		borderRightColor: dpLessMuted,
		borderRightWidth: 1,
	},
	address: {
		fontSize: 14,
		color: dpLessMutedStill,
	},
	deliveryMethodText: {
		color: dpBlue,
		fontWeight: "bold",
		fontSize: 18,
		paddingLeft: 8,
	},
});

interface AccountProfilePharmacyProps {
	account: AccountLoginResponse;
	profileId: string;
}

export default AccountProfilePharmacy;
