import { LinearGradient } from "expo-linear-gradient";
import Layout from "modules/layout/Layout.component";
import React, { FunctionComponent } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { ImageBackground, StyleSheet, View } from "react-native";
import { Card, Paragraph, Title } from "react-native-paper";
import header from "../../assets/header.jpg";
import { dpLessMuted } from "../theme/theme";
import { detectIsSmallScreen, useResponsive } from "./store/Responsive.context";

const CardScreen: FunctionComponent<CardScreenProps> = (props) => {
	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	return (
		<Layout>
			<View style={styles.root}>
				<Card elevation={4} style={[styles.card, isSmallScreen ? styles.cardSmall : styles.cardLarge]}>
					<FormProvider {...props.methods}>
						<View style={styles.header}>
							<ImageBackground source={header} style={styles.image}>
								<LinearGradient
									colors={[props.headerColorStart, props.headerColorEnd]}
									style={styles.headerGradient}
									start={{ x: 0, y: 0 }}
									end={{ x: 1, y: 0 }}
								/>
								<Title style={styles.headerText}>{props.header}</Title>
								<Paragraph style={styles.subheaderText}>{props.subHeader}</Paragraph>
							</ImageBackground>
						</View>
						<Card.Content style={styles.contentContainer}>
							<View style={[isSmallScreen ? styles.contentSmall : styles.contentLarge]}>{props.content}</View>
						</Card.Content>
						<View style={styles.actions}>{props.actions}</View>
					</FormProvider>
				</Card>
			</View>
		</Layout>
	);
};

const styles = StyleSheet.create({
	root: {
		width: "100%",
	},
	contentContainer: {
		justifyContent: "center",
		flexDirection: "row",
		paddingTop: 32,
	},
	contentSmall: {
		width: "100%",
	},
	contentLarge: {
		width: 460,
	},
	card: {
		// force header image to not hide the border radius of the card
		overflow: "hidden",
	},
	cardSmall: {
		width: "100%",
	},
	cardLarge: {
		width: 768,
	},
	image: {
		flex: 1,
		resizeMode: "cover",
		height: 194,
		justifyContent: "center",
	},
	header: {
		width: "100%",
		height: 194,
	},
	headerGradient: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		height: 194,
	},
	headerText: {
		color: "#fff",
		fontWeight: "bold",
		fontSize: 36,
		zIndex: 1,
		textAlign: "center",
	},
	subheaderText: {
		color: "#fff",
		fontWeight: "400",
		fontSize: 16,
		zIndex: 1,
		textAlign: "center",
		paddingTop: 8,
	},
	actions: {
		flexDirection: "row",
		borderTopColor: dpLessMuted,
		borderTopWidth: 2,
		marginTop: 32,
	},
});

interface CardScreenProps {
	header: string;
	subHeader: string;
	headerColorStart: string;
	headerColorEnd: string;
	content: React.ReactNode;
	actions: React.ReactNode;
	methods: UseFormReturn<any>;
}

export default CardScreen;
