import { pageView } from "modules/common/tracking/tracking";
import Layout from "modules/layout/Layout.component";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";
import WizardProgress from "modules/material/progress/WizardProgress.component";
import { dpBlue } from "modules/theme/theme";
import React, { FunctionComponent, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { Title } from "react-native-paper";
import AddMedication from "./AddMedication.component";

const AddMedicationScreen: FunctionComponent<AddMedicationScreenProps> = (props) => {
	useEffect(function initialize() {
		pageView("add-medications");
	}, []);

	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	return (
		<Layout>
			<View style={styles.root}>
				<View style={styles.header}>
					<Title style={styles.title}>Refill</Title>
					<WizardProgress numberOfSteps={3} currentStep={3} />
				</View>
				<View style={[isSmallScreen ? styles.contentSmall : styles.contentLarge]}>
					<AddMedication />
				</View>
			</View>
		</Layout>
	);
};

const styles = StyleSheet.create({
	root: {
		width: "100%",
	},
	header: {
		flexDirection: "row",
		paddingBottom: 16,
	},
	contentSmall: {
		width: "100%",
	},
	contentLarge: {
		width: 768,
	},
	title: {
		color: dpBlue,
		fontWeight: "bold",
		flex: 1,
	},
});

interface AddMedicationScreenProps {}

export default AddMedicationScreen;
