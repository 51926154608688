import { useNavigation, useRoute } from "@react-navigation/native";
import { createAccount } from "modules/account/store/account.actions";
import { selectCreateAccountErrorMessage, selectCreateAccountState } from "modules/account/store/account.selectors";
import { passwordRegex, passwordStrengthMessage } from "modules/common/constants";
import { Screens } from "modules/common/screens/screens.constants";
import CardScreen from "modules/layout/CardScreen.component";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";
import MessageBox from "modules/material/message-box/MessageBox.component";
import NewsletterSignUpField from "modules/pro-forma/newsletter/newsletter-signup.component";
import { selectRefill, selectSubmittedRefillId } from "modules/refill/store/refill.selectors";
import { RequestState } from "modules/store/types";
import React, { FunctionComponent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import ShieldCheckIcon from "../../assets/icons/shield-check.svg";
import UserCircleIcon from "../../assets/icons/user-circle.svg";
import { pageView, TRACKING_CATEGORY } from "../common/tracking/tracking";
import Button from "../form/button/Button.component";
import TextField from "../form/fields/TextField.component";
import PrivacyPolicyField from "../pro-forma/privacy-policy/PrivacyPolicyField.component";
import TermsOfServiceField from "../pro-forma/terms-of-service/TermsOfServiceField.component";
import { dpLessMuted } from "../theme/theme";

const AccountCreate: FunctionComponent<AccountCreateProps> = (props) => {
	const refill = useSelector(selectRefill());
	const submittedRefillId = useSelector(selectSubmittedRefillId());
	const methods = useForm<AccountCreateForm>({ defaultValues: { emailAddress: refill?.emailAddress ?? "", password: "" } });
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const route = useRoute();
	const createAccountState = useSelector(selectCreateAccountState());
	const errorMessage = useSelector(selectCreateAccountErrorMessage());

	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	const handleSubmit = (data: AccountCreateForm) => {
		dispatch(createAccount(data.emailAddress, data.password, data.newsletter, navigation, route));
	};

	useEffect(function initialize() {
		pageView("account-create");
	}, []);

	return (
		<CardScreen
			header="Create an Account"
			subHeader="The easiest way to access your prescriptions"
			headerColorStart="rgba(4,53,100,0.7)"
			headerColorEnd="rgba(0,181,136,.7)"
			methods={methods}
			content={
				<>
					{createAccountState === RequestState.error && (
						<MessageBox type="error" message={errorMessage ?? "Unable to create account. Please try again"} />
					)}
					<TextField
						label="Email Address"
						name="emailAddress"
						rules={{
							required: "Email is required",
							pattern: {
								value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
								message: "Invalid email address",
							},
						}}
						onSubmit={methods.handleSubmit(handleSubmit)}
					/>
					<TextField
						label="Password"
						type="password"
						name="password"
						helpMessage={passwordStrengthMessage}
						rules={{
							required: "Password is required",
							pattern: {
								value: passwordRegex,
								message: "Password is not strong enough",
							},
						}}
						onSubmit={methods.handleSubmit(handleSubmit)}
					/>
					{!submittedRefillId && (
						<>
							<TermsOfServiceField />
							<PrivacyPolicyField />
							<NewsletterSignUpField />
						</>
					)}
					<View style={{ marginTop: 16 }}>
						<Button
							onPress={methods.handleSubmit(handleSubmit)}
							tracking={{ id: "submit-account-create", category: TRACKING_CATEGORY.profile }}
							disabled={createAccountState === RequestState.loading}
							loading={createAccountState === RequestState.loading}
						>
							Create Account
						</Button>
					</View>
				</>
			}
			actions={
				<>
					{!submittedRefillId && (
						<>
							{!isSmallScreen && (
								<>
									<Button
										tracking={{ id: "have-account", category: TRACKING_CATEGORY.profile }}
										icon={ShieldCheckIcon}
										mode={"flat"}
										navigateTo={Screens.Login}
									>
										Already Have an Account?
									</Button>
									<View
										style={{
											borderLeftWidth: 2,
											borderLeftColor: dpLessMuted,
										}}
									/>
									<Button
										tracking={{ id: "refill-without-account", category: TRACKING_CATEGORY.profile }}
										icon={UserCircleIcon}
										mode={"flat"}
										navigateTo={Screens.ProfileInfo}
									>
										Refill Without an Account
									</Button>
								</>
							)}
							{isSmallScreen && (
								<View style={{ flex: 1 }}>
									<Button
										tracking={{ id: "have-account", category: TRACKING_CATEGORY.profile }}
										icon={ShieldCheckIcon}
										mode={"flat"}
										navigateTo={Screens.Login}
									>
										Already Have an Account?
									</Button>
									<View
										style={{
											borderTopWidth: 2,
											borderTopColor: dpLessMuted,
										}}
									/>
									<Button
										tracking={{ id: "refill-without-account", category: TRACKING_CATEGORY.profile }}
										icon={UserCircleIcon}
										mode={"flat"}
										navigateTo={Screens.ProfileInfo}
									>
										Refill Without an Account
									</Button>
								</View>
							)}
						</>
					)}
				</>
			}
		/>
	);
};

interface AccountCreateForm {
	emailAddress: string;
	password: string;
	termsOfService: boolean;
	privacyPolicy: boolean;
	newsletter: boolean;
}

interface AccountCreateProps {}

export default AccountCreate;
