import dayjs from "dayjs";
import { PharmacyLocation, RefillsService } from "modules/pharmacy/store/pharmacy";
import { Navigation, Route } from "modules/store/types";
import { Screens } from "./screens/screens.constants";

export const formatPhone = (phone: string) => {
	if (phone.length === 10) {
		return `${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(6)}`;
	}
	return phone;
};

export const formatDate = (date: string | undefined | Date): string => {
	if (!date) {
		return "n/a";
	}
	return dayjs(date).format("M/D/YYYY");
};

export const formatDateForServer = (date: string | undefined | Date): string => {
	if (!date) {
		return "";
	}
	return dayjs(date).format("YYYY-MM-DD");
};

/**
 * Perform a deep removal of all properties that are undefined. Does not mutate object
 */
export const removeUndefinedProperties = <T>(obj: T): T => {
	// general function is based on the deep clone from: https://www.npmjs.com/package/just-clone
	if (typeof obj == "function") {
		return obj;
	}
	var result: any = Array.isArray(obj) ? [] : {};
	for (var key in obj) {
		var value = obj[key];
		if (value === null || value === undefined) {
			continue;
		}
		var type = {}.toString.call(value).slice(8, -1);
		if (type == "Array" || type == "Object") {
			result[key] = removeUndefinedProperties(value);
		} else if (type == "Date") {
			result[key] = new Date((value as unknown as Date).getTime());
		} else {
			result[key] = value;
		}
	}
	return result;
};

/**
 * Perform a deep trim of all properties that are strings. Does not mutate object
 */
export const trimAllStringProperties = <T>(obj: T): T => {
	// general function is based on the deep clone from: https://www.npmjs.com/package/just-clone
	if (typeof obj == "function") {
		return obj;
	}
	var result: any = Array.isArray(obj) ? [] : {};
	for (var key in obj) {
		var value = obj[key];
		var type = {}.toString.call(value).slice(8, -1);
		if (type == "Array" || type == "Object") {
			result[key] = trimAllStringProperties(value);
		} else if (type == "Date") {
			result[key] = new Date((value as unknown as Date).getTime());
		} else if (type === "String") {
			result[key] = (value as unknown as string).trim();
		} else {
			result[key] = value;
		}
	}
	return result;
};

/**
 * React Native navigation continues to frustrate when trying to navigate, especially on an initial page load, a hack to fix
 * Pulled from: https://github.com/react-navigation/react-navigation/issues/9182#issuecomment-825769688
 */
export const stubbornNavigate = (navigation: Navigation, route: Route, screen: Screens) => {
	const unsubscribe = navigation.addListener("focus", () => {
		navigate(navigation, route, screen);
	});

	return () => {
		unsubscribe();
	};
};

export const isEmpty = (obj: any) => {
	return Object.keys(obj ?? {}).length === 0;
};

/**
 * Perform navigation and ensure the pharmacy id is kept in the query string
 */
export const navigate = (navigation: Navigation, route: Route, screen: Screens) => {
	const pharmacyId = (route.params as { pharmacy_id: string }).pharmacy_id;
	navigation.navigate(screen, {
		pharmacy_id: pharmacyId,
	});
};

/**
 * Test if string is GUID (.net) or otherwise referred to as UUID in other circles
 */
export const isGuid = (toTest: string) => {
	return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(toTest);
};

export const generateGuid = () => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const detectIsSafari = () => {
	const anyWindow = window as any;
	return (
		/constructor/i.test(anyWindow.HTMLElement) ||
		(function (p) {
			return p.toString() === "[object SafariRemoteNotification]";
		})(!anyWindow["safari"] || (typeof anyWindow.safari !== "undefined" && anyWindow["safari"].pushNotification))
	);
};

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
