import { RequestState } from "modules/store/types";
import { AccountLoginProfile, AccountLoginResponse } from "./account.types";
import {
	AccountActionTypes,
	SET_ACCOUNT,
	LOGIN_SUCCESS,
	LOGIN,
	LOGIN_ERROR,
	RESET_PASSWORD,
	RESET_PASSWORD_ERROR,
	RESET_PASSWORD_SUCCESS,
	LOGOUT_SUCCESS,
	TRY_REFRESH_SESSION_SUCCESS,
	TRY_REFRESH_SESSION,
	TRY_REFRESH_SESSION_ERROR,
	CHANGE_PASSWORD,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_ERROR,
	ADD_PROFILE,
	ADD_PROFILE_SUCCESS,
	ADD_PROFILE_ERROR,
	SET_PROFILE,
	UPDATE_PROFILE,
	UPDATE_PROFILE_SUCCESS,
	UPDATE_PROFILE_ERROR,
	CREATE_ACCOUNT,
	CREATE_ACCOUNT_SUCCESS,
	CREATE_ACCOUNT_ERROR,
	ACCOUNT_VERIFICATION_SUCCESS,
	ACCOUNT_VERIFICATION,
	ACCOUNT_VERIFICATION_ERROR,
	UPDATE_ACCOUNT,
	RESET_PASSWORD_STATE,
} from "./account.action-types";

export interface AccountReducerState {
	account?: AccountLoginResponse;
	loginState: RequestState;
	trySessionState: RequestState;
	passwordResetState: RequestState;
	passwordChangeState: RequestState;
	addProfileState: RequestState;
	profile?: AccountLoginProfile;
	updateProfileState: RequestState;
	createAccountState: RequestState;
	createAccountErrorMessage?: string;
	accountVerificationState: RequestState;
}

const initialState = (): AccountReducerState => {
	return {
		account: undefined,
		profile: undefined,
		loginState: RequestState.idle,
		passwordResetState: RequestState.idle,
		trySessionState: RequestState.idle,
		passwordChangeState: RequestState.idle,
		accountVerificationState: RequestState.idle,
		addProfileState: RequestState.idle,
		updateProfileState: RequestState.idle,
		createAccountState: RequestState.idle,
	};
};

export const AccountReducer = (state = initialState(), action: AccountActionTypes): AccountReducerState => {
	switch (action.type) {
		case SET_ACCOUNT:
			return {
				...state,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				account: action.payload,
				profile: action.payload.account_details.profiles?.length ? action.payload.account_details.profiles[0] : undefined,
				loginState: RequestState.success,
			};
		case LOGIN:
			return {
				...state,
				loginState: RequestState.loading,
			};
		case LOGIN_ERROR:
			return {
				...state,
				loginState: RequestState.error,
			};
		case RESET_PASSWORD:
			return {
				...state,
				passwordResetState: RequestState.loading,
			};
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				passwordResetState: RequestState.success,
			};
		case RESET_PASSWORD_ERROR:
			return {
				...state,
				passwordResetState: RequestState.error,
			};
		case RESET_PASSWORD_STATE:
			return {
				...state,
				passwordResetState: RequestState.idle,
			};
		case LOGOUT_SUCCESS:
			return {
				...initialState(),
				trySessionState: state.trySessionState,
			};
		case TRY_REFRESH_SESSION:
			return {
				...state,
				trySessionState: RequestState.loading,
			};
		case TRY_REFRESH_SESSION_SUCCESS:
			return {
				...state,
				account: action.payload,
				profile: action.payload.account_details.profiles?.length ? action.payload.account_details.profiles[0] : undefined,
				trySessionState: RequestState.success,
			};
		case TRY_REFRESH_SESSION_ERROR:
			return {
				...state,
				trySessionState: RequestState.error,
			};
		case CHANGE_PASSWORD:
			return {
				...state,
				passwordChangeState: RequestState.loading,
			};
		case CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				passwordChangeState: RequestState.success,
			};
		case CHANGE_PASSWORD_ERROR:
			return {
				...state,
				passwordChangeState: RequestState.error,
			};
		case CREATE_ACCOUNT_SUCCESS:
			return {
				...state,
				account: action.payload,
				profile: undefined,
			};
		case ADD_PROFILE:
			return {
				...state,
				addProfileState: RequestState.loading,
			};
		case ADD_PROFILE_SUCCESS:
			return {
				...state,
				addProfileState: RequestState.success,
				profile: action.payload.profile,
				account: action.payload.account,
			};
		case ADD_PROFILE_ERROR:
			return {
				...state,
				addProfileState: RequestState.error,
			};
		case SET_PROFILE:
			return {
				...state,
				profile: action.payload,
			};
		case UPDATE_PROFILE:
			return {
				...state,
				updateProfileState: RequestState.loading,
			};
		case UPDATE_PROFILE_SUCCESS:
			return {
				...state,
				updateProfileState: RequestState.success,
				profile: action.payload.profile,
				account: action.payload.account,
			};
		case UPDATE_PROFILE_ERROR:
			return {
				...state,
				updateProfileState: RequestState.error,
			};
		case CREATE_ACCOUNT:
			return {
				...state,
				createAccountState: RequestState.loading,
			};
		case CREATE_ACCOUNT_SUCCESS:
			return {
				...state,
				createAccountState: RequestState.success,
			};
		case CREATE_ACCOUNT_ERROR:
			return {
				...state,
				createAccountState: RequestState.error,
				createAccountErrorMessage: action.payload.message,
			};
		case ACCOUNT_VERIFICATION:
			return {
				...state,
				accountVerificationState: RequestState.loading,
			};
		case ACCOUNT_VERIFICATION_SUCCESS:
			return {
				...state,
				accountVerificationState: RequestState.success,
				account: action.payload,
				profile: action.payload.account_details.profiles?.length ? action.payload.account_details.profiles[0] : undefined,
				trySessionState: RequestState.success,
			};
		case ACCOUNT_VERIFICATION_ERROR:
			return {
				...state,
				accountVerificationState: RequestState.error,
			};
		case UPDATE_ACCOUNT:
			return {
				...state,
				account: {
					...state.account,
					account_details: action.payload.account,
				} as AccountLoginResponse,
				profile: action.payload.profile,
			};
		default:
			return state;
	}
};
