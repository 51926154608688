import { useNavigation, useRoute } from "@react-navigation/native";
import { stubbornNavigate } from "modules/common/helpers";
import { Screens } from "modules/common/screens/screens.constants";
import { RootReducerState } from "modules/store/rootReducer";
import React, { FunctionComponent, useContext, useEffect } from "react";
import { ReactReduxContext } from "react-redux";

const ConditionalRoute: FunctionComponent<ConditionalRouteProps> = (props) => {
	const navigation = useNavigation();
	const route = useRoute();
	const { store } = useContext(ReactReduxContext);
	const isCondition = props.condition(store.getState());

	useEffect(
		function initialize() {
			if (!isCondition) {
				return stubbornNavigate(navigation, route, props.falseConditionScreen);
			}
		},
		[isCondition]
	);

	if (!isCondition) {
		return <></>;
	}

	return <>{props.children}</>;
};

interface ConditionalRouteProps {
	condition: (reduxState: RootReducerState) => boolean;
	falseConditionScreen: Screens;
}

export default ConditionalRoute;
