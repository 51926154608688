import { RequestState } from "modules/store/types";
import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { Text, View, StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { fetchPharmacy } from "./store/pharmacy.actions";
import { selectPharmacyState } from "./store/pharmacy.selectors";
import { ActivityIndicator } from "react-native-paper";
import { dpGreen } from "modules/theme/theme";
import { logger } from "modules/common/logging/logger";

const PharmacyHandler: FunctionComponent<PharmacyHandlerProps> = (props) => {
	const state = useSelector(selectPharmacyState());
	const dispatch = useDispatch();

	// this component is outside the router, so must pull id via traditional js
	const pharmacyId = new URLSearchParams(window.location.search).get("pharmacy_id");

	useEffect(
		function initialize() {
			if (!pharmacyId) {
				logger("error", new Error("Missing pharmacy id"));
			} else {
				dispatch(fetchPharmacy(pharmacyId));
			}
		},
		[pharmacyId]
	);

	if (!pharmacyId) {
		return (
			<View style={styles.center}>
				<Text>Missing configuration</Text>
			</View>
		);
	}

	if (state === RequestState.error) {
		return (
			<View style={styles.center}>
				<Text>Error loading, please try again</Text>
			</View>
		);
	}

	if (state === RequestState.idle || state === RequestState.loading) {
		return (
			<View style={styles.center}>
				<ActivityIndicator animating={true} color={dpGreen} />
			</View>
		);
	}

	return <>{props.children}</>;
};

const styles = StyleSheet.create({
	center: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
});

interface PharmacyHandlerProps {
	children: ReactNode;
}

export default PharmacyHandler;
