import { configMap } from "../constants";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

let logToConsole = !configMap.isProduction;

export const logger = (type: "info" | "warn" | "error" | "log", message: any) => {
	// TODO: Good place to log to server on errors, eg sentry.io
	Sentry.captureException(message);
	if (!logToConsole) {
		return;
	}
	console[type](message);
};

export const initSentry = () => {
	// impossible to not monkey patch globals like "fetch" because it news up Breadcrumbs even if its never used, see github link
	// https://github.com/getsentry/sentry-javascript/blob/b89b086cf22f1be21154e8fdc0dbd8d702c5dd30/packages/browser/src/sdk.ts#L13
	Sentry.init({
		dsn: configMap.sentryEndpoint,
		tracesSampleRate: 1.0,
		// defaultIntegrations: false,
		environment: configMap.isDevelopment ? "development" : "production",
		integrations: [new Integrations.BrowserTracing()],
		// ignoreErrors: [/403.*\/account\/login\?refresh_profiles=1/, "403"],
	});

	Sentry.setContext("initData", {
		referrer: document.referrer ?? "NONE",
		pharmacyId: new URLSearchParams(window.location.search).get("pharmacy_id") ?? "NONE",
	});
};
