import { configMap } from "modules/common/constants";
import { setPharmacyContext } from "modules/common/tracking/tracking";
import { Dispatch } from "redux";
import { logger } from "../../common/logging/logger";
import { GetState } from "../../store/types";
import { Pharmacy } from "./pharmacy";
import {
	FetchPharmacyAction,
	FetchPharmacyErrorAction,
	FETCH_PHARMACY,
	FETCH_PHARMACY_ERROR,
	ReceivePharmacyAction,
	RECEIVE_PHARMACY,
} from "./pharmacy.action-types";

export const fetchPharmacy = (pharmacyId: string) => async (dispatch: Dispatch, getState: GetState) => {
	// notify the system its loading
	dispatch({
		type: FETCH_PHARMACY,
	} as FetchPharmacyAction);

	try {
		// initiate the request to fetch the pharmacy
		const response: Pharmacy = await fetch(`${configMap.apiWebEndpoint}/config/${pharmacyId}`, {
			method: "GET",
			credentials: "include",
		}).then((x) => x.json());

		setPharmacyContext(response.id);

		// put the pharmacy into our store and notify the system we have loaded
		dispatch({
			type: RECEIVE_PHARMACY,
			payload: response,
		} as ReceivePharmacyAction);
	} catch (error) {
		logger("error", error);
		// notify the system there was an error so that it may display the appropriate error
		dispatch({
			type: FETCH_PHARMACY_ERROR,
		} as FetchPharmacyErrorAction);
	}
};
