import {
	PERSIST_REFILL_PARTIAL,
	RefillActionTypes,
	SUBMIT_REFILL_REQUEST,
	SUBMIT_REFILL_REQUEST_SUCCESS,
	SUBMIT_REFILL_REQUEST_ERROR,
	Medication,
	Refill,
	DISMISS_SUBMIT_REFILL_REQUEST_ERROR,
	SET_REMOVE_MEDICATION_STATE,
	SET_EDIT_MEDICATION_STATE,
	RESET_SUBMIT_REFILL_STATE,
	SET_ADD_MEDICATION_STATE,
	ADD_ANONYMOUS_MEDICATION,
	UPDATE_ANONYMOUS_MEDICATION,
	REMOVE_ANONYMOUS_MEDICATION,
	AnonymousMedication,
	SET_ABORT_REFILL_STATE,
} from "./refill.action-types";
import { RequestState } from "modules/store/types";
import { ParsedRefillResponse, RefillRequestResult } from "./refill.types";
import { generateGuid } from "modules/common/helpers";
import { LOGOUT_SUCCESS } from "modules/account/store/account.action-types";

export interface RefillError {
	refillResponse: RefillRequestResult;
	submittedMedications: string[];
}

export interface RefillReducerState {
	refill: Partial<Refill>;
	refillIncomplete?: ParsedRefillResponse;
	submitRefillRequestState: RequestState;
	abortRefillRequestState: RequestState;
	medications: Array<AnonymousMedication>;
	addMedicationState: RequestState;
	refillError?: RefillError;
	submittedRefillId?: string;
	editMedicationState: Map<string, "read" | "edit" | "saving">;
	removeMedicationState: RemoveMedicationState;
	newMedications: string[];
}

export type RemoveMedicationState = {
	requestState: RequestState;
	medication?: Medication;
};

const initialState = (): RefillReducerState => {
	return {
		refill: {},
		submitRefillRequestState: RequestState.idle,
		abortRefillRequestState: RequestState.idle,
		medications: [],
		addMedicationState: RequestState.idle,
		refillError: undefined,
		editMedicationState: new Map<string, "read" | "edit" | "saving">(),
		removeMedicationState: {
			requestState: RequestState.idle,
		},
		newMedications: [],
	};
};

export const RefillReducer = (state = initialState(), action: RefillActionTypes): RefillReducerState => {
	switch (action.type) {
		case PERSIST_REFILL_PARTIAL:
			return {
				...state,
				refill: {
					...state.refill,
					...action.payload,
				},
			};
		case SUBMIT_REFILL_REQUEST:
			return {
				...state,
				submitRefillRequestState: RequestState.loading,
			};
		case SUBMIT_REFILL_REQUEST_SUCCESS:
			return {
				...state,
				refillError: undefined,
				medications: [],
				refill: {},
				submittedRefillId: action.payload.submitted_refill_id,
				newMedications: [],
				submitRefillRequestState: RequestState.idle,
			};
		case RESET_SUBMIT_REFILL_STATE:
			return {
				...state,
				submitRefillRequestState: RequestState.idle,
			};
		case SUBMIT_REFILL_REQUEST_ERROR:
			return {
				...state,
				// submitRefillRequestState: RequestState.error,
				refillIncomplete: action.payload.parsedResponse,
				submitRefillRequestState: RequestState.idle,
			};
		case DISMISS_SUBMIT_REFILL_REQUEST_ERROR:
			return {
				...state,
				submitRefillRequestState: RequestState.idle,
				refillError: undefined,
			};
		case SET_EDIT_MEDICATION_STATE:
			state.editMedicationState.set(action.payload.medication.medication_list_medication_id!, action.payload.state);
			return state;
		case SET_REMOVE_MEDICATION_STATE:
			return {
				...state,
				removeMedicationState: {
					requestState: action.payload.state,
					medication: action.payload?.medication,
				},
			};
		case SET_ADD_MEDICATION_STATE:
			return {
				...state,
				addMedicationState: action.payload,
			};
		case ADD_ANONYMOUS_MEDICATION:
			return {
				...state,
				medications: [...state.medications, { ...action.payload, medication_list_medication_id: generateGuid() }],
			};
		case UPDATE_ANONYMOUS_MEDICATION:
			const index = state.medications.findIndex((x) => x.medication_list_medication_id === action.payload.medication_list_medication_id);
			if (index > -1) {
				state.medications[index] = action.payload;
				return {
					...state,
					medications: [...state.medications],
				};
			} else {
				// should never reach this code
				return state;
			}
		case REMOVE_ANONYMOUS_MEDICATION:
			const removeMedicationIndex = state.medications.findIndex((x) => x.medication_list_medication_id === action.payload.medication_list_medication_id);
			if (removeMedicationIndex > -1) {
				state.medications.splice(removeMedicationIndex, 1);
				return {
					...state,
					medications: [...state.medications],
				};
			} else {
				// should never reach this code
				return state;
			}
		case SET_ABORT_REFILL_STATE:
			if (action.payload === RequestState.idle) {
				return {
					...state,
					refill: {},
					medications: [],
					newMedications: [],
					submitRefillRequestState: RequestState.idle,
					abortRefillRequestState: action.payload,
				};
			} else {
				return {
					...state,
					abortRefillRequestState: action.payload,
				};
			}
		case LOGOUT_SUCCESS:
			return {
				...initialState(),
			};
		default:
			return state;
	}
};
