import { dpError, dpGreen, dpWarn } from 'modules/theme/theme';
import React, { FunctionComponent, } from 'react';
import { StyleSheet, View, Text, ImageRequireSource, } from 'react-native';
import Icon from '../icon/Icon.component';
import exclamationCircle from '../../../assets/icons/exclamation-circle.svg';
import checkCircle from '../../../assets/icons/check-circle.svg';

const MessageBox: FunctionComponent<MessageBoxProps> = (props) => {
	const backgroundColor = props.type === 'success' ? dpGreen : (props.type === 'error' ? dpError : dpWarn)
	return (
		<View style={[styles.container, { backgroundColor }]}>
			<View style={styles.icon}>
				{(props.type === 'error' || props.type === 'warn') && (
					<>
						<Icon icon={exclamationCircle} color='#fff' />{' '}
					</>
				)}
				{props.type === 'success' && (
					<>
						<Icon icon={checkCircle} color='#fff' />{' '}
					</>
				)}
			</View>
			<View style={styles.text}>
				<Text style={styles.message}>
					{props.message}
				</Text>
			</View>

		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: 16,
		padding: 16,
		borderRadius: 8,
		marginBottom: 16,
		flexDirection: 'row',
		alignItems: 'center'
	},
	message: {
		color: '#fff'
	},
	icon: {
		paddingRight: 12,
	},
	text: {
		flex: 1,
	}
});

interface MessageBoxProps {
	type: 'success' | 'error' | 'warn';
	message: string;
}

export default MessageBox;
