import { configMap, DeliveryOptions } from "modules/common/constants";
import { logger } from "modules/common/logging/logger";
import { GetState, Navigation, Route } from "modules/store/types";
import { Dispatch } from "redux";
import {
	AccountDetails,
	AccountLoginProfile,
	AccountLoginResponse,
	AccountVerificationResponse,
	Address,
	ChangePasswordResponse,
	ResendAccountVerificationResponse,
	WebApiErrorResponse,
} from "./account.types";
import {
	CreateAccountAction,
	CreateAccountErrorAction,
	CreateAccountSuccessAction,
	CREATE_ACCOUNT,
	CREATE_ACCOUNT_ERROR,
	CREATE_ACCOUNT_SUCCESS,
	LOGIN,
	LoginAction,
	LoginErrorAction,
	LoginSuccessAction,
	LOGIN_ERROR,
	LOGIN_SUCCESS,
	LOGOUT,
	LogoutAction,
	LogoutSuccessAction,
	LOGOUT_SUCCESS,
	ResetPasswordAction,
	ResetPasswordErrorAction,
	ResetPasswordSuccessAction,
	RESET_PASSWORD,
	RESET_PASSWORD_ERROR,
	RESET_PASSWORD_SUCCESS,
	TryRefreshSessionAction,
	TryRefreshSessionErrorAction,
	TryRefreshSessionSuccessAction,
	TRY_REFRESH_SESSION,
	TRY_REFRESH_SESSION_ERROR,
	TRY_REFRESH_SESSION_SUCCESS,
	ChangePasswordAction,
	ChangePasswordSuccessAction,
	ChangePasswordErrorAction,
	CHANGE_PASSWORD,
	CHANGE_PASSWORD_ERROR,
	CHANGE_PASSWORD_SUCCESS,
	ACCOUNT_VERIFICATION,
	ACCOUNT_VERIFICATION_SUCCESS,
	ACCOUNT_VERIFICATION_ERROR,
	AccountVerificationAction,
	AccountVerificationSuccessAction,
	AccountVerificationErrorAction,
	RESEND_ACCOUNT_VERIFICATION,
	RESEND_ACCOUNT_VERIFICATION_SUCCESS,
	RESEND_ACCOUNT_VERIFICATION_ERROR,
	ResendAccountVerificationAction,
	ResendAccountVerificationSuccessAction,
	ResendAccountVerificationErrorAction,
	AddProfileAction,
	ADD_PROFILE,
	ADD_PROFILE_SUCCESS,
	AddProfileSuccessAction,
	ADD_PROFILE_ERROR,
	AddProfileErrorAction,
	SET_PROFILE,
	SetProfileAction,
	UPDATE_PROFILE,
	UpdateProfileAction,
	UPDATE_PROFILE_SUCCESS,
	UpdateProfileSuccessAction,
	UPDATE_PROFILE_ERROR,
	UpdateProfileErrorAction,
	UPDATE_ACCOUNT,
	UpdateAccountAction,
	ResetPasswordStateAction,
	RESET_PASSWORD_STATE,
} from "./account.action-types";
import { handleFetchResponse, handleFetchResponseWithoutThrow } from "modules/common/fetch/fetch-helpers";
import { Screens } from "modules/common/screens/screens.constants";
import { formatDateForServer, navigate, Optional, removeUndefinedProperties, trimAllStringProperties } from "modules/common/helpers";
import { AddProfileForm } from "modules/profile/AddProfile.component";
import { UserProfileForm } from "modules/refill/ProfileInfo.component";
import { PharmacySelectionFormData } from "modules/refill/PharmacySelectionScreen.component";
import { identify, trackEvent, TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import { AnonymousMedication, Medication, Refill } from "modules/refill/store/refill.action-types";
import { addSnackbarItem } from "modules/layout/snackbar/store/snackbar.actions";
import isEmpty from "lodash.isempty";

const checkForRedirectInstructions = () => {
	const redirectUrl = new URLSearchParams(window.location.search).get("redirect_to");
	// If there was a `redirect_to` query param we're navigating to it in the same tab after logging in
	// necessary to support patent surveys - which are still on the old portal
	if (redirectUrl) window.open(redirectUrl, "_self");
};

export const login = (username: string, password: string, navigation: Navigation, route: Route) => async (dispatch: Dispatch, getState: GetState) => {
	// notify the system its loading
	dispatch({
		type: LOGIN,
	} as LoginAction);

	const pharmacyId = getState().pharmacy.pharmacy!.id;
	const body = trimAllStringProperties({ email: username, password });
	// initiate the request to create new account
	const response = await fetch(`${configMap.apiWebEndpoint}/account/login?refresh_profiles=1`, {
		method: "POST",
		body: JSON.stringify(body),
		headers: {
			"x-pharmacy-id": pharmacyId,
			"content-type": "application/json",
		},
		credentials: "include",
	}).then((x) => {
		return handleFetchResponseWithoutThrow<AccountLoginResponse>(x);
	});

	if (response.hasError) {
		// notify the system there was an error so that it may display the appropriate error
		dispatch({
			type: LOGIN_ERROR,
		} as LoginErrorAction);
	} else {
		const responsePayload = response.response!;
		dispatch({
			type: LOGIN_SUCCESS,
			payload: responsePayload,
		} as LoginSuccessAction);

		identify(responsePayload.user_id);
		trackEvent("login-success");

		checkForRedirectInstructions();

		if (responsePayload.email_verified) {
			navigate(navigation, route, Screens.AccountProfile);
		} else {
			navigate(navigation, route, Screens.AccountVerification);
		}
	}
};

export const createAccount =
	(username: string, password: string, isAddToNewsletter: boolean, navigation: Navigation, route: Route) =>
	async (dispatch: Dispatch, getState: GetState) => {
		// notify the system its loading
		dispatch({
			type: CREATE_ACCOUNT,
		} as CreateAccountAction);

		// TODO: handle add to newsletter
		try {
			const body = removeUndefinedProperties(
				trimAllStringProperties({
					email: username,
					password,
					consent_types: ["TermsConditions", "PrivacyPolicy"],
					submitted_refill_id: getState().refill.submittedRefillId,
				})
			);
			const pharmacyId = getState().pharmacy.pharmacy!.id;
			const response: AccountLoginResponse | WebApiErrorResponse = await fetch(`${configMap.apiWebEndpoint}/account/create`, {
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"x-pharmacy-id": pharmacyId,
					"content-type": "application/json",
					// header to denote to use the new email
					"x-refill-type": "portal",
				},
				credentials: "include",
			}).then(handleFetchResponse);

			if ((response as WebApiErrorResponse).success === false) {
				dispatch({
					type: CREATE_ACCOUNT_ERROR,
					payload: {
						message: (response as WebApiErrorResponse).message,
					},
				} as CreateAccountErrorAction);
				return;
			}

			if (isAddToNewsletter) {
				dispatch(addToNewsletter(username) as any);
			}

			dispatch({
				type: CREATE_ACCOUNT_SUCCESS,
				payload: response,
			} as CreateAccountSuccessAction);

			navigate(navigation, route, Screens.AccountVerification);
		} catch (error) {
			logger("error", error);
			// notify the system there was an error so that it may display the appropriate error
			dispatch({
				type: CREATE_ACCOUNT_ERROR,
				payload: {
					message: "Unknown error creating account. Please try again",
				},
			} as CreateAccountErrorAction);
		}
	};

export const resetPassword = (emailAddress: string) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		type: RESET_PASSWORD,
	} as ResetPasswordAction);

	trackEvent("reset-password");

	try {
		const pharmacyId = getState().pharmacy.pharmacy!.id;
		const body = trimAllStringProperties({ email: emailAddress });
		const response = await fetch(`${configMap.apiWebEndpoint}/account/password/reset`, {
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				"x-pharmacy-id": pharmacyId,
				"content-type": "application/json",
				// header to denote to use the new email
				"x-refill-type": "portal",
			},
			credentials: "include",
		}).then((x) => {
			x.json();
		});

		dispatch({
			type: RESET_PASSWORD_SUCCESS,
			payload: response,
		} as ResetPasswordSuccessAction);
	} catch (error) {
		logger("error", error);
		dispatch({
			type: RESET_PASSWORD_ERROR,
		} as ResetPasswordErrorAction);
	}
};

export const logout = (navigation: Navigation, route: Route) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		type: LOGOUT,
	} as LogoutAction);
	const time = new Date().getTime();

	try {
		const pharmacyId = getState().pharmacy.pharmacy!.id;
		await fetch(`${configMap.apiWebEndpoint}/account/logout?t=${time}`, {
			method: "GET",
			headers: {
				"x-pharmacy-id": pharmacyId,
				"content-type": "application/json",
			},
			credentials: "include",
		}).then(handleFetchResponse);

		dispatch({
			type: LOGOUT_SUCCESS,
		} as LogoutSuccessAction);
		navigate(navigation, route, Screens.Login);
	} catch (error) {
		logger("error", error);
		// TODO: need to show some sort of error
	}
};

export const addToNewsletter = (emailAddress: string) => async (dispatch: Dispatch, getState: GetState) => {
	// this is a fire and forget for the present, mimicking the refill-web behavior
	const appCode = getState().pharmacy.pharmacy?.attr.appCode.value;
	await fetch(`${configMap.newsletterEndpoint}/${appCode}/user/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
		},
		body: JSON.stringify({
			email: emailAddress,
		}),
	});

	trackEvent("newsletter-signup", TRACKING_CATEGORY.profile);
};

const accountApiRequest = async (pharmacyId: string) => {
	// in refill-web t is referred to as a cache bust, so can be improved
	const time = new Date().getTime();
	const response = await fetch(`${configMap.apiWebEndpoint}/account/?refresh_profiles=1&t=${time}`, {
		method: "GET",
		headers: {
			"x-pharmacy-id": pharmacyId,
			"content-type": "application/json",
		},
		credentials: "include",
	}).then((x) => {
		return handleFetchResponseWithoutThrow<AccountLoginResponse>(x);
	});

	return response;
};

export const trySession = () => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		type: TRY_REFRESH_SESSION,
	} as TryRefreshSessionAction);

	const pharmacyId = getState().pharmacy.pharmacy!.id;
	const response = await accountApiRequest(pharmacyId);

	if (response.hasError || !response.response?.account_details) {
		dispatch({
			type: TRY_REFRESH_SESSION_ERROR,
		} as TryRefreshSessionErrorAction);
	} else {
		const responsePayload = response.response!;
		identify(responsePayload.user_id);

		checkForRedirectInstructions();

		dispatch({
			type: TRY_REFRESH_SESSION_SUCCESS,
			payload: responsePayload,
		} as TryRefreshSessionSuccessAction);
	}
};

export const changePassword = (token: string, password: string) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		type: CHANGE_PASSWORD,
	} as ChangePasswordAction);

	try {
		const pharmacyId = getState().pharmacy.pharmacy!.id;
		const response: ChangePasswordResponse = await fetch(`${configMap.apiWebEndpoint}/account/password/change`, {
			method: "POST",
			body: JSON.stringify({
				token: token,
				password: password,
			}),
			headers: {
				"x-pharmacy-id": pharmacyId,
				"content-type": "application/json",
			},
			credentials: "include",
		}).then(handleFetchResponse);

		if (!response.success) {
			throw new Error("Password change failed");
		}

		trackEvent("password-change-success", TRACKING_CATEGORY.profile);

		dispatch({
			type: CHANGE_PASSWORD_SUCCESS,
		} as ChangePasswordSuccessAction);
	} catch (error) {
		logger("error", error);
		dispatch({
			type: CHANGE_PASSWORD_ERROR,
		} as ChangePasswordErrorAction);
	}
};

export const addProfileApiRequest = async (
	data: Optional<AddProfileForm, "rxNumber">,
	pharmacyId: string,
	addressId?: string,
	additionalMedications?: Medication[] | AnonymousMedication[]
) => {
	const medications = [
		...(!isEmpty(data.rxNumber) ? [{ rx_num: data.rxNumber }] : []),
		...(additionalMedications ?? []).map((x) => {
			return {
				rx_num: x.rx_num,
			};
		}),
	] as { rx_num: string }[];

	const body = trimAllStringProperties(
		removeUndefinedProperties({
			patient: {
				date_of_birth: formatDateForServer(data.dateOfBirth),
				default_delivery_method: data.deliveryMethodId,
				default_location_id: data.locationId,
				default_address_id: addressId,
				first_name: data.firstName,
				last_name: data.lastName,
				phone: (data.phone ?? "").replace(/-/g, ""),
				email: data.emailAddress,
			},
			medication_list: medications,
		})
	);

	const response: AccountDetails = await fetch(`${configMap.apiWebEndpoint}/account/profile/create`, {
		method: "POST",
		body: JSON.stringify(body),
		headers: {
			"x-pharmacy-id": pharmacyId,
			"content-type": "application/json",
		},
		credentials: "include",
	}).then(handleFetchResponse);

	return response;
};

export const addProfile = (data: AddProfileForm, navigation: Navigation, route: Route) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		type: ADD_PROFILE,
	} as AddProfileAction);

	try {
		const pharmacyId = getState().pharmacy.pharmacy!.id;
		const address = await handleAddressUpsert(data, getState().account.account!);
		const response: AccountDetails = await addProfileApiRequest(data, pharmacyId, address?.address_id);

		const previousProfileIds = getState().account.account?.account_details.profiles?.map((x) => x.patient_profile_id) ?? [];
		const newProfile = response.profiles?.find((x) => {
			return previousProfileIds?.findIndex((y) => y === x.patient_profile_id) < 0;
		});
		const account = {
			...getState().account.account,
			account_details: response,
		};

		dispatch({
			type: ADD_PROFILE_SUCCESS,
			payload: {
				account: account,
				profile: newProfile,
			},
		} as AddProfileSuccessAction);

		navigate(navigation, route, Screens.AccountProfile);
	} catch (error) {
		logger("error", error);
		dispatch({
			type: ADD_PROFILE_ERROR,
		} as AddProfileErrorAction);
	}
};

export const setProfile = (profile: AccountLoginProfile) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		type: SET_PROFILE,
		payload: profile,
	} as SetProfileAction);
};

export const updateProfile = (data: UserProfileForm, navigation: Navigation, route: Route) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		type: UPDATE_PROFILE,
	} as UpdateProfileAction);
	const profile = getState().account.profile!;

	try {
		const pharmacyId = getState().pharmacy.pharmacy!.id;
		const body = trimAllStringProperties(
			removeUndefinedProperties({
				patient_profile_id: profile.patient_profile_id,
				patient_object: {
					first_name: data.firstName,
					last_name: data.lastName,
					date_of_birth: formatDateForServer(data.dateOfBirth),
					email: data.emailAddress,
					phone: data.phone.replace(/-/g, ""),
					default_address_id: profile.patient.default_address_id,
					default_delivery_method: profile.patient.default_delivery_method,
					default_location_id: profile.patient.default_location_id,
				},
			})
		);
		const response: AccountDetails = await fetch(`${configMap.apiWebEndpoint}/account/profile/update`, {
			method: "PUT",
			body: JSON.stringify(body),
			headers: {
				"x-pharmacy-id": pharmacyId,
				"content-type": "application/json",
			},
			credentials: "include",
		}).then(handleFetchResponse);

		const newProfile = response.profiles?.find((x) => x.patient_profile_id === profile?.patient_profile_id);
		const account = {
			...getState().account.account,
			account_details: response,
		};

		dispatch({
			type: UPDATE_PROFILE_SUCCESS,
			payload: {
				account: account,
				profile: newProfile,
			},
		} as UpdateProfileSuccessAction);

		navigate(navigation, route, Screens.AccountProfile);
	} catch (error) {
		logger("error", error);
		dispatch({
			type: UPDATE_PROFILE_ERROR,
		} as UpdateProfileErrorAction);
	}
};

export const updateProfilePharmacy =
	(data: PharmacySelectionFormData, navigation: Navigation, route: Route) => async (dispatch: Dispatch, getState: GetState) => {
		dispatch({
			type: UPDATE_PROFILE,
		} as UpdateProfileAction);
		const profile = getState().account.profile!;

		try {
			const address = await handleAddressUpsert(data, getState().account.account!, profile);

			const pharmacyId = getState().pharmacy.pharmacy!.id;
			const body = trimAllStringProperties(
				removeUndefinedProperties({
					patient_profile_id: profile.patient_profile_id,
					patient_object: {
						first_name: profile.patient.first_name,
						last_name: profile.patient.last_name,
						date_of_birth: profile.patient.date_of_birth,
						email: profile.patient.email,
						phone: profile.patient.phone,
						default_address_id: address?.address_id ?? profile.patient.default_address_id,
						default_delivery_method: data.deliveryMethodId,
						default_location_id: data.locationId,
					},
				})
			);
			const response: AccountDetails = await fetch(`${configMap.apiWebEndpoint}/account/profile/update`, {
				method: "PUT",
				body: JSON.stringify(body),
				headers: {
					"x-pharmacy-id": pharmacyId,
					"content-type": "application/json",
				},
				credentials: "include",
			}).then(handleFetchResponse);

			const newProfile = response.profiles?.find((x) => x.patient_profile_id === profile?.patient_profile_id);
			const account = {
				...getState().account.account,
				account_details: response,
			};

			dispatch({
				type: UPDATE_PROFILE_SUCCESS,
				payload: {
					account: account,
					profile: newProfile,
				},
			} as UpdateProfileSuccessAction);

			navigate(navigation, route, Screens.AccountProfile);
		} catch (error) {
			logger("error", error);
			dispatch({
				type: UPDATE_PROFILE_ERROR,
			} as UpdateProfileErrorAction);
		}
	};

export const addAddressApiRequest = async (data: PharmacySelectionFormData) => {
	const body = trimAllStringProperties(
		removeUndefinedProperties({
			address_name: data.address1,
			city: data.city,
			state: data.state,
			street_one: data.address1,
			street_two: data.address2,
			zip: data.postalCode,
		})
	);

	const response: AccountDetails = await fetch(`${configMap.apiWebEndpoint}/account/address/create`, {
		method: "POST",
		body: JSON.stringify(body),
		headers: {
			"content-type": "application/json",
		},
		credentials: "include",
	}).then(handleFetchResponse);

	return response.addresses[response.addresses.length - 1];
};

export const updateAddress = async (data: PharmacySelectionFormData, addressId: string) => {
	const body = trimAllStringProperties(
		removeUndefinedProperties({
			address_id: addressId,
			address_object: {
				address_name: data.address1,
				city: data.city,
				state: data.state,
				street_one: data.address1,
				street_two: data.address2,
				zip: data.postalCode,
			},
		})
	);

	const response: AccountDetails = await fetch(`${configMap.apiWebEndpoint}/account/address/update`, {
		method: "PUT",
		body: JSON.stringify(body),
		headers: {
			"content-type": "application/json",
		},
		credentials: "include",
	}).then(handleFetchResponse);

	const updatedAddress = response.addresses.find((x) => x.address_id === addressId);

	return updatedAddress;
};

const handleAddressUpsert = async (
	data: PharmacySelectionFormData,
	account: AccountLoginResponse,
	profile?: AccountLoginProfile
): Promise<Address | undefined> => {
	let addressId = profile?.patient.default_address_id;
	if (data.deliveryMethodId === DeliveryOptions.delivery || data.deliveryMethodId === DeliveryOptions.mail) {
		const currentAddress = account.account_details.addresses.find((x) => x.address_id === addressId);
		if (!currentAddress) {
			// add a new address
			const newAddress = await addAddressApiRequest(data);
			addressId = newAddress.address_id;
			return newAddress;
		} else {
			// update the existing address, but only if there were changes
			const a = trimAllStringProperties(currentAddress);
			const b = trimAllStringProperties(data);
			if (a.city === b.city && a.state === b.state && a.street_one === b.address1 && a.street_two === b.address2 && a.zip === b.postalCode) {
				return currentAddress;
			}
			const newAddress = await updateAddress(data, currentAddress.address_id);
			return newAddress;
		}
	}
};

export const verifyAccount = (code: string, navigation: Navigation, route: Route) => async (dispatch: Dispatch, getState: GetState) => {
	// notify the system its loading
	dispatch({
		type: ACCOUNT_VERIFICATION,
	} as AccountVerificationAction);

	try {
		const pharmacyId = getState().pharmacy.pharmacy!.id;
		const response: AccountVerificationResponse = await fetch(`${configMap.apiWebEndpoint}/account/email/verify`, {
			method: "POST",
			body: JSON.stringify({
				email_token: code,
			}),
			headers: {
				"x-pharmacy-id": pharmacyId,
				"content-type": "application/json",
			},
			credentials: "include",
		}).then(handleFetchResponse);

		if (!response.success) {
			throw new Error("Verify account failed");
		}

		// in refill-web t is referred to as a cache bust, so can be improved
		const time = new Date().getTime();
		const accountResponse: AccountLoginResponse = await fetch(`${configMap.apiWebEndpoint}/account/?refresh_profiles=1&t=${time}`, {
			method: "GET",
			headers: {
				"x-pharmacy-id": pharmacyId,
				"content-type": "application/json",
			},
			credentials: "include",
		}).then(handleFetchResponse);

		dispatch({
			type: ACCOUNT_VERIFICATION_SUCCESS,
			payload: accountResponse,
		} as AccountVerificationSuccessAction);

		navigate(navigation, route, Screens.ProfileInfo);
	} catch (error) {
		logger("error", error);
		// notify the system there was an error so that it may display the appropriate error
		dispatch({
			type: ACCOUNT_VERIFICATION_ERROR,
		} as AccountVerificationErrorAction);
	}
};

export const resendAccountVerification = () => async (dispatch: Dispatch, getState: GetState) => {
	// notify the system its loading
	dispatch({
		type: RESEND_ACCOUNT_VERIFICATION,
	} as ResendAccountVerificationAction);

	try {
		const pharmacyId = getState().pharmacy.pharmacy!.id;
		const response: ResendAccountVerificationResponse = await fetch(`${configMap.apiWebEndpoint}/account/email/resend`, {
			method: "POST",
			body: JSON.stringify({}),
			headers: {
				"x-pharmacy-id": pharmacyId,
				"content-type": "application/json",
				// header to denote to use the new email
				"x-refill-type": "portal",
			},
			credentials: "include",
		}).then(handleFetchResponse);

		dispatch({
			type: RESEND_ACCOUNT_VERIFICATION_SUCCESS,
			payload: response,
		} as ResendAccountVerificationSuccessAction);
		dispatch(
			addSnackbarItem({
				text: "Successfully resent code",
				severity: "success",
				timeout: 5000,
			}) as any
		);
	} catch (error) {
		logger("error", error);
		// notify the system there was an error so that it may display the appropriate error
		dispatch({
			type: RESEND_ACCOUNT_VERIFICATION_ERROR,
		} as ResendAccountVerificationErrorAction);
		dispatch(
			addSnackbarItem({
				text: "Error resending code. Please try again",
				severity: "error",
				timeout: 5000,
			}) as any
		);
	}
};

export const updateAccount = (data: AccountDetails) => async (dispatch: Dispatch, getState: GetState) => {
	const profile = getState().account.profile;
	const newProfile = data.profiles?.find((x) => x.patient_profile_id === profile?.patient_profile_id);
	dispatch({
		type: UPDATE_ACCOUNT,
		payload: {
			account: data,
			profile: newProfile,
		},
	} as UpdateAccountAction);
};

export const resetPasswordResetState = () => (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		type: RESET_PASSWORD_STATE,
	} as ResetPasswordStateAction);
};

/**
 * Create a profile and corresponding details on a new account that goes through refill flow
 */
export const createProfileOnRefill = async (medications: AnonymousMedication[], dispatch: Dispatch, getState: GetState) => {
	const refill = getState().refill.refill as Refill;
	const pharmacyId = getState().pharmacy.pharmacy?.id!;
	let addressId: string | undefined;
	if (refill.deliveryMethodId === DeliveryOptions.delivery || refill.deliveryMethodId === DeliveryOptions.mail) {
		const addAddressResponse = await addAddressApiRequest(refill);
		addressId = addAddressResponse.address_id;
	}
	let account = await addProfileApiRequest(refill, pharmacyId, addressId, medications);
	let profile = account.profiles.length ? account.profiles[0] : undefined;

	dispatch({
		type: UPDATE_ACCOUNT,
		payload: {
			account: account,
			profile: profile,
		},
	} as UpdateAccountAction);
};
