import { selectIsAuthenticated } from "modules/account/store/account.selectors";
import Button from "modules/form/button/Button.component";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Text, StyleSheet, Image, View } from "react-native";
import { dpBlue, dpLessMutedStill } from "modules/theme/theme";
import refillCompleteImage from "../../assets/complete-graphic.svg";
import Layout from "modules/layout/Layout.component";
import { Screens } from "modules/common/screens/screens.constants";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";

/**
	Note: The following is a hack to allow us to override the refill response for a specific pharmacy using their
	config2 pharmacy uuid. If/when this list becomes larger than a few pharmacy overrides, we should implement
	a better pattern and potentially store the overrides in a database.
*/
const pharmacyResponseOverrides: Record<string, Record<string, string>> = {
	// Allen's Pharmacy
	"cbdf8ec2-65c2-4ae7-8208-2d880f925f2f": {
		headerText: "Standard Pickup Next day. Call for Urgent Refills.",
		subHeaderText: "",
	},
};

const RefillComplete: FunctionComponent<RefillCompleteProps> = (props) => {
	const isAuthenticated = useSelector(selectIsAuthenticated());
	const pharmacyId: string = new URLSearchParams(window.location.search).get("pharmacy_id") || "";

	const responseOverride = pharmacyResponseOverrides[pharmacyId] || {};
	if (responseOverride) console.log('Applying response override for ', pharmacyId);

	// If no override is found, use the default values
	const headerText = responseOverride.headerText || "Your Refill Will Be Ready Shortly";
	const subHeaderText = responseOverride.subHeaderText || "Visit your profile for the easiest way to access your prescriptions in the future";

	return (
		<Layout>
			<View style={styles.container}>
				<Image source={refillCompleteImage} style={{ width: 461, height: 120, marginTop: 64 }} />
				<Text style={styles.headerText}>{headerText}</Text>
				<Text style={styles.subHeaderText}>{subHeaderText}</Text>
				{!isAuthenticated && (
					<Button
						tracking={{
							id: "goto-create-profile",
							category: TRACKING_CATEGORY.profile,
						}}
						navigateTo={Screens.CreateAccount}
					>
						Complete Your Profile
					</Button>
				)}
				{isAuthenticated && (
					<Button
						tracking={{
							id: "goto-profile",
							category: TRACKING_CATEGORY.profile,
						}}
						navigateTo={Screens.AccountProfile}
					>
						Back to Your Profile
					</Button>
				)}
			</View>
		</Layout>
	);
};

const styles = StyleSheet.create({
	headerText: {
		fontSize: 24,
		fontWeight: "bold",
		color: dpBlue,
		paddingTop: 64,
	},
	subHeaderText: {
		color: dpLessMutedStill,
		fontSize: 16,
		paddingTop: 16,
		paddingBottom: 64,
	},
	container: {
		alignItems: "center",
	},
});

interface RefillCompleteProps {}

export default RefillComplete;
