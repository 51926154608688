import React, { FunctionComponent } from 'react';
import type { ImageRequireSource } from 'react-native';
import { Image } from "react-native";

/**
 * Create an icon from an SVG that can easily be colored
 *
 * Sample usage:
 * ```
 * import clipboardForward from '<relative_path>/assets/icons/clipboard-forward.svg';
 * ...
 * return (
 *   <>
 *     ...
 * 	   <Icon
 * 		   icon={clipboardForward}
 * 		   color={'#fff'}
 * 	   />
 *     ...
 * 	 </>
 * )
 */
const Icon: FunctionComponent<IconProps> = (props) => {
	return (
		<Image
			source={props.icon}
			style={{
				width: props.width ?? 24,
				height: props.height ?? 24,
				tintColor: props.color ?? '#000',
			}}
		/>
	);
}

interface IconProps {
	icon: ImageRequireSource;
	width?: number;
	height?: number;
	color?: string;
}

export default Icon;
