import React, { FunctionComponent } from "react";
import TextField from "modules/form/fields/TextField.component";
import Layout from "modules/layout/Layout.component";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { dpBlue, dpLessMutedStill } from "modules/theme/theme";
import { FormProvider, useForm } from "react-hook-form";
import { Card, Title } from "react-native-paper";
import Button from "modules/form/button/Button.component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Screens } from "modules/common/screens/screens.constants";
import { addProfile } from "modules/account/store/account.actions";
import Icon from "modules/material/icon/Icon.component";
import ClipboardForwardIcon from "../../assets/icons/clipboard-forward.svg";
import PharmacySelectionForm from "modules/refill/PharmacySelectForm.component";
import { selectAccount, selectAddProfileState, selectProfile } from "modules/account/store/account.selectors";
import { RequestState } from "modules/store/types";
import MessageBox from "modules/material/message-box/MessageBox.component";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import DateTimeField from "modules/material/date-time/DateTime.component";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";
import MaskedInputField from "modules/material/masked-input/MaskedInputField.component";

const AddProfile: FunctionComponent<AddProfileProps> = (props) => {
	const profile = useSelector(selectProfile());
	const account = useSelector(selectAccount());
	const address = account?.account_details.addresses?.find((x) => x.address_id === profile?.patient.default_address_id);
	const methods = useForm<AddProfileForm>({
		defaultValues: {
			dateOfBirth: "",
			firstName: "",
			lastName: "",
			phone: "",
			locationId: "",
			deliveryMethodId: "",
			emailAddress: "",
			address1: address?.street_one ?? "",
			address2: address?.street_two ?? "",
			postalCode: address?.zip ?? "",
			city: address?.city ?? "",
			state: address?.state ?? "",
			rxNumber: "",
		},
	});
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const route = useRoute();
	const addProfileState = useSelector(selectAddProfileState());

	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	const handleSubmit = (data: AddProfileForm) => {
		dispatch(addProfile(data, navigation, route));
	};

	return (
		<Layout>
			<View style={styles.root}>
				<Card elevation={4} style={styles.card}>
					<FormProvider {...methods}>
						<View style={styles.cardHeader}>
							<Icon icon={ClipboardForwardIcon} color={"#fff"} />
							<Title style={styles.cardHeaderText}>Add Profile</Title>
						</View>
						<Card.Content style={[styles.contentContainer, isSmallScreen ? styles.contentContainerSmall : styles.contentContainerLarge]}>
							<View style={styles.content}>
								{addProfileState === RequestState.error && <MessageBox type="error" message="Error adding profile" />}
								<View style={[isSmallScreen ? styles.mobileRow : styles.row]}>
									<View style={styles.column}>
										<TextField
											label="First Name"
											name="firstName"
											rules={{
												required: "First name is required",
											}}
										/>
									</View>
									<View style={styles.column}>
										<TextField
											label="Last Name"
											name="lastName"
											rules={{
												required: "Last name is required",
											}}
										/>
									</View>
								</View>
								<View style={[isSmallScreen ? styles.mobileRow : styles.row]}>
									<View style={styles.column}>
										<MaskedInputField
											name="phone"
											label="Cell Phone"
											rules={{
												required: "Cell phone is required",
												pattern: {
													value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
													message: "Invalid phone number",
												},
											}}
											inputMaskType="phone"
										/>
									</View>
									<View style={styles.column}>
										<MaskedInputField
											label="Date of Birth"
											name="dateOfBirth"
											rules={{
												required: "Date of Birth is required",
											}}
											inputMaskType="date"
										/>
									</View>
								</View>
								<View style={styles.row}>
									<View style={styles.column}>
										<TextField
											label="Email Address"
											name="emailAddress"
											rules={{
												required: "Email is required",
												pattern: {
													value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
													message: "Invalid email address",
												},
											}}
											onSubmit={methods.handleSubmit(handleSubmit)}
										/>
									</View>
								</View>
								<View style={styles.row}>
									<View style={styles.column}>
										<TextField
											label="Rx Number"
											name="rxNumber"
											rules={{
												required: "Rx Number is required",
											}}
											onSubmit={methods.handleSubmit(handleSubmit)}
										/>
									</View>
								</View>
								<PharmacySelectionForm />
							</View>
						</Card.Content>
					</FormProvider>
				</Card>
				<View style={styles.actions}>
					<View style={{ alignItems: "center", justifyContent: "center" }}>
						<Button
							tracking={{
								id: "cancel-refill",
								category: TRACKING_CATEGORY.refill,
							}}
							mode="text"
							navigateTo={Screens.AccountProfile}
							textButtonStyle={{ color: dpLessMutedStill }}
						>
							cancel
						</Button>
					</View>
					<View style={{ marginLeft: 32 }}>
						<Button
							tracking={{
								id: "add-profile",
								category: TRACKING_CATEGORY.profile,
							}}
							onPress={methods.handleSubmit(handleSubmit)}
							disabled={addProfileState === RequestState.loading}
							loading={addProfileState === RequestState.loading}
						>
							Add Profile
						</Button>
					</View>
				</View>
			</View>
		</Layout>
	);
};

interface AddProfileProps { }

export interface AddProfileForm {
	dateOfBirth: string;
	firstName: string;
	lastName: string;
	phone?: string;
	locationId: string;
	deliveryMethodId: string;
	address1: string;
	address2: string;
	postalCode: string;
	city: string;
	state: string;
	emailAddress: string;
	rxNumber: string;
}

const styles = StyleSheet.create({
	root: {
		width: "100%",
	},
	contentContainer: {
		justifyContent: "center",
		flexDirection: "row",
		paddingTop: 32,
	},
	contentContainerLarge: {
		width: 768,
	},
	contentContainerSmall: {
		width: "100%",
	},
	content: {
		// width: 460,
		flex: 1,
	},
	card: {
		// force header image to not hide the border radius of the card
		overflow: "hidden",
	},
	cardHeader: {
		width: "100%",
		height: 70,
		backgroundColor: dpBlue,
		paddingLeft: 16,
		flexDirection: "row",
		alignItems: "center",
	},
	cardHeaderText: {
		color: "#fff",
		fontWeight: "bold",
		fontSize: 20,
		zIndex: 1,
		paddingLeft: 8,
	},
	actions: {
		flexDirection: "row",
		marginTop: 32,
		justifyContent: "flex-end",
	},
	row: {
		flexDirection: "row",
	},
	mobileRow: {
		flexDirection: "column",
	},
	column: {
		flex: 1,
		paddingLeft: 16,
		paddingRight: 16,
	},
	header: {
		flexDirection: "row",
		paddingBottom: 16,
	},
	title: {
		color: dpBlue,
		fontWeight: "bold",
	},
});

export default AddProfile;
