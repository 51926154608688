import { dpBlue, dpGreen, dpLessMutedStill, dpMuted } from "modules/theme/theme";
import React, { FunctionComponent } from "react";
import { View, StyleSheet, Text } from "react-native";

const WizardProgress: FunctionComponent<WizardProgressProps> = (props) => {
	return (
		<View style={styles.root}>
			{new Array(props.numberOfSteps).fill(0).map((x, index) => (
				<View key={index} style={styles.item}>
					<View style={[styles.marker, getMarkerProgressStyle(index, props.currentStep)]}></View>
					{index < props.numberOfSteps - 1 && <View style={[styles.line, getLineProgressStyle(index, props.currentStep)]}></View>}
				</View>
			))}
		</View>
	);
};

const getMarkerProgressStyle = (index: number, currentStep: number) => {
	if (currentStep === index + 1) {
		return styles.markerCurrentStep;
	} else if (currentStep > index) {
		return styles.markerComplete;
	} else {
		return styles.markerIncomplete;
	}
};

const getLineProgressStyle = (index: number, currentStep: number) => {
	return index > currentStep - 2 ? styles.lineIncomplete : styles.lineComplete;
};

const styles = StyleSheet.create({
	root: {
		flexDirection: "row",
	},
	marker: {
		width: 16,
		height: 16,
		borderRadius: 8,
	},
	markerComplete: {
		backgroundColor: dpBlue,
	},
	markerIncomplete: {
		backgroundColor: dpMuted,
	},
	markerCurrentStep: {
		backgroundColor: dpGreen,
	},
	lineComplete: {
		backgroundColor: dpBlue,
	},
	lineIncomplete: {
		backgroundColor: dpMuted,
	},
	line: {
		height: 2,
		width: 40,
	},
	item: {
		flexDirection: "row",
		alignItems: "center",
	},
});

interface WizardProgressProps {
	numberOfSteps: number;
	currentStep: number;
}

export default WizardProgress;
