import { applyMiddleware, compose, createStore, CombinedState, Store, Middleware, StoreEnhancer } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./rootReducer";
import { configMap } from "modules/common/constants";

const configureStore = (preloadedState?: any): Store<CombinedState<any>> => {
	const middleware = [thunkMiddleware] as Middleware[];
	if (configMap.isDevelopment) {
		middleware.push(createLogger());
	}
	const middlewareEnhancer = applyMiddleware(...middleware);

	const enhancers = [middlewareEnhancer];
	const composedEnhancers = compose(...enhancers) as StoreEnhancer<unknown, {}>;

	const store = createStore(rootReducer(), preloadedState, composedEnhancers);
	return store;
};

export default configureStore;
