import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { RootReducerState } from "./rootReducer";
import { Route as ReactNavigationRoute } from "@react-navigation/native";

export type GetState = () => RootReducerState;
export enum RequestState {
	idle,
	loading,
	success,
	error,
}
export type Navigation = NavigationProp<ParamListBase>;
export type Route = ReactNavigationRoute<string, object | undefined>;

export type RowStates = "read" | "edit" | "saving";
