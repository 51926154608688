import { RequestState, RowStates } from "modules/store/types";
import { dpLessMuted, dpError, dpGreen } from "modules/theme/theme";
import React, { FunctionComponent } from "react";
import { View, StyleSheet } from "react-native";
import TextField from "modules/form/fields/TextField.component";
import Button from "modules/form/button/Button.component";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AnonymousMedication, Medication } from "./store/refill.action-types";
import { upsertMedication, setRemoveMedicationState, setEditMedicationState } from "./store/refill.actions";
import SaveOutline from "../../assets/icons/save-outline.svg";
import CancelCircle from "../../assets/icons/cancel-circle.svg";
import DeleteOutline from "../../assets/icons/delete-outline.svg";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import { selectEditMedicationRowState } from "./store/refill.selectors";

const MedicationListRowEdit: FunctionComponent<MedicationListRowEditProps> = (props) => {
	const medication = props.medication;
	// guest refill will have less fields for medication, but is guaranteed to have a temporary medication_list_medication_id assigned
	const medicationId = medication.medication_list_medication_id!;
	const dispatch = useDispatch();
	const methods = useForm<EditMedicationForm>({
		defaultValues: { rx_num: medication.rx_num, rx_name: medication.rx_name },
	});

	const rowState = useSelector(selectEditMedicationRowState(medicationId));
	console.log(rowState, medication);

	const handleSaveMedication = (data: EditMedicationForm) => {
		const toSaveMedication = {
			...props.medication,
			...data,
		};
		dispatch(upsertMedication(toSaveMedication));
	};

	const showDeleteWarning = () => {
		dispatch(setRemoveMedicationState(RequestState.idle, medication));
	};

	const handleCancelEdit = () => {
		dispatch(setEditMedicationState(medication, "read"));
	};

	return (
		<FormProvider {...methods}>
			<View style={[styles.column, { width: 120, marginRight: 120 }]}>
				<TextField type="text" label="Rx #" name="rx_num" disabled={props.state === "saving"} />
			</View>

			<View style={[styles.column, { flex: 1 }]}>
				<TextField type="text" label="Medication Name" name="rx_name" disabled={props.state === "saving"} />
			</View>

			<View style={[styles.column, { width: 80, justifyContent: "flex-end" }]}>
				<Button
					tracking={{ id: "cancel-edit-medication", category: TRACKING_CATEGORY.refill }}
					icon={CancelCircle}
					iconColor={dpLessMuted}
					mode={"icon"}
					onPress={handleCancelEdit}
					disabled={rowState === "saving"}
					textButtonStyle={{ color: dpLessMuted }}
				>
					{/* cancel */}
				</Button>

				<Button
					tracking={{ id: "delete-medication", category: TRACKING_CATEGORY.refill }}
					icon={DeleteOutline}
					iconColor={dpError}
					mode={"icon"}
					onPress={showDeleteWarning}
					textButtonStyle={{ color: dpError }}
					disabled={rowState === "saving"}
				>
					{/* delete */}
				</Button>

				<Button
					tracking={{ id: "save-medication", category: TRACKING_CATEGORY.refill }}
					icon={SaveOutline}
					iconColor={dpGreen}
					mode={"icon"}
					onPress={methods.handleSubmit(handleSaveMedication)}
					disabled={rowState === "saving"}
					textButtonStyle={{ color: dpGreen }}
				>
					{/* save */}
				</Button>
			</View>
		</FormProvider>
	);
};

interface MedicationListRowEditProps {
	medication: Medication | AnonymousMedication;
	state: RowStates;
}

interface EditMedicationForm {
	rx_num: string;
	rx_name?: string;
}

const styles = StyleSheet.create({
	column: {
		alignItems: "center",
		flexDirection: "row",
	},
});

export default MedicationListRowEdit;
