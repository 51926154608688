import { Pharmacy } from "./pharmacy";

export const FETCH_PHARMACY = 'FETCH_PHARMACY';
export const RECEIVE_PHARMACY = 'RECEIVE_PHARMACY';
export const FETCH_PHARMACY_ERROR = 'FETCH_PHARMACY_ERROR';

export interface FetchPharmacyAction  {
  type: typeof FETCH_PHARMACY;
};

export interface FetchPharmacyErrorAction  {
	type: typeof FETCH_PHARMACY_ERROR;
  };

export interface ReceivePharmacyAction  {
	type: typeof RECEIVE_PHARMACY;
	payload: Pharmacy;
};

export type PharmacyActionTypes = FetchPharmacyAction | ReceivePharmacyAction | FetchPharmacyErrorAction;
