import { SnackbarActionTypes, SnackbarItem, SET_SNACKBAR_ITEMS } from "./snackbar.action-types";

const defaultState = () => {
	return [] as Array<SnackbarItem>;
};

export type SnackbarReducerState = Array<SnackbarItem>;

export const SnackbarReducer = (state = defaultState(), action: SnackbarActionTypes) => {
	switch (action.type) {
		case SET_SNACKBAR_ITEMS:
			return action.payload;
		default:
			return state;
	}
};
