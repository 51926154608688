import React, { FunctionComponent, useEffect } from "react";
import { Appbar } from "react-native-paper";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import Logo from "./Logo.component";
import ProfileMenu from "./ProfileMenu.component";
import Snackbar from "./snackbar/Snackbar.component";
import { detectIsSmallScreen, useResponsive } from "./store/Responsive.context";

const Layout: FunctionComponent<LayoutProps> = (props) => {
	const window = useWindowDimensions();
	const { updateResponsive, responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	useEffect(() => {
		updateResponsive(window.width);
	}, [window.width]);

	return (
		<View style={styles.root}>
			<Appbar.Header style={styles.appbar}>
				<View>
					<Logo />
				</View>
				<View style={styles.profileMenu}>
					<ProfileMenu />
				</View>
			</Appbar.Header>
			<View style={styles.contentContainer}>
				<View style={[isSmallScreen ? styles.contentSmall : styles.content]}>{props.children}</View>
			</View>
			<Snackbar />
		</View>
	);
};

interface LayoutProps {}
const styles = StyleSheet.create({
	appbar: {
		display: "flex",
		height: 90,
		backgroundColor: "#fff",
		justifyContent: "center",
	},
	profileMenu: {
		display: "flex",
		position: "absolute",
		right: 8,
	},
	content: {
		maxWidth: 768,
	},
	contentSmall: {
		maxWidth: 360,
	},
	contentContainer: {
		justifyContent: "center",
		alignItems: "center",
		paddingTop: 32,
	},
	root: {
		backgroundColor: "#F2F5F8",
		paddingBottom: 128,
	},
	desktop: {
		maxWidth: 768,
	},
});

export default Layout;
