import React, { FunctionComponent, useEffect, useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import { Checkbox as CheckboxPaper } from "react-native-paper";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { dpError } from "../../theme/theme";

const CheckboxField: FunctionComponent<CheckboxFieldProps> = (props) => {
	const formContext = useFormContext();
	if (!formContext) {
		/**
		 * ## Usage
		 * ```js
		 * import { useForm, FormProvider } from 'react-hook-form';
		 * ...
		 * const methods = useForm();
		 * ...
		 * <FormProvider {...methods} >
		 *     <CheckboxField />
		 * </FormProvider>
		 * ```
		 */
		throw new Error("Checkbox field must have a parent form context");
	}

	useEffect(function initialize() {
		formContext.setValue(props.name, !!props.defaultValue);
	}, []);

	const { control, formState } = formContext;
	const error = formState.errors[props.name];

	const [state, setState] = useState(!!props.defaultValue);
	const handleChange = (onChange: (...event: any[]) => void) => {
		const newState = !state;
		setState(newState);
		onChange(newState);
	};

	return (
		<View style={styles.root}>
			<Controller
				control={control}
				render={({ field: { onChange } }) => (
					<>
						{!props.rightAlign && (
							<CheckboxPaper
								onPress={() => {
									handleChange(onChange);
								}}
								status={!state ? "unchecked" : "checked"}
								disabled={props.disabled}
								uncheckedColor={error ? dpError : undefined}
							/>
						)}

						{props.label && <Text style={styles.label}>{props.label}</Text>}
						{props.children && props.children}
						{props.rightAlign && (
							<CheckboxPaper
								onPress={() => {
									handleChange(onChange);
								}}
								status={!state ? "unchecked" : "checked"}
								disabled={props.disabled}
								uncheckedColor={error ? dpError : undefined}
							/>
						)}
					</>
				)}
				name={props.name}
				rules={props.rules}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		flexDirection: "row",
		alignItems: "center",
	},
	label: {},
});

interface CheckboxFieldProps {
	label?: string;
	name: string;
	rules?: RegisterOptions;
	onPress?: () => void;
	disabled?: boolean;
	defaultValue?: boolean;
	rightAlign?: boolean;
}

export default CheckboxField;
