import { RootReducerState } from "modules/store/rootReducer";
import { RequestState, RowStates } from "modules/store/types";
import { AnonymousMedication, Medication, Refill } from "./refill.action-types";
import { RefillError, RemoveMedicationState } from "./refill.reducer";
import { ParsedRefillResponse } from "./refill.types";

export const selectMedications =
	() =>
	(state: RootReducerState): AnonymousMedication[] =>
		state.refill.medications;

export const selectMedicationsSaved =
	() =>
	(state: RootReducerState): Medication[] =>
		state.account.profile?.medication_list ?? [];

export const selectAddMedicationState =
	() =>
	(state: RootReducerState): RequestState =>
		state.refill.addMedicationState;

export const selectSubmitRefillRequestState =
	() =>
	(state: RootReducerState): RequestState =>
		state.refill.submitRefillRequestState;

export const selectRefillError =
	() =>
	(state: RootReducerState): RefillError | undefined =>
		state.refill.refillError;

export const selectRefill =
	() =>
	(state: RootReducerState): Partial<Refill> =>
		state.refill.refill;

export const selectSubmittedRefillId =
	() =>
	(state: RootReducerState): string | undefined =>
		state.refill.submittedRefillId;

export const selectEditMedicationRowState =
	(id: string) =>
	(state: RootReducerState): RowStates => {
		return state.refill.editMedicationState.get(id) ?? "read";
	};

export const selectRemoveMedicationState =
	() =>
	(state: RootReducerState): RemoveMedicationState => {
		return state.refill.removeMedicationState;
	};

export const selectRefillIncomplete =
	() =>
	(state: RootReducerState): ParsedRefillResponse | undefined => {
		return state.refill.refillIncomplete;
	};

export const selectNewMedications =
	() =>
	(state: RootReducerState): string[] => {
		return state.refill.newMedications;
	};

export const selectAbortRefillRequestState =
	() =>
	(state: RootReducerState): RequestState =>
		state.refill.abortRefillRequestState;
