import TextField from "modules/form/fields/TextField.component";
import Layout from "modules/layout/Layout.component";
import React, { FunctionComponent, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { StyleSheet, View } from "react-native";
import { Card, Title } from "react-native-paper";
import { dpBlue, dpLessMutedStill } from "../theme/theme";
import ClipboardForwardIcon from "../../assets/icons/clipboard-forward.svg";
import Button from "modules/form/button/Button.component";
import Icon from "modules/material/icon/Icon.component";
import { useDispatch, useSelector } from "react-redux";
import { persistRefillPartial } from "./store/refill.actions";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Screens } from "modules/common/screens/screens.constants";
import { selectAccount, selectProfile, selectUpdateProfileState } from "modules/account/store/account.selectors";
import { updateProfile } from "modules/account/store/account.actions";
import { formatDate, navigate } from "modules/common/helpers";
import { RequestState } from "modules/store/types";
import DateTimeField from "modules/material/date-time/DateTime.component";
import { pageView, trackEvent, TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import WizardProgress from "modules/material/progress/WizardProgress.component";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";
import MaskedInputField from "modules/material/masked-input/MaskedInputField.component";

const UserProfile: FunctionComponent<UserProfileProps> = (props) => {
	const profile = useSelector(selectProfile());
	const account = useSelector(selectAccount());
	const methods = useForm<UserProfileForm>({
		defaultValues: {
			dateOfBirth: profile?.patient.date_of_birth !== undefined ? formatDate(profile?.patient.date_of_birth) : "",
			emailAddress: profile?.patient.email ?? account?.email ?? "",
			firstName: profile?.patient.first_name ?? "",
			lastName: profile?.patient.last_name ?? "",
			phone: profile?.patient.phone ?? "",
		},
	});

	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	const dispatch = useDispatch();
	const navigation = useNavigation();
	const route = useRoute();
	const updateProfileState = useSelector(selectUpdateProfileState());

	// first time through with an account and no profile, email should be pulled from account
	const showEmail = profile || !account?.email;

	useEffect(
		function initialize() {
			pageView("profile-info");
			if (!profile) {
				trackEvent("guest-refill-begin");
			}
		},
		[profile]
	);

	const handleSubmit = (data: UserProfileForm) => {
		if (profile) {
			dispatch(updateProfile(data, navigation, route));
		} else {
			dispatch(persistRefillPartial(data));
			navigate(navigation, route, Screens.PharmacySelection);
		}
	};

	return (
		<Layout>
			<View style={styles.root}>
				{!profile && (
					<View style={styles.header}>
						<Title style={styles.title}>Refill</Title>
						<WizardProgress numberOfSteps={3} currentStep={1} />
					</View>
				)}
				<Card elevation={4} style={styles.card}>
					<FormProvider {...methods}>
						<View style={styles.cardHeader}>
							<Icon icon={ClipboardForwardIcon} color={"#fff"} />
							<Title style={styles.cardHeaderText}>Enter Profile Info</Title>
						</View>
						<Card.Content style={[styles.contentContainer, isSmallScreen ? styles.contentContainerSmall : styles.contentContainerLarge]}>
							<View style={styles.content}>
								<View style={[isSmallScreen ? styles.rowSmall : styles.rowLarge]}>
									<View style={styles.column}>
										<TextField
											label="First Name"
											name="firstName"
											rules={{
												required: "First name is required",
											}}
											onSubmit={methods.handleSubmit(handleSubmit)}
										/>
									</View>
									<View style={styles.column}>
										<TextField
											label="Last Name"
											name="lastName"
											rules={{
												required: "Last name is required",
											}}
											onSubmit={methods.handleSubmit(handleSubmit)}
										/>
									</View>
								</View>
								<View style={[isSmallScreen ? styles.rowSmall : styles.rowLarge]}>
									<View style={styles.column}>
										<MaskedInputField
											name="phone"
											label="Cell Phone"
											rules={{
												required: "Cell phone is required",
												pattern: {
													value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
													message: "Invalid phone number",
												},
											}}
											inputMaskType="phone"
										/>
									</View>
									<View style={styles.column}>
										<MaskedInputField
											label="Date of Birth"
											name="dateOfBirth"
											rules={{
												required: "Date of Birth is required",
											}}
											inputMaskType="date"
										/>
									</View>
								</View>
								{showEmail && (
									<View style={styles.rowLarge}>
										<View style={styles.column}>
											<TextField
												label="Email Address"
												name="emailAddress"
												rules={{
													required: "Email is required",
													pattern: {
														value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
														message: "Invalid email address",
													},
												}}
												onSubmit={methods.handleSubmit(handleSubmit)}
											/>
										</View>
									</View>
								)}
							</View>
						</Card.Content>
					</FormProvider>
				</Card>
				<View style={styles.actions}>
					<View style={{ alignItems: "center", justifyContent: "center" }}>
						<Button
							tracking={{
								id: "cancel-refill",
								category: TRACKING_CATEGORY.profile,
							}}
							mode="text"
							navigateTo={Screens.AccountProfile}
							textButtonStyle={{ color: dpLessMutedStill }}
						>
							cancel
						</Button>
					</View>
					<View style={{ marginLeft: 32 }}>
						<Button
							tracking={{
								id: "submit-profile-info",
								category: TRACKING_CATEGORY.profile,
							}}
							onPress={methods.handleSubmit(handleSubmit)}
							disabled={updateProfileState === RequestState.loading}
							loading={updateProfileState === RequestState.loading}
						>
							{profile ? "Save" : "Next"}
						</Button>
					</View>
				</View>
			</View>
		</Layout>
	);
};

const styles = StyleSheet.create({
	root: {
		width: "100%",
	},
	contentContainer: {
		justifyContent: "center",
		flexDirection: "row",
		paddingTop: 32,
	},
	contentContainerLarge: {
		width: 768,
	},
	contentContainerSmall: {
		width: "100%",
	},
	content: {
		// width: 460,
		flex: 1,
	},
	card: {
		// force header image to not hide the border radius of the card
		overflow: "hidden",
	},
	cardHeader: {
		width: "100%",
		height: 70,
		backgroundColor: dpBlue,
		paddingLeft: 16,
		flexDirection: "row",
		alignItems: "center",
	},
	cardHeaderText: {
		color: "#fff",
		fontWeight: "bold",
		fontSize: 20,
		zIndex: 1,
		paddingLeft: 8,
	},
	actions: {
		flexDirection: "row",
		marginTop: 32,
		justifyContent: "flex-end",
	},
	rowLarge: {
		flexDirection: "row",
	},
	rowSmall: {
		flexDirection: "column",
	},
	column: {
		flex: 1,
		paddingLeft: 16,
		paddingRight: 16,
	},
	header: {
		flexDirection: "row",
		paddingBottom: 16,
	},
	title: {
		color: dpBlue,
		flex: 1,
		fontWeight: "bold",
	},
});

interface UserProfileProps { }

export interface UserProfileForm {
	firstName: string;
	lastName: string;
	phone: string;
	dateOfBirth: string;
	emailAddress: string;
}

export default UserProfile;
