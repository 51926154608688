import React, { FunctionComponent } from "react";
import { Text } from "react-native";
import CheckboxField from "../../form/fields/CheckboxField.component";
import { dpLessMutedStill } from "../../theme/theme";

const NewsletterSignUpField: FunctionComponent<NewsletterSignUpFieldProps> = (props) => {
	return (
		<CheckboxField name="newsletter" defaultValue={true} rightAlign={props.rightAlign}>
			<Text style={{ fontSize: 16, color: dpLessMutedStill }}>Sign up for pharmacy newsletter</Text>
		</CheckboxField>
	);
};

interface NewsletterSignUpFieldProps {
	rightAlign?: boolean;
}

export default NewsletterSignUpField;
