import { UNITED_STATES_STATES } from "modules/common/constants";
import TextField from "modules/form/fields/TextField.component";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";
import SelectField from "modules/material/dropdown/SelectField.component";
import Icon from "modules/material/icon/Icon.component";
import { dpBlue } from "modules/theme/theme";
import React, { FunctionComponent } from "react";
import { StyleSheet, View, Text } from "react-native";
import AmbulanceIcon from "../../assets/icons/ambulance.svg";

const DeliveryAddress: FunctionComponent<DeliveryAddressProps> = (props) => {
	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	return (
		<>
			<View style={styles.title}>
				<Icon icon={AmbulanceIcon} color={dpBlue} />
				<Text style={{ color: dpBlue, fontWeight: "bold", fontSize: 20, paddingLeft: 8 }}>Delivery Address</Text>
			</View>
			<View style={[isSmallScreen ? styles.rowSmall : styles.rowLarge]}>
				<View style={styles.column}>
					<TextField
						label="Address"
						name="address1"
						rules={{
							required: "Address is required",
						}}
					/>
				</View>
				<View style={styles.column}>
					<TextField label="Apt/Unit" name="address2" />
				</View>
			</View>
			<View style={[isSmallScreen ? styles.rowSmall : styles.rowLarge]}>
				<View style={styles.column}>
					<TextField
						label="Postal Code"
						name="postalCode"
						rules={{
							required: "Zip Code is required",
							pattern: {
								value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
								message: "Zip code is invalid",
							},
						}}
					/>
				</View>
			</View>
			<View style={[isSmallScreen ? styles.rowSmall : styles.rowLarge]}>
				<View style={styles.column}>
					<TextField
						label="City"
						name="city"
						rules={{
							required: "City is required",
						}}
					/>
				</View>
				<View style={styles.column}>
					<SelectField label="State" name="state" items={UNITED_STATES_STATES} />
				</View>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	rowLarge: {
		flexDirection: "row",
	},
	rowSmall: {
		flexDirection: "column",
	},
	column: {
		flex: 1,
		paddingLeft: 16,
		paddingRight: 16,
	},
	title: {
		paddingLeft: 16,
		flexDirection: "row",
		marginBottom: 24,
	},
});

interface DeliveryAddressProps {}

export default DeliveryAddress;
