import { Route, useLinkTo, useNavigation, useRoute } from "@react-navigation/native";
import { navigate } from "modules/common/helpers";
import { Screens } from "modules/common/screens/screens.constants";
import { Navigation } from "modules/store/types";
import React, { FunctionComponent, ReactNode } from "react";
import { StyleSheet, View, Text, Linking, Pressable } from "react-native";
import { ActivityIndicator, Button as ButtonPaper, IconButton } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";
import { trackEvent, TRACKING_CATEGORY } from "../../common/tracking/tracking";
import { dpError, dpGreen } from "../../theme/theme";

const Button: FunctionComponent<ButtonProps> = (props) => {
	if (props.navigation || props.withoutNavigation) {
		return <ButtonCore {...props} navigation={props.navigation} />;
	}

	return <ButtonWithNavigation {...props} />;
};

const ButtonWithNavigation: FunctionComponent<ButtonProps> = (props) => {
	const navigation = useNavigation();
	const route = useRoute();
	return <ButtonCore {...props} navigation={navigation} route={route} />;
};

const ButtonCore: FunctionComponent<ButtonProps & { navigation?: Navigation }> = (props) => {
	const mode = props.mode || "standard";
	const linkTo = useLinkTo();

	const handleOnPress = () => {
		if (props.disabled) {
			// only applicable to text links, react-native-paper buttons will not trigger if disabled
			return;
		}
		trackEvent(props.tracking.id + "-button-press", props.tracking.category);

		if (props.onPress) {
			props.onPress();
		}

		if (props.linkTo) {
			linkTo(props.linkTo);
		}

		if (props.openUrl) {
			Linking.openURL(props.openUrl);
		}

		if (props.navigateTo) {
			if (!props.navigation || !props.route) {
				throw new Error("navigateTo requires a navigation object");
			}
			navigate(props.navigation, props.route, props.navigateTo);
		}
	};

	if (mode === "text") {
		// mimic a traditional html anchor tag that is just text
		return (
			<Pressable onPress={handleOnPress} style={{ cursor: "pointer" } as any}>
				<Text
					style={[
						styles.text,
						{ color: props.textButtonStyle?.color ? props.textButtonStyle.color : "inherit" },
						{ fontSize: props.textButtonStyle?.fontSize },
						{ fontWeight: props.textButtonStyle?.fontWeight },
						props.style,
					]}
					dataDetectorType="link"
				>
					{props.children}
				</Text>
			</Pressable>
		);
	}

	if (mode === "flat") {
		return (
			<ButtonPaper
				style={[styles.flat, props.style]}
				contentStyle={{ height: 60 }}
				theme={{ roundness: 0 }}
				onPress={handleOnPress}
				icon={props.icon}
				disabled={props.disabled}
				loading={props.loading}
			>
				{props.children}
			</ButtonPaper>
		);
	}

	if (mode === "icon") {
		return (
			<IconButton
				icon={props.icon!}
				color={props.iconColor}
				size={props.iconSize ?? 20}
				onPress={handleOnPress}
				testID={props.testId}
				style={props.style}
				disabled={props.disabled}
			/>
		);
	}

	return (
		<View style={[styles.standard, props.style]}>
			<ButtonPaper
				mode={"contained"}
				color={props.intent === "destructive" ? dpError : dpGreen}
				onPress={handleOnPress}
				theme={{ roundness: 4 }}
				contentStyle={{ height: 52 }}
				testID={props.testId}
				icon={props.icon}
				disabled={props.disabled}
				labelStyle={{
					color: "#fff",
				}}
				loading={props.loading}
			>
				{props.children}
			</ButtonPaper>
		</View>
	);
};

const styles = StyleSheet.create({
	standard: {
		// paddingBottom: 16,
		alignItems: "center",
	},
	flat: {
		flex: 1,
		height: 60,
	},
	text: {},
});

interface ButtonProps {
	children?: ReactNode;
	onPress?: () => void;
	mode?: "standard" | "flat" | "text" | "icon";
	intent?: "primary" | "secondary" | "destructive";
	testId?: string;
	tracking: {
		id: string;
		category?: TRACKING_CATEGORY;
	};
	linkTo?: string;
	navigateTo?: Screens;
	openUrl?: string;
	icon?: IconSource;
	iconColor?: string;
	iconSize?: number;
	disabled?: boolean;
	loading?: boolean;
	textButtonStyle?: {
		color?: string;
		fontSize?: number;
		fontWeight?: "normal" | "bold" | "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900";
	};
	/* With buttons outside the main hierarchy, such as those in a portal will need to either pass in navigation or mark withoutNavigation
	to avoid the useNavigation hook which throws an exception when outside react-navigation context */
	navigation?: Navigation;
	route?: Route<string, object | undefined>;
	withoutNavigation?: boolean;
	style?: {};
}

export default Button;
