import { selectPharmacyLogo } from "modules/pharmacy/store/pharmacy.selectors";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Image, Pressable, StyleSheet } from "react-native";
import { navigate } from "modules/common/helpers";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Screens } from "modules/common/screens/screens.constants";

/**
 * Dynamically display the proper pharmacy logo based on the current pharmacy
 */
const Logo: FunctionComponent<LogoProps> = React.memo((props) => {
	const imageSrc = useSelector(selectPharmacyLogo());
	const navigation = useNavigation();
	const route = useRoute();

	const handleLogoPress = () => {
		navigate(navigation, route, Screens.AccountProfile);
	};

	if (imageSrc === undefined) {
		return <></>;
	}

	return (
		<Pressable onPress={handleLogoPress}>
			<Image source={{ uri: imageSrc }} style={styles.logo} />
		</Pressable>
	);
});

const styles = StyleSheet.create({
	logo: {
		width: 300,
		height: 50,
		resizeMode: "contain",
		cursor: "pointer",
	},
});

interface LogoProps {}

export default Logo;
