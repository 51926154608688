import { RequestState } from "modules/store/types";
import { dpLessMutedStill } from "modules/theme/theme";
import React, { FunctionComponent, useEffect } from "react";
import { Portal, Text } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import Button from "modules/form/button/Button.component";
import { useDispatch, useSelector } from "react-redux";
import { selectAddMedicationState } from "./store/refill.selectors";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import Dialog from "modules/material/dialog/Dialog.component";
import TextField from "modules/form/fields/TextField.component";
import { selectIsAuthenticated } from "modules/account/store/account.selectors";
import { FormProvider, useForm } from "react-hook-form";
import { addMedication, setAddMedicationState } from "./store/refill.actions";

const AddMedicationDialog: FunctionComponent<AddMedicationDialogProps> = (props) => {
	const dispatch = useDispatch();
	const addingMedicationState = useSelector(selectAddMedicationState());
	const isAuthenticated = useSelector(selectIsAuthenticated());
	const addMedicationMethods = useForm<AddMedicationForm>({ defaultValues: { rx_num: "", rx_name: "" } });

	const handleClose = () => {
		addMedicationMethods.reset();
		props.onClose();
	};

	const handleAddMedication = (data: AddMedicationForm) => {
		dispatch(addMedication(data));
	};

	useEffect(() => {
		if (addingMedicationState === RequestState.success) {
			dispatch(setAddMedicationState(RequestState.idle));
			handleClose();
		}
	}, [addingMedicationState]);

	return (
		<Portal>
			<Dialog visible={props.visible} onDismiss={handleClose} style={styles.dialog}>
				<View style={styles.titleContainer}>
					<Text style={styles.dialogText}>Add Medication</Text>
				</View>
				<View style={{ width: 360 }}>
					<FormProvider {...addMedicationMethods}>
						<View>
							<TextField
								label="Rx Number"
								name="rx_num"
								rules={{
									required: "Rx Number is required",
								}}
								disabled={addingMedicationState === RequestState.loading}
								onSubmit={addMedicationMethods.handleSubmit(handleAddMedication)}
							/>
						</View>
						<View>
							<TextField
								label="Rx Name"
								name="rx_name"
								helpMessage={isAuthenticated ? "" : "(Optional)"}
								rules={{
									required: {
										value: isAuthenticated,
										message: "Medication name is required",
									},
								}}
								disabled={addingMedicationState === RequestState.loading}
								onSubmit={addMedicationMethods.handleSubmit(handleAddMedication)}
							/>
						</View>
					</FormProvider>
				</View>
				<View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
					<View style={{ alignItems: "center", justifyContent: "center" }}>
						<Button
							intent="destructive"
							withoutNavigation={true}
							tracking={{ id: "cancel-add-medication", category: TRACKING_CATEGORY.refill }}
							onPress={handleClose}
							mode="text"
							textButtonStyle={{ color: dpLessMutedStill }}
						>
							Cancel
						</Button>
					</View>
					<View style={{ marginLeft: 32 }}>
						<Button
							intent="primary"
							withoutNavigation={true}
							tracking={{ id: "confirm-add-medication", category: TRACKING_CATEGORY.refill }}
							disabled={addingMedicationState === RequestState.loading}
							loading={addingMedicationState === RequestState.loading}
							onPress={addMedicationMethods.handleSubmit(handleAddMedication)}
						>
							Add
						</Button>
					</View>
				</View>
			</Dialog>
		</Portal>
	);
};

interface AddMedicationDialogProps {
	visible: boolean;
	onClose: Function;
}

interface AddMedicationForm {
	rx_num: string;
	rx_name: string;
}

const styles = StyleSheet.create({
	dialog: {
		maxWidth: 420,
		alignSelf: "center",
		position: "fixed",
	} as any,
	dialogText: {
		fontWeight: "bold",
		fontSize: 18,
	},
	titleContainer: {
		marginBottom: 32,
	},
});

export default AddMedicationDialog;
