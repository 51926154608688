import { Platform } from "react-native";
import { configMap } from "../constants";

export const initialize = () => {
	if (Platform.OS === "web") {
		if (configMap.pendoId !== "") {
			(function (apiKey) {
				(function (p?: any, e?: any, n?: any, d?: any, o?: any) {
					var v: any, w: any, x: any, y: any, z: any;
					o = p[d] = p[d] || {};
					o._q = o._q || [];
					v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
					for (w = 0, x = v.length; w < x; ++w)
						(function (m) {
							o[m] =
								o[m] ||
								function () {
									o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
								};
						})(v[w]);
					y = e.createElement(n);
					y.async = !0;
					y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
					z = e.getElementsByTagName(n)[0];
					z.parentNode.insertBefore(y, z);
				})(window, document, "script", "pendo");
			})(configMap.pendoId);
			// as per pendo reps, yes have these place holder strings in here
			(window as any).pendo?.initialize({
				visitor: {
					id: "VISITOR-UNIQUE-ID",
				},
				account: {
					id: "ACCOUNT-UNIQUE-ID",
				},
			});
		}
	}
};
