import React, { FunctionComponent, useState } from "react";
import { Menu } from "react-native-paper";
import { StyleSheet, View } from "react-native";
import { dpLessMutedStill } from "../theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated } from "modules/account/store/account.selectors";
import Button from "modules/form/button/Button.component";
import UserCircle from "../../assets/icons/user-circle.svg";
import { logout } from "modules/account/store/account.actions";
import { useNavigation, useRoute } from "@react-navigation/native";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";

const ProfileMenu: FunctionComponent<ProfileMenuProps> = (props) => {
	const isAuthenticated = useSelector(selectIsAuthenticated());
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const route = useRoute();

	const handleProfileClick = () => {
		setIsMenuOpen(true);
	};

	const handleCloseMenu = () => {
		setIsMenuOpen(false);
	};

	const handleLogout = () => {
		setIsMenuOpen(false);
		dispatch(logout(navigation, route));
	};

	return (
		<View>
			{isAuthenticated && (
				<Menu
					visible={isMenuOpen}
					onDismiss={handleCloseMenu}
					style={styles.menu}
					contentStyle={styles.menuContent}
					anchor={
						<Button
							mode={"icon"}
							onPress={handleProfileClick}
							icon={UserCircle}
							iconColor={dpLessMutedStill}
							tracking={{
								id: "open-profile-menu",
								category: TRACKING_CATEGORY.profile,
							}}
							iconSize={30}
						>
							{/* icon */}
						</Button>
					}
				>
					<Menu.Item onPress={handleLogout} title="Sign Out" />
				</Menu>
			)}
		</View>
	);
};

interface ProfileMenuProps {}

const styles = StyleSheet.create({
	menu: {},
	menuContent: {
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		paddingTop: 0,
		borderTopWidth: 2,
		borderTopColor: "#f8cf60",
	},
});

export default ProfileMenu;
