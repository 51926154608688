import React, { FunctionComponent } from "react";
import { TextInput, Text } from "react-native-paper";
import { dpBlue, dpError, dpGreen, dpLessMuted, dpMuted } from "../../theme/theme";
import { StyleSheet, View } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { DropDownFieldProps } from "./dropdown.helpers";
import { detectIsSafari } from "modules/common/helpers";

/**
 * Render an html native select drop down field.
 */
const SelectField: FunctionComponent<DropDownFieldProps> = (props) => {
	const formContext = useFormContext();
	if (!formContext) {
		/**
		 * ## Usage
		 * ```js
		 * import { useForm, FormProvider } from 'react-hook-form';
		 * ...
		 * const methods = useForm();
		 * ...
		 * <FormProvider {...methods} >
		 *     <SelectField />
		 * </FormProvider>
		 * ```
		 */
		throw new Error("Select field must have a parent form context");
	}

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		formContext.setValue(props.name, event.target.value);
		const item = props.items.find((x) => x.value === event.target.value);
		if (props.onSelection) {
			props.onSelection(item!);
		}
	};

	const defaultValue = formContext.getValues(props.name) ?? props.defaultValue ?? "";
	const { control, formState } = formContext;
	const error = formState.errors[props.name];

	const isSafari = detectIsSafari();

	let selectStyle = {
		fontSize: "16px",
		padding: "11px 0 12px 12px",
		margin: "4px 14px 2px 2px",
		border: "0",
		outline: 0,
		fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
		color: dpGreen,
		fontWeight: "bold",
		cursor: "pointer",
	} as any;

	if (isSafari) {
		selectStyle.WebkitAppearance = "none";
	}

	return (
		<View style={styles.root}>
			{props.label && <Text style={styles.label}>{props.label}</Text>}
			<Controller
				control={control}
				render={({ field: { onChange, onBlur, value } }) => (
					<TextInput
						theme={{ roundness: 8, colors: { primary: dpGreen, background: "#fff", placeholder: dpMuted } as any }}
						onBlur={onBlur}
						onChange={onChange}
						mode="outlined"
						value={value}
						style={styles.textInput}
						render={(textInputProps) => (
							<>
								<select
									name={props.name}
									onFocus={textInputProps.onFocus}
									onBlur={textInputProps.onBlur}
									/* styles on native web element cannot use StyleSheet values */
									style={selectStyle}
									defaultValue={defaultValue}
									onChange={handleChange}
								>
									{props.items.map((x, index) => (
										<option value={x.value} key={x.value}>
											{x.title}
										</option>
									))}
								</select>
							</>
						)}
					/>
				)}
				name={props.name}
			/>
			{error && <Text style={styles.errorMessage}>{error.message}</Text>}
			{props.helpMessage && <Text style={styles.helpMessage}>{props.helpMessage}</Text>}
		</View>
	);
};

const styles = StyleSheet.create({
	textInput: {
		color: dpGreen,
	},
	label: {
		fontSize: 18,
		color: dpBlue,
		fontWeight: "bold",
		paddingBottom: 4,
	},
	root: {
		flex: 1,
		paddingBottom: 32,
	},
	helpMessage: {
		color: dpLessMuted,
		fontSize: 12,
		marginTop: 12,
	},
	errorMessage: {
		color: dpError,
		fontSize: 12,
		marginTop: 12,
	},
});

export default SelectField;
