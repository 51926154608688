import React, { FunctionComponent } from "react";
import { TextInput, Text } from "react-native-paper";
import { dpBlue, dpError, dpGreen, dpLessMuted, dpMuted } from "../../theme/theme";
import { StyleSheet, View } from "react-native";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

/**
 * Placeholder for more dynamic masked inputs, for now just handle phone
 */
const MaskedInputField: FunctionComponent<MaskedInputFieldProps> = (props) => {
	const formContext = useFormContext();
	const mask = "   -   -    ";
	const dateMask = "  /  /    ";

	if (!formContext) {
		/**
		 * ## Usage
		 * ```js
		 * import { useForm, FormProvider } from 'react-hook-form';
		 * ...
		 * const methods = useForm();
		 * ...
		 * <FormProvider {...methods} >
		 *     <MaskedInputField />
		 * </FormProvider>
		 * ```
		 */
		throw new Error("DateTime field must have a parent form context");
	}

	const { control, formState } = formContext;
	const error = formState.errors[props.name];

	const lastKey = React.useRef("");

	const applyMask = (value: string, isBackspace: boolean) => {
		const lastIndex = value.length - 1;

		if (isBackspace) {
			if (mask[lastIndex + 1] === "-") {
				value = value.substr(0, value.length - 1);
			}
		} else {
			if (mask[lastIndex + 1] === "-") {
				return value + "-";
			}
		}

		if (value.length > 12) {
			value = value.substr(0, 12);
		}

		return value;
	};

	const applyDateMask = (value: string, isBackspace: boolean) => {
		const lastIndex = value.length - 1;

		if (isBackspace) {
			if (dateMask[lastIndex + 1] === "/") {
				value = value.substr(0, value.length - 1);
			}
		} else {
			if (dateMask[lastIndex + 1] === "/") {
				return value + "/";
			}
		}

		if (value.length > 10) {
			value = value.substr(0, 10);
		}

		return value;
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value;
		switch (props.inputMaskType) {
			case "phone":
				value = applyMask(value, lastKey.current === "Backspace")
				break;
			case "date":
				value = applyDateMask(value, lastKey.current === "Backspace")
				break
			default:
				throw new Error("Error: MaskedInputField requires a valid inputMaskType prop!");
		}
		formContext.setValue(props.name, value);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, value: string) => {
		lastKey.current = event.key;
	};

	return (
		<View style={styles.root}>
			{props.label && <Text style={styles.label}>{props.label}</Text>}
			<Controller
				control={control}
				render={({ field: { onChange, onBlur, value } }) => (
					<TextInput
						theme={{ roundness: 8, colors: { primary: dpGreen, background: "#fff", placeholder: dpMuted } as any }}
						onBlur={onBlur}
						onChange={onChange}
						mode="outlined"
						value={value}
						style={styles.textInput}
						render={(textInputProps) => (
							<>
								<input
									type="tel"
									name={props.name}
									onChange={handleChange}
									onKeyDown={(e) => {
										handleKeyPress(e, value);
									}}
									onFocus={textInputProps.onFocus}
									onBlur={textInputProps.onBlur}
									// defaultValue={formContext.getValues(props.name)}
									style={{
										fontSize: "16px",
										margin: "14px",
										border: "0",
										outline: 0,
										fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
										color: dpGreen,
										fontWeight: "bold",
									}}
									value={value}
								/>
							</>
						)}
					/>
				)}
				name={props.name}
				rules={props.rules}
			/>
			{error && <Text style={styles.errorMessage}>{error.message}</Text>}
			{props.helpMessage && <Text style={styles.helpMessage}>{props.helpMessage}</Text>}
		</View>
	);
};

const styles = StyleSheet.create({
	textInput: {
		color: dpGreen,
	},
	label: {
		fontSize: 18,
		color: dpBlue,
		fontWeight: "bold",
		paddingBottom: 4,
	},
	root: {
		paddingBottom: 32,
	},
	helpMessage: {
		color: dpLessMuted,
		fontSize: 12,
		marginTop: 12,
	},
	errorMessage: {
		color: dpError,
		fontSize: 12,
		marginTop: 12,
	},
});

interface MaskedInputFieldProps {
	label?: string;
	name: string;
	rules?: RegisterOptions;
	helpMessage?: string;
	inputMaskType?: string;
}

export default MaskedInputField;
