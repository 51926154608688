import { useNavigation, useRoute } from "@react-navigation/native";
import { login } from "modules/account/store/account.actions";
import { selectLoginState } from "modules/account/store/account.selectors";
import { Screens } from "modules/common/screens/screens.constants";
import CardScreen from "modules/layout/CardScreen.component";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";
import MessageBox from "modules/material/message-box/MessageBox.component";
import { RequestState } from "modules/store/types";
import React, { FunctionComponent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import ClipboardForwardIcon from "../../assets/icons/clipboard-forward.svg";
import { pageView, TRACKING_CATEGORY } from "../common/tracking/tracking";
import Button from "../form/button/Button.component";
import TextField from "../form/fields/TextField.component";
import { dpLessMuted, dpLessMutedStill } from "../theme/theme";
import UserCircleIcon from "../../assets/icons/user-circle.svg";

const Login: FunctionComponent<LoginProps> = (props) => {
	const methods = useForm<LoginForm>({
		defaultValues: { emailAddress: "", password: "" },
	});
	const dispatch = useDispatch();
	const loginState = useSelector(selectLoginState());
	const navigation = useNavigation();
	const route = useRoute();
	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	const handleSubmit = (data: LoginForm) => {
		dispatch(login(data.emailAddress, data.password, navigation, route));
	};

	useEffect(function initialize() {
		pageView("login");
	}, []);

	useEffect(
		function resetFormOnPageUnload() {
			return navigation.addListener("blur", () => {
				methods.reset();
			});
		},
		[navigation]
	);

	return (
		<CardScreen
			header="Log In"
			subHeader="The easiest way to access your prescriptions"
			headerColorStart="rgba(4,53,100,0.7)"
			headerColorEnd="rgba(243,107,127,.7)"
			methods={methods}
			content={
				<>
					{loginState === RequestState.error && <MessageBox type="error" message="Email or password is incorrect. Please try again" />}
					<TextField
						label="Email Address"
						name="emailAddress"
						rules={{
							required: "Email is required",
						}}
						onSubmit={methods.handleSubmit(handleSubmit)}
					/>
					<TextField
						label="Password"
						type="password"
						name="password"
						rules={{
							required: "Password is required",
						}}
						onSubmit={methods.handleSubmit(handleSubmit)}
					/>
					<View style={{ marginTop: 16 }}>
						<Button
							onPress={methods.handleSubmit(handleSubmit)}
							tracking={{ id: "login", category: TRACKING_CATEGORY.profile }}
							disabled={loginState === RequestState.loading}
							loading={loginState === RequestState.loading}
						>
							Log In
						</Button>
					</View>
					<View style={[{ alignItems: "center" }, { paddingTop: 16 }]}>
						<Button
							mode="text"
							tracking={{
								id: "goto-reset-password",
								category: TRACKING_CATEGORY.profile,
							}}
							navigateTo={Screens.ResetPassword}
							textButtonStyle={{ color: dpLessMutedStill }}
						>
							Forgot your password?
						</Button>
					</View>
				</>
			}
			actions={
				<>
					<View style={{ flex: 1, flexDirection: isSmallScreen ? "column" : "row" }}>
						<Button
							tracking={{ id: "dont-have-account", category: TRACKING_CATEGORY.profile }}
							icon={ClipboardForwardIcon}
							mode={"flat"}
							navigateTo={Screens.CreateAccount}
						>
							Create an account
						</Button>
						<View
							style={{
								borderTopWidth: isSmallScreen ? 2 : 0,
								borderLeftWidth: isSmallScreen ? 0 : 2,
								borderColor: dpLessMuted,
							}}
						/>
						<Button
							tracking={{ id: "refill-without-account", category: TRACKING_CATEGORY.profile }}
							icon={UserCircleIcon}
							mode={"flat"}
							navigateTo={Screens.ProfileInfo}
						>
							Refill Without an Account
						</Button>
					</View>
				</>
			}
		/>
	);
};

interface LoginProps {}

interface LoginForm {
	emailAddress: string;
	password: string;
}

export default Login;
