//http://localhost:19006/verify-account?pharmacy_id=989c956f-c8e4-44c0-b305-2e62b8835199
import React, { FunctionComponent, useEffect, useState } from "react";
import CardScreen from "modules/layout/CardScreen.component";
import { Controller, useForm } from "react-hook-form";
import { NativeSyntheticEvent, StyleSheet, Text, TextInputKeyPressEventData, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { TextInput } from "react-native-paper";
import { pageView, TRACKING_CATEGORY } from "../common/tracking/tracking";
import Button from "../form/button/Button.component";
import { dpLessMuted, dpLessMutedStill, dpGreen, dpMuted } from "../theme/theme";
import { Screens } from "modules/common/screens/screens.constants";
import ShieldCheckIcon from "../../assets/icons/shield-check.svg";
import UserCircleIcon from "../../assets/icons/user-circle.svg";
import { verifyAccount, resendAccountVerification } from "../account/store/account.actions";
import { useNavigation, useRoute } from "@react-navigation/native";
import { selectAccount, selectAccountVerificationState } from "modules/account/store/account.selectors";
import { RequestState } from "modules/store/types";
import MessageBox from "modules/material/message-box/MessageBox.component";

const AccountVerification: FunctionComponent<AccountVerificationProps> = (props) => {
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const route = useRoute();

	const queryParamToken = (route.params as any).email_token;
	const hasQueryParamToken = queryParamToken?.length === 5;

	const methods = useForm<AccountVerificationForm>({
		mode: "onChange",
		defaultValues: {
			digit1: hasQueryParamToken ? queryParamToken[0] : "",
			digit2: hasQueryParamToken ? queryParamToken[1] : "",
			digit3: hasQueryParamToken ? queryParamToken[2] : "",
			digit4: hasQueryParamToken ? queryParamToken[3] : "",
			digit5: hasQueryParamToken ? queryParamToken[4] : "",
		},
	});
	const refs = [React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null)];
	const account = useSelector(selectAccount());
	const accountVerificationState = useSelector(selectAccountVerificationState());

	useEffect(function initialize() {
		pageView("verify-account");
	}, []);

	const handleVerificationSubmit = (data: AccountVerificationForm) => {
		const verificationCode = `${data.digit1}${data.digit2}${data.digit3}${data.digit4}${data.digit5}`;
		dispatch(verifyAccount(verificationCode, navigation, route));
	};

	const handleResendVerification = () => {
		dispatch(resendAccountVerification());
	};

	useEffect(
		function handlePasteFromClipboard() {
			const firstRef = refs[0] as any;
			if (firstRef && firstRef.current && firstRef.current.root) {
				const input = firstRef.current.root;
				const pasteCallback = (event: any) => {
					let paste = event.clipboardData.getData("text");
					for (let i = 0; i < paste.length && i < 5; i++) {
						methods.setValue(`digit${i + 1}`, paste.charAt(i));
					}
					(refs[paste.length > 5 ? 4 : paste.length - 1].current as any).focus();
					methods.trigger();
					methods.handleSubmit(handleVerificationSubmit)();
				};
				input.addEventListener("paste", pasteCallback);

				return () => {
					input.removeEventListener("paste", pasteCallback);
				};
			}
		},
		[refs]
	);
	const onKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>, index: number) => {
		if (event.nativeEvent.key === "Backspace" && index > 0) {
			methods.setValue(`digit${index + 1}`, "");
			(refs[index - 1].current as any).focus();
			event.preventDefault();
			event.stopPropagation();
		}
		if (event.nativeEvent.key === "Enter" || (event.nativeEvent.key === "NumpadEnter" && index === 4)) {
			methods.handleSubmit(handleVerificationSubmit)();
		}
	};

	return (
		<CardScreen
			header="Verify Your Account"
			subHeader="Please enter the code we sent to you below"
			headerColorStart="rgba(4, 53, 100, 0.7)"
			headerColorEnd="rgb(159, 192, 223)"
			methods={methods}
			content={
				<>
					{accountVerificationState === RequestState.error && <MessageBox type="error" message="Unable to verify your account. Please try again" />}
					<Text style={{ fontSize: 16, color: dpLessMutedStill, textAlign: "center" }}>We sent an email to {account?.email}</Text>
					<Text
						style={{
							fontSize: 14,
							color: dpLessMutedStill,
							marginTop: 10,
							marginBottom: 10,
							textAlign: "center",
						}}
					>
						If it's not in your inbox, please check your spam and junk folders.
					</Text>
					<Text style={{ fontSize: 16, fontWeight: "bold", marginTop: 10, marginBottom: 10 }}>Verification Code</Text>
					<View style={styles.otpBoxesContainer}>
						{[0, 1, 2, 3, 4].map((item, index) => (
							<React.Fragment key={index}>
								<Controller
									control={methods.control}
									render={({ field: { onChange, onBlur, value } }) => (
										<TextInput
											theme={{
												roundness: 8,
												colors: {
													primary: dpGreen,
													background: "#fff",
													placeholder: (methods.formState.errors as any)[`digit${index + 1}`] ? "red" : dpMuted,
													text: dpGreen,
												} as any,
											}}
											ref={refs[index]}
											onChangeText={(value) => {
												if (value.length === 1 && index < 4) {
													(refs[index + 1].current as any).focus();
												}
											}}
											style={styles.otpBox}
											value={value}
											mode="outlined"
											keyboardType="numeric"
											maxLength={1}
											onBlur={onBlur}
											onChange={onChange}
											textContentType="oneTimeCode"
											onKeyPress={(e) => {
												onKeyPress(e, index);
											}}
										/>
									)}
									key={index}
									name={`digit${index + 1}`}
									rules={{
										required: true,
										pattern: /^\d+$/,
									}}
								/>
							</React.Fragment>
						))}
					</View>
					<View style={{ marginTop: 16 }}>
						<Button
							disabled={!methods.formState.isValid || accountVerificationState === RequestState.loading}
							loading={accountVerificationState === RequestState.loading}
							onPress={methods.handleSubmit(handleVerificationSubmit)}
							tracking={{ id: "send-verification-code", category: TRACKING_CATEGORY.profile }}
						>
							Submit
						</Button>
					</View>
				</>
			}
			actions={
				<>
					<Button
						tracking={{ id: "return-to-login", category: TRACKING_CATEGORY.profile }}
						icon={UserCircleIcon}
						mode={"flat"}
						navigateTo={Screens.Login}
					>
						Return to Login
					</Button>
					<View
						style={{
							borderLeftWidth: 2,
							borderLeftColor: dpLessMuted,
						}}
					/>
					<Button
						tracking={{ id: "resend-verification-code", category: TRACKING_CATEGORY.profile }}
						icon={ShieldCheckIcon}
						mode={"flat"}
						onPress={handleResendVerification}
					>
						Resend Code
					</Button>
				</>
			}
		/>
	);
};

interface AccountVerificationProps {}

interface AccountVerificationForm {
	digit1: string;
	digit2: string;
	digit3: string;
	digit4: string;
	digit5: string;
}

const styles = StyleSheet.create({
	//figure out spacing without needing padding left on resend code
	otpBoxesContainer: {
		display: "flex",
		flexDirection: "row",
		alignSelf: "center",
	},
	otpBox: {
		marginRight: 10,
		height: 75,
		width: 64,
		textAlign: "center",
		fontSize: 48,
		fontWeight: "bold",
	},
});

export default AccountVerification;
