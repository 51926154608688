import { Platform } from "react-native";
import { configMap } from "../constants";

export const initialize = () => {
	if (Platform.OS === "web") {
		const window: any = global.window;
		window.heap = window.heap || [];
		const { heap } = window;
		heap.load = function (e: any, t: any) {
			(window.heap.appid = e), (window.heap.config = t = t || {});
			var r = document.createElement("script");
			(r.type = "text/javascript"), (r.async = !0), (r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js");
			var a = document.getElementsByTagName("script")[0];
			a.parentNode?.insertBefore(r, a);
			for (
				var n = function (e: any) {
						return function () {
							heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
						};
					},
					p = [
						"addEventProperties",
						"addUserProperties",
						"clearEventProperties",
						"identify",
						"resetIdentity",
						"removeEventProperty",
						"setEventProperties",
						"track",
						"unsetEventProperty",
					],
					o = 0;
				o < p.length;
				o++
			)
				heap[p[o]] = n(p[o]);
		};
		if (configMap.heapId !== "") {
			window.heap.load(configMap.heapId, {});
		}
	}
};
