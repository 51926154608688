import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

export enum Screens {
	Login = "Login",
	CreateAccount = "CreateAccount",
	ResetPassword = "ResetPassword",
	ProfileInfo = "ProfileInfo",
	AddProfile = "AddProfile",
	AccountProfile = "AccountProfile",
	PharmacySelection = "PharmacySelection",
	ChangePassword = "ChangePassword",
	AddMedication = "AddMedication",
	RefillComplete = "RefillComplete",
	RefillIncomplete = "RefillIncomplete",
	AccountVerification = "AccountVerification",
}

const prefix = Linking.makeUrl("/");
export const linking: LinkingOptions = {
	prefixes: [prefix],
	config: {
		screens: {
			[Screens.Login]: "login",
			[Screens.CreateAccount]: "create-account",
			[Screens.ResetPassword]: "reset-password",
			[Screens.ProfileInfo]: "refill",
			[Screens.AccountProfile]: "",
			[Screens.PharmacySelection]: "pharmacy-select",
			[Screens.ChangePassword]: "change-password",
			[Screens.AddMedication]: "add-medication",
			[Screens.AddProfile]: "add-profile",
			[Screens.RefillComplete]: "refill-complete",
			[Screens.RefillIncomplete]: "refill-incomplete",
			[Screens.AccountVerification]: "verify-account",
		},
	},
};
