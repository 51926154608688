import { formatDate } from "modules/common/helpers";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import Button from "modules/form/button/Button.component";
import { detectIsSmallScreen, useResponsive } from "modules/layout/store/Responsive.context";
import { dpLessMuted, dpBlue, dpLessMutedStill } from "modules/theme/theme";
import React, { FunctionComponent } from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch } from "react-redux";
import { AnonymousMedication, Medication } from "./store/refill.action-types";
import { setEditMedicationState } from "./store/refill.actions";

const MedicationListRowRead: FunctionComponent<MedicationListRowReadProps> = (props) => {
	const medication = props.medication;
	const medicationAsPartial = medication as Partial<Medication>;
	const dispatch = useDispatch();

	const { responsiveState } = useResponsive();
	const isSmallScreen = detectIsSmallScreen(responsiveState);

	const handleEditMedication = () => {
		dispatch(setEditMedicationState(medication, "edit"));
	};

	return (
		<View style={{ flexDirection: "column", flex: 1 }}>
			<View style={{ flexDirection: "row", display: "flex" }}>
				<View style={[styles.column, { width: isSmallScreen ? 100 : 300 }]}>
					<Text style={styles.medicationListRowItemText}>Rx# {medication.rx_num}</Text>
				</View>

				<View style={[styles.column, { flex: 1 }]}>
					<Text style={styles.medicationListRowItemText}>{medication.rx_name}</Text>
				</View>

				<View style={[styles.column, { width: 80, justifyContent: "flex-end" }]}>
					<Button
						tracking={{ id: "edit-medication", category: TRACKING_CATEGORY.refill }}
						mode="text"
						onPress={handleEditMedication}
						textButtonStyle={{ color: dpLessMuted }}
					>
						edit
					</Button>
				</View>
			</View>
			<View style={{ flexDirection: "row" }}>
				<View style={{ flex: 1 }}>
					{medicationAsPartial.remaining_refills !== null && (
						<Text style={[styles.metadataText, { paddingRight: 32 }]}>Refills Left: {medicationAsPartial.remaining_refills}</Text>
					)}
				</View>
				<View style={{ flex: 1 }}>
					{medicationAsPartial.last_refilled_date !== null && (
						<Text style={styles.metadataText}>Last Filled: {formatDate(medicationAsPartial.last_refilled_date)}</Text>
					)}
				</View>
				<View style={{ flex: 1 }}>
					<Text style={styles.metadataText}>Last Updated: {formatDate(medicationAsPartial.updated_date)}</Text>
				</View>
			</View>
		</View>
	);
};

interface MedicationListRowReadProps {
	medication: Medication | AnonymousMedication;
}

const styles = StyleSheet.create({
	column: {
		alignItems: "center",
		flexDirection: "row",
	},
	medicationListRowItemText: {
		fontWeight: "bold",
		color: dpBlue,
		fontSize: 18,
	},
	metadataText: {
		color: dpLessMutedStill,
		fontSize: 12,
	},
});

export default MedicationListRowRead;
