import { NavigationContainerRef } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { Screens } from "modules/common/screens/screens.constants";
import AccountCreate from "modules/profile/AccountCreate.component";
import AccountProfile from "modules/profile/AccountProfile.component";
import Login from "modules/profile/Login.component";
import ResetPassword from "modules/profile/ResetPassword.component";
import React, { FunctionComponent, useEffect } from "react";
import ProfileInfo from "modules/refill/ProfileInfo.component";
import { useDispatch, useSelector } from "react-redux";
import { trySession } from "modules/account/store/account.actions";
import { selectTrySessionState } from "modules/account/store/account.selectors";
import { RequestState } from "modules/store/types";
import ChangePassword from "modules/profile/ChangePassword.component";
import PharmacySelectionScreen from "modules/refill/PharmacySelectionScreen.component";
import AddMedicationScreen from "modules/refill/AddMedicationScreen.component";
import AccountVerification from "modules/profile/AccountVerification.component";
import AddProfile from "modules/profile/AddProfile.component";
import RefillComplete from "modules/refill/RefillComplete.component";
import { ActivityIndicator } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import { dpGreen } from "modules/theme/theme";
import { RootReducerState } from "modules/store/rootReducer";
import { isEmpty } from "modules/common/helpers";
import ConditionalRoute from "./ConditionalRoute";
import RefillIncomplete from "modules/refill/RefillIncomplete.component";
import { selectPharmacy } from "modules/pharmacy/store/pharmacy.selectors";

const Stack = createStackNavigator();

const Routes: FunctionComponent<RouteProps> = (props) => {
	const dispatch = useDispatch();
	const trySessionState = useSelector(selectTrySessionState());
	const pharmacy = useSelector(selectPharmacy());

	useEffect(function initialize() {
		dispatch(trySession());
	}, []);

	if (trySessionState === RequestState.idle || trySessionState === RequestState.loading) {
		return (
			<View style={styles.center}>
				<ActivityIndicator animating={true} color={dpGreen} />
			</View>
		);
	}

	return (
		<Stack.Navigator screenOptions={{ headerShown: false, title: pharmacy.name }}>
			<Stack.Screen
				name={Screens.Login}
				options={{ title: `${pharmacy.name} - Login` }}
				children={() => (
					<ConditionalRoute condition={isNotAuthenticated} falseConditionScreen={Screens.AccountProfile}>
						<Login />
					</ConditionalRoute>
				)}
			/>
			<Stack.Screen name={Screens.CreateAccount} component={AccountCreate} options={{ title: `${pharmacy.name} - Create Account` }} />
			<Stack.Screen name={Screens.ResetPassword} component={ResetPassword} options={{ title: `${pharmacy.name} - Reset Password` }} />
			<Stack.Screen name={Screens.ProfileInfo} component={ProfileInfo} options={{ title: `${pharmacy.name} - Profile Info` }} />
			<Stack.Screen
				name={Screens.AccountProfile}
				children={() => (
					<ConditionalRoute condition={isAuthenticated} falseConditionScreen={Screens.Login}>
						<ConditionalRoute condition={isEmailVerified} falseConditionScreen={Screens.AccountVerification}>
							<AccountProfile />
						</ConditionalRoute>
					</ConditionalRoute>
				)}
			/>
			<Stack.Screen name={Screens.ChangePassword} component={ChangePassword} options={{ title: `${pharmacy.name} - Change Password` }} />
			<Stack.Screen
				name={Screens.PharmacySelection}
				options={{ title: `${pharmacy.name} - Select Pharmacy` }}
				children={() => (
					<ConditionalRoute condition={isRefillNotEmptyOrLoggedIn} falseConditionScreen={Screens.Login}>
						<PharmacySelectionScreen />
					</ConditionalRoute>
				)}
			/>
			<Stack.Screen
				name={Screens.AddMedication}
				options={{ title: `${pharmacy.name} - Add Medications` }}
				children={() => (
					<ConditionalRoute condition={isRefillNotEmpty} falseConditionScreen={Screens.Login}>
						<AddMedicationScreen />
					</ConditionalRoute>
				)}
			/>
			<Stack.Screen
				name={Screens.AccountVerification}
				options={{ title: `${pharmacy.name} - Verify Account` }}
				children={() => (
					<ConditionalRoute condition={isAuthenticated} falseConditionScreen={Screens.Login}>
						<AccountVerification />
					</ConditionalRoute>
				)}
			/>
			<Stack.Screen name={Screens.RefillComplete} component={RefillComplete} options={{ title: `${pharmacy.name} - Refill Complete` }} />
			<Stack.Screen
				name={Screens.RefillIncomplete}
				options={{ title: `${pharmacy.name} - Refill` }}
				children={() => (
					<ConditionalRoute condition={isRefillIncomplete} falseConditionScreen={Screens.AccountProfile}>
						<RefillIncomplete />
					</ConditionalRoute>
				)}
			/>
			<Stack.Screen
				name={Screens.AddProfile}
				options={{ title: `${pharmacy.name} - Add Profile` }}
				children={() => (
					<ConditionalRoute condition={isAuthenticated} falseConditionScreen={Screens.Login}>
						<AddProfile />
					</ConditionalRoute>
				)}
			/>
		</Stack.Navigator>
	);
};

const isRefillNotEmpty = (state: RootReducerState): boolean => {
	const refill = state.refill.refill;
	return !isEmpty(refill);
};

const isRefillNotEmptyOrLoggedIn = (state: RootReducerState): boolean => {
	const isRefill = isRefillNotEmpty(state);
	const isAuth = isAuthenticated(state);
	return isRefill || isAuth;
};

const isRefillIncomplete = (state: RootReducerState): boolean => {
	return state.refill.refillIncomplete !== undefined;
};

const isEmailVerified = (state: RootReducerState): boolean => {
	return !!state.account.account?.email_verified;
};

const isAuthenticated = (state: RootReducerState): boolean => {
	const account = state.account.account;
	return !!account;
};

const isAuthenticatedAndEmailVerified = (state: RootReducerState): boolean => {
	return isAuthenticated(state) && isEmailVerified(state);
};

const isNotAuthenticated = (state: RootReducerState): boolean => {
	const account = state.account.account;
	return !account;
};

const styles = StyleSheet.create({
	center: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
});

interface RouteProps {
	navigationRef: React.MutableRefObject<null | NavigationContainerRef>;
}

export default Routes;
