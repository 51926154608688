import { addGlobalContexts, newTracker, setUserId, trackPageView, trackStructEvent } from "@snowplow/browser-tracker";
import { configMap } from "../constants";

/* TODO: handle native, perhaps this? https://github.com/snowplow-incubator/snowplow-react-native-tracker */

/* Setup a single tracker, a reference is not needed to use it, eg the trackStructEvent can be called without a reference to the tracker */
export const initialize = () => {
	if (configMap.isDevelopment) {
		return;
	}
	newTracker("pp", configMap.snowplowEndpoint, {
		appId: "PP",
		platform: "web",
		respectDoNotTrack: true,
		contexts: {
			webPage: true,
		},
	});
};

export const setPharmacyContext = (pharmacyId: string) => {
	addGlobalContexts([
		{
			schema: "iglu:com.digitalpharmacist/site/jsonschema/1-0-0",
			data: {
				pharmacyId: pharmacyId,
				locationId: null,
			},
		},
	]);
};

export const trackEvent = (
	event: string,
	category: TRACKING_CATEGORY = TRACKING_CATEGORY.refill,
	property: string | undefined = undefined,
	value: number | undefined = undefined
) => {
	if (configMap.isDevelopment) {
		return;
	}
	trackStructEvent({
		action: event,
		category: category,
		property: property,
		value: value,
	});
};

export const identify = (userId: string) => {
	if (configMap.isDevelopment) {
		return;
	}
	setUserId(userId);
};

export const pageView = (name: string) => {
	if (configMap.isDevelopment) {
		return;
	}
	trackPageView({
		title: name,
	});
};

export enum TRACKING_CATEGORY {
	refill = "refill",
	profile = "profile",
}
