import { DefaultTheme } from "react-native-paper";

export const dpGreen = "#00B588";
export const dpBlue = "#043564";
export const dpMuted = "#CDD7DE";
export const dpLessMuted = "#A1AFC5";
export const dpLessMutedStill = "#6D7B8F";
export const dpWarn = "#ffda56"; // TODO: this color did not come from mockups
export const dpError = "#F36B7F";

const theme = {
	...DefaultTheme,
	roundness: 8,
	colors: {
		...DefaultTheme.colors,
		primary: dpBlue,
		accent: dpGreen,
	},
};

export default theme;
