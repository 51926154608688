import React, { FunctionComponent, useState, useEffect } from "react";
import CardScreen from "modules/layout/CardScreen.component";
import { useForm } from "react-hook-form";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import ClipboardForwardIcon from "../../assets/icons/clipboard-forward.svg";
import UserCircleIcon from "../../assets/icons/user-circle.svg";
import { pageView, TRACKING_CATEGORY } from "../common/tracking/tracking";
import Button from "../form/button/Button.component";
import TextField from "../form/fields/TextField.component";
import { dpLessMuted, dpLessMutedStill } from "../theme/theme";
import { resetPassword, resetPasswordResetState } from "../account/store/account.actions";
import { selectPasswordResetState } from "modules/account/store/account.selectors";
import { RequestState } from "modules/store/types";
import MessageBox from "modules/material/message-box/MessageBox.component";
import { Screens } from "modules/common/screens/screens.constants";
import { useNavigation } from "@react-navigation/native";

const ResetPassword: FunctionComponent<ResetPasswordProps> = (props) => {
	const methods = useForm<ResetPasswordForm>({ defaultValues: { emailAddress: "" } });
	const dispatch = useDispatch();
	const passwordResetState = useSelector(selectPasswordResetState());
	const navigation = useNavigation();

	const handleSubmit = (data: ResetPasswordForm) => {
		dispatch(resetPassword(data.emailAddress));
	};

	useEffect(
		function initialize() {
			pageView("reset-password");

			const unsubscribe = navigation.addListener("blur", () => {
				dispatch(resetPasswordResetState());
			});
			return unsubscribe;
		},
		[navigation]
	);

	return (
		<CardScreen
			header="Reset Password"
			subHeader="Enter the email on your account and we'll send you a reset password link"
			headerColorStart="rgba(4, 53, 100, 0.7)"
			headerColorEnd="rgba(248, 207, 96, 0.7)"
			methods={methods}
			content={
				<>
					{passwordResetState !== RequestState.success && (
						<>
							<TextField
								label="Account Email"
								name="emailAddress"
								rules={{
									required: "Email is required",
									pattern: {
										value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
										message: "Invalid email address",
									},
								}}
							/>
							<View style={{ marginTop: 16 }}>
								<Button
									onPress={methods.handleSubmit(handleSubmit)}
									tracking={{ id: "send-reset-link", category: TRACKING_CATEGORY.profile }}
									disabled={passwordResetState === RequestState.loading}
									loading={passwordResetState === RequestState.loading}
								>
									Send Reset Link
								</Button>
							</View>
						</>
					)}

					{passwordResetState === RequestState.success && (
						<MessageBox
							type={"success"}
							message={"If this email address is associated with an active account, you should receive a password reset link shortly"}
						/>
					)}

					{passwordResetState === RequestState.error && <MessageBox type={"error"} message={"Unable to reset your password, please try again"} />}

					<View style={[{ alignItems: "center" }, { paddingTop: 16 }]}>
						<Button
							mode="text"
							tracking={{ id: "reset-password-back-to-login", category: TRACKING_CATEGORY.profile }}
							navigateTo={Screens.Login}
							textButtonStyle={{ color: dpLessMutedStill }}
						>
							&laquo; Back to Log In
						</Button>
					</View>
				</>
			}
			actions={
				<>
					<Button
						tracking={{ id: "no-account", category: TRACKING_CATEGORY.profile }}
						icon={ClipboardForwardIcon}
						mode={"flat"}
						navigateTo={Screens.CreateAccount}
					>
						Don't Have an Account?
					</Button>
					<View
						style={{
							borderLeftWidth: 2,
							borderLeftColor: dpLessMuted,
						}}
					/>
					<Button
						tracking={{ id: "refill-without-account", category: TRACKING_CATEGORY.profile }}
						icon={UserCircleIcon}
						mode={"flat"}
						navigateTo={Screens.ProfileInfo}
					>
						Refill Without an Account
					</Button>
				</>
			}
		/>
	);
};

interface ResetPasswordProps {
	resetPasswordSuccess: boolean;
}

interface ResetPasswordForm {
	emailAddress: string;
}

export default ResetPassword;
