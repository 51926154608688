import React from 'react';
import { ColorValue, StyleSheet, View } from 'react-native';

const OUTLINE_MINIMIZED_LABEL_Y_OFFSET = -6;
const LABEL_PADDING_TOP = 8;
const MIN_HEIGHT = 64;
const MIN_DENSE_HEIGHT = 48;
const INPUT_PADDING_HORIZONTAL = 14;

/* Taken from https://github.com/callstack/react-native-paper/blob/main/src/components/TextInput/TextInputOutlined.tsx */
const Outline = ({
	theme,
	hasActiveOutline,
	activeColor,
	outlineColor,
	backgroundColor,
}: OutlineProps) => (
		<View
			pointerEvents="none"
			style={[
				styles.outline,
				// eslint-disable-next-line react-native/no-inline-styles
				{
					backgroundColor,
					borderRadius: theme.roundness,
					borderWidth: hasActiveOutline ? 2 : 1,
					borderColor: hasActiveOutline ? activeColor : outlineColor,
				},
			]}
		/>
	);

const styles = StyleSheet.create({
	placeholder: {
		position: 'absolute',
		left: 0,
		paddingHorizontal: INPUT_PADDING_HORIZONTAL,
	},
	outline: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 6,
		bottom: 0,
	},
	labelContainer: {
		paddingBottom: 0,
	},
	input: {
		flexGrow: 1,
		paddingHorizontal: INPUT_PADDING_HORIZONTAL,
		margin: 0,
		zIndex: 1,
	},
	inputOutlined: {
		paddingTop: 8,
		paddingBottom: 8,
	},
	inputOutlinedDense: {
		paddingTop: 4,
		paddingBottom: 4,
	},
});

type OutlineProps = {
	activeColor: string;
	hasActiveOutline?: boolean;
	outlineColor?: string;
	backgroundColor: ColorValue;
	theme: ReactNativePaper.Theme;
};

export default Outline;
