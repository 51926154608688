import React, { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";
import { Title } from "react-native-paper";
import { dpBlue } from "../theme/theme";
import bottle from "../../assets/icons/bottle.svg";
import Icon from "modules/material/icon/Icon.component";
import { useSelector } from "react-redux";
import { selectMedications, selectMedicationsSaved } from "./store/refill.selectors";
import MedicationListRow from "./MedicationListRow";
import RemoveMedicationWarningDialog from "./RemoveMedicationWarningDialog";
import { AnonymousMedication, Medication } from "./store/refill.action-types";

type MedicationExtended = AnonymousMedication & Medication;

const MedicationList: FunctionComponent<MedicationListProps> = (props) => {
	const anonymousRefillMedications = useSelector(selectMedications());
	const medicationsSaved = useSelector(selectMedicationsSaved());

	const byLastRefillDate = (first: MedicationExtended, second: MedicationExtended): number => {
		const timeStampValueOf = (dateValue: string): number => new Date(dateValue).getTime();
		return timeStampValueOf(second.last_refilled_date ?? second.updated_date) - timeStampValueOf(first.last_refilled_date ?? first.updated_date);
	};

	const medications = ([...anonymousRefillMedications, ...medicationsSaved] as MedicationExtended[]).sort(byLastRefillDate);

	return (
		<>
			<View style={styles.root}>
				<View>
					<View style={styles.cardHeader}>
						<Icon icon={bottle} color={"#fff"} />
						<Title style={styles.cardHeaderText}>Medications List</Title>
					</View>

					{medications.length > 0 && (
						<View style={styles.listContainer}>
							{medications.map(
								(medication: MedicationExtended, index: number): JSX.Element => (
									<MedicationListRow
										// We changed the key from `index` to `medication.medication_list_medication_id`
										// to fix the bug reported here: https://rxwiki.atlassian.net/browse/SB-107
										// We should keep an eye on this as the `index` was used because there was a different bug w/ using `medication_id`
										// https://github.com/digitalpharmacist/patient-portal/pull/58#discussion_r1008163019
										key={medication.medication_list_medication_id}
										medication={medication}
										isLast={index === medications.length - 1}
									/>
								)
							)}
						</View>
					)}
				</View>
			</View>
			<RemoveMedicationWarningDialog />
		</>
	);
};

interface MedicationListProps {}

const styles = StyleSheet.create({
	root: {
		width: "100%",
	},
	cardHeader: {
		width: "100%",
		height: 70,
		backgroundColor: dpBlue,
		paddingLeft: 16,
		flexDirection: "row",
		alignItems: "center",
	},
	cardHeaderText: {
		color: "#fff",
		fontWeight: "bold",
		fontSize: 20,
		zIndex: 1,
		paddingLeft: 8,
	},
	listContainer: {
		paddingLeft: 32,
		paddingRight: 32,
	},
});

export default MedicationList;
