import { RequestState } from "modules/store/types";
import { dpLessMutedStill } from "modules/theme/theme";
import React, { FunctionComponent } from "react";
import { Portal, Text } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import Button from "modules/form/button/Button.component";
import { useDispatch, useSelector } from "react-redux";
import { setRemoveMedicationState, removeMedication } from "./store/refill.actions";
import { selectRemoveMedicationState } from "./store/refill.selectors";
import { TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import Dialog from "modules/material/dialog/Dialog.component";

const RemoveMedicationWarningDialog: FunctionComponent<RemoveMedicationWarningDialogProps> = (props) => {
	const dispatch = useDispatch();
	const removeMedicationState = useSelector(selectRemoveMedicationState());
	const medication = removeMedicationState.medication!;
	const warningVisible = removeMedicationState.medication !== undefined;

	const handleClose = () => {
		dispatch(setRemoveMedicationState(RequestState.idle, undefined));
	};

	const handleRemoveMedication = () => {
		dispatch(removeMedication(medication));
	};

	return (
		<Portal>
			<Dialog visible={warningVisible} onDismiss={handleClose} style={styles.dialog}>
				<View>
					<Text style={styles.dialogText}>
						Remove "Rx# {medication?.rx_num} - {medication?.rx_name}"?
					</Text>
				</View>
				<View style={{ paddingTop: 32, flexDirection: "row", justifyContent: "flex-end" }}>
					<View style={{ alignItems: "center", justifyContent: "center" }}>
						<Button
							intent="destructive"
							withoutNavigation={true}
							tracking={{ id: "cancel-delete-medication", category: TRACKING_CATEGORY.refill }}
							onPress={handleClose}
							mode="text"
							textButtonStyle={{ color: dpLessMutedStill }}
						>
							Cancel
						</Button>
					</View>
					<View style={{ marginLeft: 32 }}>
						<Button
							intent="destructive"
							withoutNavigation={true}
							tracking={{ id: "confirm-delete-medication", category: TRACKING_CATEGORY.refill }}
							onPress={handleRemoveMedication}
							disabled={removeMedicationState.requestState === RequestState.loading}
							loading={removeMedicationState.requestState === RequestState.loading}
						>
							Remove
						</Button>
					</View>
				</View>
			</Dialog>
		</Portal>
	);
};

interface RemoveMedicationWarningDialogProps {}

const styles = StyleSheet.create({
	dialog: {
		maxWidth: 420,
		alignSelf: "center",
		position: "fixed",
	} as any,
	dialogText: {
		fontWeight: "bold",
		fontSize: 18,
	},
});

export default RemoveMedicationWarningDialog;
