import { RequestState } from '../../store/types';
import { Pharmacy } from './pharmacy';
import { FETCH_PHARMACY, FETCH_PHARMACY_ERROR, PharmacyActionTypes, RECEIVE_PHARMACY } from './pharmacy.action-types';

export interface PharmacyReducerState {
    state: RequestState,
	pharmacy?: Pharmacy,
}

const initialState = (): PharmacyReducerState => {
	return {
		state: RequestState.idle,
		pharmacy: undefined,
	}
}

export const PharmacyReducer = (state = initialState(), action: PharmacyActionTypes): PharmacyReducerState => {
	switch(action.type) {
		case FETCH_PHARMACY:
			return {
				...state,
				state: RequestState.loading,
			}
		case RECEIVE_PHARMACY:
			return {
				...state,
				pharmacy: action.payload,
				state: RequestState.success,
			}
		case FETCH_PHARMACY_ERROR:
			return {
				...state,
				state: RequestState.error,
			}
		default:
			return state;
	}
}
