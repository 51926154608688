import * as React from "react";

interface ResponsiveState {
	screen: ScreenSizes;
}

interface State {
	responsiveState: ResponsiveState | undefined;
	updateResponsive: (width: number) => void;
}

const ResponsiveContext = React.createContext<State | undefined>(undefined);

export enum ScreenSizes {
	extraSmall,
	small,
	medium,
	large,
	extraLarge,
}

export const ResponsiveProvider = ({ children }: { children: React.ReactNode }) => {
	const getSize = (width: number) => {
		if (width < 600) {
			return ScreenSizes.extraSmall;
		} else if (width < 768) {
			return ScreenSizes.small;
		} else if (width < 992) {
			return ScreenSizes.medium;
		} else if (width < 1200) {
			return ScreenSizes.large;
		} else {
			return ScreenSizes.extraLarge;
		}
	};

	const [responsiveState, setState] = React.useState<ResponsiveState | undefined>({ screen: getSize(window.innerWidth) });

	const updateResponsive = (width: number) => {
		const screen = getSize(width);
		if (responsiveState?.screen === screen) {
			return;
		}
		setState({ screen });
	};

	const value = { responsiveState, updateResponsive };
	return <ResponsiveContext.Provider value={value}>{children}</ResponsiveContext.Provider>;
};
export const useResponsive = () => {
	const context = React.useContext(ResponsiveContext);
	if (context === undefined) {
		throw new Error("useResponsive must be used within a ResponsiveProvider");
	}
	return context;
};

export const detectIsSmallScreen = (responsiveState: { screen: ScreenSizes } | undefined) => {
	return responsiveState?.screen == ScreenSizes.small || responsiveState?.screen === ScreenSizes.extraSmall;
};
