import { configMap, DeliveryOptions } from "modules/common/constants";
import { logger } from "modules/common/logging/logger";
import { GetState, Navigation, RequestState, Route, RowStates } from "modules/store/types";
import { Dispatch } from "redux";
import {
	PERSIST_REFILL_PARTIAL,
	PersistRefillPartialAction,
	SubmitRefillRequestAction,
	SubmitRefillRequestSuccessAction,
	SubmitRefillRequestErrorAction,
	SUBMIT_REFILL_REQUEST,
	SUBMIT_REFILL_REQUEST_SUCCESS,
	SUBMIT_REFILL_REQUEST_ERROR,
	Refill,
	Medication,
	SetEditMedicationStateAction,
	SET_EDIT_MEDICATION_STATE,
	SET_REMOVE_MEDICATION_STATE,
	DISMISS_SUBMIT_REFILL_REQUEST_ERROR,
	DismissSubmitRefillRequestErrorAction,
	ResetSubmitRefillState,
	RESET_SUBMIT_REFILL_STATE,
	SetAddMedicationStateAction,
	SET_ADD_MEDICATION_STATE,
	AddAnonymousMedicationAction,
	ADD_ANONYMOUS_MEDICATION,
	UpdateAnonymousMedicationAction,
	UPDATE_ANONYMOUS_MEDICATION,
	RemoveAnonymousMedicationAction,
	REMOVE_ANONYMOUS_MEDICATION,
	AnonymousMedication,
	SetAbortRefillStateAction,
	SET_ABORT_REFILL_STATE,
} from "./refill.action-types";
import { ParsedRefillResponse, RefillItem, RefillItemMedication, RefillItemStatusCodes, RefillItemStatusCodesTypes, RefillRequestResult } from "./refill.types";
import { handleFetchResponse } from "modules/common/fetch/fetch-helpers";
import { AccountDetails } from "modules/account/store/account.types";
import { addToNewsletter, createProfileOnRefill, updateAccount } from "modules/account/store/account.actions";
import { UpdateProfileSuccessAction } from "modules/account/store/account.action-types";
import { SetRemoveMedicationStateAction } from "./refill.action-types";
import { formatDateForServer, navigate } from "modules/common/helpers";
import { Screens } from "modules/common/screens/screens.constants";
import { trackEvent, TRACKING_CATEGORY } from "modules/common/tracking/tracking";
import { addSnackbarItem } from "modules/layout/snackbar/store/snackbar.actions";

export const persistRefillPartial = (data: Partial<Refill>) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		payload: data,
		type: PERSIST_REFILL_PARTIAL,
	} as PersistRefillPartialAction);
};

export const addMedicationApiRequest = async (rxNum: string, rxName: string | undefined, pharmacyId: string, locationId: string, profileId: string) => {
	const payload = {
		pharmacy_id: pharmacyId,
		location_id: locationId,
		patient_profile_id: profileId,
		medication_object: {
			rx_num: rxNum,
			rx_name: rxName,
		},
	};

	const response: AccountDetails = await fetch(`${configMap.apiWebEndpoint}/account/medication/create`, {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"content-type": "application/json",
		},
		credentials: "include",
	}).then(handleFetchResponse);

	return response;
};

export const addMedication =
	(data: Omit<Medication | AnonymousMedication, "medication_list_medication_id">) => async (dispatch: Dispatch, getState: GetState) => {
		dispatch(setAddMedicationState(RequestState.loading) as any);

		try {
			const profile = getState().account.profile;
			if (!profile) {
				// guest checkout
				dispatch(addAnonymousMedication(data) as any);
				dispatch(setAddMedicationState(RequestState.success) as any);
				return;
			}

			const pharmacyId = getState().pharmacy.pharmacy!.id;
			const locationId = profile?.patient.default_location_id;
			const profileId = profile?.patient_profile_id;

			const response: AccountDetails = await addMedicationApiRequest(data.rx_num!, data.rx_name, pharmacyId, locationId, profileId);

			const account = {
				...getState().account.account,
				account_details: response,
			};
			const updatedProfile = response.profiles?.find((x) => x.patient_profile_id === profile?.patient_profile_id);

			dispatch(setAddMedicationState(RequestState.success) as any);
			dispatch({
				type: "UPDATE_PROFILE_SUCCESS",
				payload: {
					account: account,
					profile: updatedProfile,
				},
			} as UpdateProfileSuccessAction);

			// reset();
		} catch (error) {
			logger("error", error);
			dispatch(setAddMedicationState(RequestState.error) as any);
		}
	};

export const setEditMedicationState = (data: Medication | AnonymousMedication, state: RowStates) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		payload: {
			medication: data,
			state: state,
		},
		type: SET_EDIT_MEDICATION_STATE,
	} as SetEditMedicationStateAction);
};

export const setAddMedicationState = (state: RequestState) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		payload: state,
		type: SET_ADD_MEDICATION_STATE,
	} as SetAddMedicationStateAction);
};

const addAnonymousMedication = (data: Omit<AnonymousMedication, "medication_list_medication_id">) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		payload: data,
		type: ADD_ANONYMOUS_MEDICATION,
	} as AddAnonymousMedicationAction);
};

export const upsertMedication = (data: Medication | AnonymousMedication) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch(setEditMedicationState(data, "saving") as any);

	try {
		const anonymousMedicationIndex = getState().refill.medications.findIndex((x) => x.medication_list_medication_id === data.medication_list_medication_id);
		if (anonymousMedicationIndex > -1) {
			// guest refill
			dispatch({
				type: UPDATE_ANONYMOUS_MEDICATION,
				payload: data,
			} as UpdateAnonymousMedicationAction);
			dispatch(setEditMedicationState(data, "read") as any);
			return;
		}

		const profile = getState().account.profile;

		const payload = {
			patient_profile_id: profile?.patient_profile_id,
			medication_list_medication_id: data.medication_list_medication_id,
			medication_object: data,
		};

		const response: AccountDetails = await fetch(`${configMap.apiWebEndpoint}/account/medication/update`, {
			method: "PUT",
			body: JSON.stringify(payload),
			headers: {
				"content-type": "application/json",
			},
			credentials: "include",
		}).then(handleFetchResponse);

		dispatch(updateAccount(response) as any);
		dispatch(setEditMedicationState(data, "read") as any);
	} catch (error) {
		logger("error", error);
		dispatch(
			addSnackbarItem({
				text: "Error saving refill medication. Please try again",
				severity: "error",
				timeout: 5000,
			}) as any
		);
	}
};

export const setRemoveMedicationState = (state: RequestState, data?: Medication | AnonymousMedication) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		payload: {
			state: state,
			medication: data,
		},
		type: SET_REMOVE_MEDICATION_STATE,
	} as SetRemoveMedicationStateAction);
};

export const removeMedication = (data: Medication) => async (dispatch: Dispatch, getState: GetState) => {
	dispatch(setRemoveMedicationState(RequestState.loading, data) as any);

	try {
		const anonymousMedicationIndex = getState().refill.medications.findIndex((x) => x.medication_list_medication_id === data.medication_list_medication_id);
		if (anonymousMedicationIndex > -1) {
			// guest refill
			dispatch({
				type: REMOVE_ANONYMOUS_MEDICATION,
				payload: data,
			} as RemoveAnonymousMedicationAction);
			dispatch(setRemoveMedicationState(RequestState.idle, undefined) as any);
			return;
		}

		const profile = getState().account.profile;
		const payload = {
			patient_profile_id: profile?.patient_profile_id,
			medication_list_medication_id: profile?.medication_list.find((x) => x.rx_num === data.rx_num)?.medication_list_medication_id,
		};

		const response: AccountDetails = await fetch(`${configMap.apiWebEndpoint}/account/medication/deactivate`, {
			method: "PUT",
			body: JSON.stringify(payload),
			headers: {
				"content-type": "application/json",
			},
			credentials: "include",
		}).then(handleFetchResponse);

		dispatch(setRemoveMedicationState(RequestState.idle, undefined) as any);
		dispatch(updateAccount(response) as any);
	} catch (error) {
		logger("error", error);
		dispatch(setRemoveMedicationState(RequestState.idle, data) as any);
	}
};

export const submitRefillRequest =
	(medications: Array<Medication | AnonymousMedication>, isAddToNewsletter: boolean, comment: string | undefined, navigation: Navigation, route: Route) =>
	async (dispatch: Dispatch, getState: GetState) => {
		dispatch({
			type: SUBMIT_REFILL_REQUEST,
		} as SubmitRefillRequestAction);

		const filteredMedications = medications.filter((x) => x !== undefined && x !== null);
		const profile = getState().account.profile;
		if (profile) {
			submitAuthenticatedRefill(filteredMedications, comment, navigation, route, dispatch, getState);
		} else {
			submitAnonymousRefill(filteredMedications as AnonymousMedication[], comment, isAddToNewsletter, navigation, route, dispatch, getState);
		}
	};

const submitAnonymousRefill = async (
	medications: Array<AnonymousMedication>,
	comment: string | undefined,
	isAddToNewsletter: boolean,
	navigation: Navigation,
	route: Route,
	dispatch: Dispatch,
	getState: GetState
) => {
	try {
		const pharmacyId = getState().pharmacy.pharmacy!.id;
		const refill = getState().refill.refill;
		const address =
			refill.deliveryMethodId === DeliveryOptions.delivery || refill.deliveryMethodId === DeliveryOptions.mail
				? {
						street_one: refill.address1,
						street_two: refill.address2,
						city: refill.city,
						state: refill.state,
						zip: refill.postalCode,
						country: null,
				  }
				: null;
		const refillItems = medications.map((x, index) => {
			return {
				rx_form_dosage: null,
				rx_name: x.rx_name,
				rx_norm: null,
				rx_num: x.rx_num,
				rx_type: null,
				refill_index: index,
			};
		});

		const payload = {
			pharmacy_id: pharmacyId,
			location_id: refill.locationId,
			email: refill.emailAddress,
			delivery_method: refill.deliveryMethodId,
			phone_number: (refill.phone ?? "").replace(/-/g, ""),
			comment: comment,
			address: address,
			patient: {
				first_name: refill.firstName,
				last_name: refill.lastName,
				dob: formatDateForServer(refill.dateOfBirth),
				phone: (refill.phone ?? "").replace(/-/g, ""),
			},
			refill_items: refillItems,
		};

		const response: RefillRequestResult = await fetch(`${configMap.apiWebEndpoint}/refill/anonymous/submit`, {
			method: "POST",
			body: JSON.stringify(payload),
			headers: {
				"x-pharmacy-id": pharmacyId,
				"content-type": "application/json",
			},
			credentials: "include",
		}).then(handleFetchResponse);

		if (isAddToNewsletter) {
			dispatch(addToNewsletter(refill.emailAddress!) as any);
		}

		const parsedResponse = handleRefillResponse(response, medications, comment, getState);
		if (!parsedResponse.isSuccess) {
			dispatch({
				type: SUBMIT_REFILL_REQUEST_ERROR,
				payload: {
					parsedResponse,
				},
			} as SubmitRefillRequestErrorAction);

			navigate(navigation, route, Screens.RefillIncomplete);
		} else {
			//tracking
			trackEvent("guest-refill-submitted");
			trackEvent("refilled-medications", TRACKING_CATEGORY.refill, "count", medications.length);

			if (!!getState().account.account) {
				// first time through for a new account they will need to have profile created
				try {
					await createProfileOnRefill(medications, dispatch, getState);
				} catch (createProfileError) {
					// log the error, but otherwise continue them down the normal flow, they can always create a profile from account profile screen
					logger("error", createProfileError);
				}
			}

			dispatch({
				type: SUBMIT_REFILL_REQUEST_SUCCESS,
				payload: response,
			} as SubmitRefillRequestSuccessAction);

			//navigation
			navigate(navigation, route, Screens.RefillComplete);
		}
	} catch (error) {
		logger("error", error);
		dispatch(
			addSnackbarItem({
				text: "Error submitting refill. Please try again",
				severity: "error",
				timeout: 5000,
			}) as any
		);
		dispatch({
			type: SUBMIT_REFILL_REQUEST_ERROR,
			payload: {
				parsedResponse: getState().refill.refillIncomplete,
			},
		} as SubmitRefillRequestErrorAction);
	}
};

const submitAuthenticatedRefill = async (
	medications: Array<Medication | AnonymousMedication>,
	comment: string | undefined,
	navigation: Navigation,
	route: Route,
	dispatch: Dispatch,
	getState: GetState
) => {
	// determine if Medication or AnonymousMedication, may receive AnonymousMedication if the user is taken to refill incomplete screen
	const isSavedMedication = medications.findIndex((x) => {
		return !!(x as Medication).created_date;
	});

	try {
		const pharmacyId = getState().pharmacy.pharmacy!.id;
		const path = `/profile/submit`;
		const profile = getState().account.profile;

		const refillItems = isSavedMedication
			? null
			: medications.map((x, index) => {
					return {
						rx_form_dosage: null,
						rx_name: x.rx_name,
						rx_norm: null,
						rx_num: x.rx_num,
						rx_type: null,
						refill_index: index,
					};
			  });

		const payload = {
			pharmacy_id: pharmacyId,
			location_id: profile?.patient.default_location_id,
			address_id: profile?.patient.default_address_id,
			comment: comment,
			delivery_method: profile?.patient.default_delivery_method,
			email: profile?.patient.email,
			medication_list_id: profile?.medication_list.length ? profile?.medication_list[0].medication_list_id : undefined,
			medication_list_medication_ids: isSavedMedication ? medications.map((x) => x.medication_list_medication_id) : [],
			patient_profile_id: profile?.patient_profile_id,
			phone_number: (profile?.patient.phone ?? "").replace(/-/g, ""),
			refill_items: refillItems,
		};

		const response: RefillRequestResult = await fetch(`${configMap.apiWebEndpoint}/refill${path}`, {
			method: "POST",
			body: JSON.stringify(payload),
			headers: {
				"x-pharmacy-id": pharmacyId,
				"content-type": "application/json",
			},
			credentials: "include",
		}).then(handleFetchResponse);

		const parsedResponse = handleRefillResponse(response, medications, comment, getState);
		if (!parsedResponse.isSuccess) {
			dispatch({
				type: SUBMIT_REFILL_REQUEST_ERROR,
				payload: {
					parsedResponse,
				},
			} as SubmitRefillRequestErrorAction);

			trackEvent("refilled-medications-incomplete", TRACKING_CATEGORY.refill, "count", parsedResponse.successfulItems.length);
			navigate(navigation, route, Screens.RefillIncomplete);
		} else {
			dispatch({
				type: SUBMIT_REFILL_REQUEST_SUCCESS,
				payload: response,
			} as SubmitRefillRequestSuccessAction);

			trackEvent("refilled-medications", TRACKING_CATEGORY.refill, "count", medications.length);
			navigate(navigation, route, Screens.RefillComplete);
		}
	} catch (error) {
		logger("error", error);
		dispatch(
			addSnackbarItem({
				text: "Error submitting refill. Please try again",
				severity: "error",
				timeout: 5000,
			}) as any
		);
		dispatch({
			type: SUBMIT_REFILL_REQUEST_ERROR,
			payload: {
				parsedResponse: getState().refill.refillIncomplete,
			},
		} as SubmitRefillRequestErrorAction);
	}
};

/**
 * In the event the user gets to incomplete refill, they may not end up submitting, but we
 * still need to handle any post refill tasks, primarily those associated with a new account
 */
export const abortRefill =
	(medications: Array<RefillItemMedication>, navigation: Navigation, route: Route, screen: Screens) => async (dispatch: Dispatch, getState: GetState) => {
		const profile = getState().account.profile;
		const account = getState().account.account;

		dispatch({
			type: SET_ABORT_REFILL_STATE,
			payload: RequestState.loading,
		} as SetAbortRefillStateAction);

		if (!profile && !!account) {
			// first time through for a new account they will need to have profile created
			try {
				await createProfileOnRefill(
					medications.map((x) => {
						return x.medication;
					}),
					dispatch,
					getState
				);
			} catch (createProfileError) {
				// log the error, but otherwise continue them down the normal flow, they can always create a profile from account profile screen
				logger("error", createProfileError);
			}
		}

		dispatch({
			type: SET_ABORT_REFILL_STATE,
			payload: RequestState.idle,
		} as SetAbortRefillStateAction);

		navigate(navigation, route, screen);
	};

export const dismissRefillSubmitError = () => async (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		type: DISMISS_SUBMIT_REFILL_REQUEST_ERROR,
	} as DismissSubmitRefillRequestErrorAction);
};

const handleRefillResponse = (
	response: RefillRequestResult,
	medications: Array<Medication | AnonymousMedication>,
	comment: string | undefined,
	getState: GetState
): ParsedRefillResponse => {
	const profile = getState().account.profile;
	const isSavedMedication = medications.findIndex((x) => {
		return !!(x as Medication).created_date;
	});

	const filterMedications = (refillItems: RefillItem[], filterType: String) => {
		return refillItems.filter((x) => RefillItemStatusCodesTypes[x.message_code] === filterType);
	};

	if (profile && isSavedMedication) {
		const mapping = medications.map((medication) => {
			return profile.medication_list.find(
				(medicationListItem) => medicationListItem.medication_list_medication_id === medication.medication_list_medication_id
			)!;
		});
		const mapMedication = (x: RefillItem) => {
			return {
				medication: mapping[x.refill_index],
				status: x.status,
				messageCode: x.message_code as keyof typeof RefillItemStatusCodes,
			};
		};
		const successfulItems = filterMedications(response.refill_items, "success").map(mapMedication);
		const failedItems = filterMedications(response.refill_items, "fail").map(mapMedication);
		const retryItems = filterMedications(response.refill_items, "retry").map(mapMedication);
		return {
			isSuccess: failedItems.length === 0 && retryItems.length === 0,
			successfulItems,
			retryItems,
			failedItems,
			comment,
		};
	} else {
		const mapMedication = (x: RefillItem) => {
			const medication = medications[x.refill_index] as AnonymousMedication;
			return {
				medication,
				status: x.status,
				messageCode: x.message_code as keyof typeof RefillItemStatusCodes,
			};
		};
		const successfulItems = filterMedications(response.refill_items, "success").map(mapMedication);
		const failedItems = filterMedications(response.refill_items, "fail").map(mapMedication);
		const retryItems = filterMedications(response.refill_items, "retry").map(mapMedication);
		return {
			isSuccess: failedItems.length === 0 && retryItems.length === 0,
			successfulItems,
			retryItems,
			failedItems,
			comment,
		};
	}
};

export const resetRefillSubmitState = () => (dispatch: Dispatch, getState: GetState) => {
	dispatch({
		type: RESET_SUBMIT_REFILL_STATE,
	} as ResetSubmitRefillState);
};
