export const handleFetchResponse = (response: Response) => {
	if (response.ok) {
		return response.json();
	}
	throw new Error(`Fetch call failed. Status: "${response.status}" Text: "${response.statusText}" Route: "${response.url}"`);
};

interface FetchResponse<T> {
	hasError: boolean;
	response?: T;
	error?: FetchResponseError;
}

export interface FetchResponseError {
	statusText: string;
	status: number;
	url: string;
}

export const handleFetchResponseWithoutThrow = async <T>(response: Response): Promise<FetchResponse<T>> => {
	return new Promise<FetchResponse<T>>((resolve, reject) => {
		if (response.ok) {
			response
				.json()
				.then((x) => {
					resolve({
						hasError: false,
						response: x,
					});
				})
				.catch(() => {
					resolve({
						hasError: true,
						error: {
							statusText: response.statusText,
							url: response.url,
							status: response.status,
						},
					});
				});
		} else {
			resolve({
				hasError: true,
				error: {
					statusText: response.statusText,
					url: response.url,
					status: response.status,
				},
			});
		}
	});
};
