import React, { FunctionComponent, useState, useEffect } from "react";
import CardScreen from "modules/layout/CardScreen.component";
import { useForm } from "react-hook-form";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import ClipboardForwardIcon from "../../assets/icons/clipboard-forward.svg";
import UserCircleIcon from "../../assets/icons/user-circle.svg";
import { pageView, TRACKING_CATEGORY } from "../common/tracking/tracking";
import Button from "../form/button/Button.component";
import TextField from "../form/fields/TextField.component";
import { dpLessMuted } from "../theme/theme";
import { changePassword } from "../account/store/account.actions";
import { selectPasswordChangeState } from "modules/account/store/account.selectors";
import { RequestState } from "modules/store/types";
import MessageBox from "modules/material/message-box/MessageBox.component";
import { Screens } from "modules/common/screens/screens.constants";
import { passwordRegex, passwordStrengthMessage } from "modules/common/constants";

const ChangePassword: FunctionComponent<ChangePasswordProps> = (props) => {
	const methods = useForm<ChangePasswordForm>({ defaultValues: { newPassword: "", newPasswordConfirm: "" } });
	const dispatch = useDispatch();
	const passwordChangeState = useSelector(selectPasswordChangeState());
	const [emailToken] = useState(new URLSearchParams(window.location.search).get("password_reset") ?? "");

	const handleSubmit = (data: ChangePasswordForm) => {
		dispatch(changePassword(emailToken, data.newPassword));
	};

	useEffect(function initialize() {
		pageView("change-password");
	}, []);

	return (
		<CardScreen
			header="Change Password"
			subHeader=""
			headerColorStart="rgba(4, 53, 100, 0.7)"
			headerColorEnd="rgba(248, 207, 96, 0.7)"
			methods={methods}
			content={
				<>
					{!emailToken && <MessageBox message="Invalid link" type="error" />}

					{emailToken && passwordChangeState === RequestState.success && (
						<>
							<MessageBox type={"success"} message={"Password successfully changed"} />
							<Button tracking={{ id: "goto-login", category: TRACKING_CATEGORY.profile }} mode={"standard"} navigateTo={Screens.Login}>
								Login
							</Button>
						</>
					)}

					{emailToken && passwordChangeState === RequestState.error && (
						<MessageBox type={"error"} message={"Unable to change your password, please try again"} />
					)}

					{emailToken && passwordChangeState !== RequestState.success && (
						<>
							<TextField
								type="password"
								label="New Password"
								name="newPassword"
								rules={{
									required: "Password is required",
									pattern: {
										value: passwordRegex,
										message: "Password is not strong enough",
									},
								}}
								helpMessage={passwordStrengthMessage}
							/>
							<TextField
								type="password"
								label="Confirm New Password"
								name="newPasswordConfirm"
								rules={{
									required: "Password confirmation is required",
									validate: {
										value: (value: string) => {
											const newPasswordField: string = methods.getValues("newPassword");
											if (value === newPasswordField) {
												return true;
											}
											return "Passwords do not match";
										},
									},
								}}
							/>

							<View style={{ marginTop: 16 }}>
								<Button
									onPress={methods.handleSubmit(handleSubmit)}
									tracking={{ id: "password-change-submit", category: TRACKING_CATEGORY.profile }}
									disabled={passwordChangeState === RequestState.loading}
									loading={passwordChangeState === RequestState.loading}
								>
									Change Password
								</Button>
							</View>
						</>
					)}
				</>
			}
			actions={
				<>
					<Button
						tracking={{ id: "no-account", category: TRACKING_CATEGORY.profile }}
						icon={ClipboardForwardIcon}
						mode={"flat"}
						navigateTo={Screens.CreateAccount}
					>
						Don't Have an Account?
					</Button>
					<View
						style={{
							borderLeftWidth: 2,
							borderLeftColor: dpLessMuted,
						}}
					/>
					<Button
						tracking={{ id: "refill-without-account", category: TRACKING_CATEGORY.profile }}
						icon={UserCircleIcon}
						mode={"flat"}
						navigateTo={Screens.ProfileInfo}
					>
						Refill Without an Account
					</Button>
				</>
			}
		/>
	);
};

interface ChangePasswordProps {
	changePasswordSuccess: boolean;
}

interface ChangePasswordForm {
	token: string;
	newPassword: string;
	newPasswordConfirm: string;
}

export default ChangePassword;
