import { RootReducerState } from "modules/store/rootReducer";
import { RequestState } from "modules/store/types";
import { AccountLoginProfile, AccountLoginResponse } from "./account.types";

export const selectIsAuthenticated =
	() =>
	(state: RootReducerState): boolean =>
		state.account.account !== undefined;

export const selectLoginState =
	() =>
	(state: RootReducerState): RequestState =>
		state.account.loginState;

export const selectPasswordResetState =
	() =>
	(state: RootReducerState): RequestState =>
		state.account.passwordResetState;

export const selectTrySessionState =
	() =>
	(state: RootReducerState): RequestState =>
		state.account.trySessionState;

export const selectPasswordChangeState =
	() =>
	(state: RootReducerState): RequestState =>
		state.account.passwordChangeState;

export const selectAccount =
	() =>
	(state: RootReducerState): AccountLoginResponse | undefined =>
		state.account.account;

export const selectProfile =
	() =>
	(state: RootReducerState): AccountLoginProfile | undefined =>
		state.account.profile;

export const selectAddProfileState =
	() =>
	(state: RootReducerState): RequestState =>
		state.account.addProfileState;

export const selectUpdateProfileState =
	() =>
	(state: RootReducerState): RequestState =>
		state.account.updateProfileState;

export const selectCreateAccountState =
	() =>
	(state: RootReducerState): RequestState =>
		state.account.createAccountState;

export const selectCreateAccountErrorMessage =
	() =>
	(state: RootReducerState): string | undefined =>
		state.account.createAccountErrorMessage;

export const selectAccountVerificationState =
	() =>
	(state: RootReducerState): RequestState =>
		state.account.accountVerificationState;
