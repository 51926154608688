import { AnonymousMedication, Medication } from "./refill.action-types";

// see status_codes.py in refill-api
export type RefillStatus = "Received" | "Failed" | "Pending" | "Submitted";
export type RefillItemStatus = "Success" | "Rejected" | "Rejected_Retry" | "Failed" | "Pending" | "Received";
export const RefillItemStatusCodes = {
	"500": "Your refill request was successfully sent to the pharmacy.",
	"501": "Your refill request was successfully sent to the pharmacy.",
	"502": "This prescription is already in the queue to be refilled.",
	"503": "This prescription is in the process of being filled.",
	"504": "This prescription has been filled and is ready for pick-up.",
	"505": "This prescription has been filled and is ready for delivery.",
	"506": "This prescription has been filled and is on the delivery vehicle.",
	"507": "This prescription has been filled and is ready to be mailed.",
	"520": "This prescription has been submitted for refill too soon.",
	"521": "This Rx Number has expired.",
	"522": "There are no more refills available for this prescription.",
	"523": "We need your doctor's approval to fill this prescription.",
	"524": "We need your authorization to contact your doctor to fill this prescription.",
	"525": "We couldn't verify your insurance.",
	"526": "This Rx Number is on hold.",
	"527": "This prescription can only be partially filled.",
	"530": "We could not find your Rx Number in our system.",
	"540": "This prescription cannot be filled online. Please contact the pharmacy to resolve this issue.",
	"541": "This prescription has been transferred to another pharmacy.",
	"542": "This prescription has been deleted from our system. Please contact the pharmacy if this issue needs to be resolved.",
	"543": "We could not verify your personal information in our system. Please verify or correct the information and resend your request.",
	"544": "We couldn't find your address. Please verify or correct the information and resend your request",
	"550": "We have sent your refill request to the pharmacy. If we have your email, we will send you an update when your request is delivered.",
	"560": "We could not reach your pharmacy with your request. If we have your email, we will send you an update when your request is delivered.",
	"561": "We could not reach your pharmacy with your request. If we have your email, we will send you an update when your request is delivered.",
	"562": "An unknown error has occurred. Please try again later.",
	"563": "An unknown error has occurred. Please try again later.",
	"564": "We couldn't reach your pharmacy with your request. Please try again later.",
};

export const RefillItemStatusCodesTypes: { [key: string]: "success" | "fail" | "retry" } = {
	"500": "success",
	"501": "success",
	"502": "success",
	"503": "success",
	"504": "success",
	"505": "success",
	"506": "success",
	"507": "success",
	"520": "fail",
	"521": "fail",
	"522": "fail",
	"523": "fail",
	"524": "fail",
	"525": "fail",
	"526": "fail",
	"527": "fail",
	"530": "retry",
	"540": "fail",
	"541": "fail",
	"542": "fail",
	"543": "fail",
	"544": "fail",
	"550": "success",
	"560": "fail",
	"561": "fail",
	"562": "fail",
	"563": "fail",
	"564": "fail",
};

export interface ParsedRefillResponse {
	isSuccess: boolean;
	successfulItems: RefillItemMedication[];
	failedItems: RefillItemMedication[];
	retryItems: RefillItemMedication[];
	comment: string | undefined;
}

// internal mapping of refill item aggregated with medication
export interface RefillItemMedication {
	/**
	 * When saved medication, it will be Medication, guest checkout or re-submit will be UnsavedMedication
	 */
	medication: Medication | AnonymousMedication;
	status: RefillItemStatus;
	messageCode: keyof typeof RefillItemStatusCodes;
}

export interface RefillItem {
	message_code: string;
	refill_index: number;
	status: RefillItemStatus;
	submitted_refill_medication_id: string;
}

export interface RefillRequestResult {
	batch_id?: string;
	confirmation_code: string;
	refill_items: RefillItem[];
	status: RefillStatus;
	submitted_refill_id: string;
}

export interface AddMedicationResult {
	account_id: string;
	account_last_modified: string;
	account_owner_id: string;
	address_index_map: object;
	addresses: Array<object>;
	created_data: string;
	default_patient_profile_id: string;
	pharmacy_id: string;
	profile_index_map: object;
	profiles: Array<object>;
	refill_history: Array<object>;
	updated_date: string;
	user_id: string;
}

export interface RemoveMedicationResult {}

export interface EditMedicationResult {}
