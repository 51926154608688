import { RootReducerState } from "modules/store/rootReducer";
import { dpError, dpGreen, dpWarn } from "modules/theme/theme";
import React, { FunctionComponent } from "react";
import { StyleSheet, Text } from "react-native";
import { Snackbar as PaperSnackbar } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarItem } from "./store/snackbar.action-types";
import { removeSnackbarItem } from "./store/snackbar.actions";

const Snackbar: FunctionComponent<SnackbarProps> = (props) => {
	const dispatch = useDispatch();
	const toasts = useSelector((state: RootReducerState) => state.snackbar);

	const handleClose = (toast: SnackbarItem) => {
		dispatch(removeSnackbarItem(toast));
	};

	return (
		<>
			{toasts.map((toast, index) => (
				<PaperSnackbar
					visible={true}
					onDismiss={() => {
						handleClose(toast);
					}}
					style={
						{
							backgroundColor: getBackgroundColor(toast),
							position: "fixed",
							bottom: 0,
							left: 0,
							right: 0,
						} as any
					}
					action={{
						color: "#fff",
						label: "Dismiss",
					}}
					key={index}
					duration={toast.timeout}
				>
					<Text style={{ color: "#fff", fontWeight: "bold" }}>{toast.text}</Text>
				</PaperSnackbar>
			))}
		</>
	);
};

const getBackgroundColor = (toast: SnackbarItem) => {
	if (toast.severity === "error") {
		return dpError;
	}
	if (toast.severity === "warning") {
		return dpWarn;
	}
	if (toast.severity === "success") {
		return dpGreen;
	}
	return "#fff";
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "space-between",
	},
});

interface SnackbarProps {}

export default Snackbar;
